import React from "react"
import { MdEdit } from "react-icons/md"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import EditDateModal from "components/Modal/EditDateModal"
import useModal from "components/Modal/useModal"
import { useUpdateJalon } from "core/spo/query-hooks/useProductions"
import { toast } from "react-toastify"
import { t } from "i18next"
import { useQueryClient } from "@tanstack/react-query"
import { TCommercializationVEFADate } from "shared/types/spo/tranche.type"

interface EditableDateCellProps {
  date: TCommercializationVEFADate
  title: string
  labelDate: string
  registrationId: string
}

function EditableDateCell({
  date,
  title,
  labelDate,
  registrationId,
}: EditableDateCellProps) {
  const { isShowing, toggle } = useModal()
  const mutationJalon = useUpdateJalon(date!.id)
  const queryClient = useQueryClient()

  const handleSubmit = async (
    newDate: Date | null | undefined,
    newIsDefinitive: boolean,
  ) => {
    const queryData = {
      date: newDate ?? null,
      isDefinitive: newIsDefinitive,
    }

    mutationJalon.mutateAsync(queryData, {
      onSuccess() {
        const successMessage = title.includes("VEFA")
          ? t("success-mutate-vefa-date")
          : t("success-mutate-commercialization-date")

        toast.success(successMessage)
        queryClient.invalidateQueries({
          queryKey: ["getTranchesCommerciales", registrationId],
        })
        toggle()
      },
      onError() {
        const errorMessage = title.includes("VEFA")
          ? t("error-mutate-vefa-date")
          : t("error-mutate-commercialization-date")
        toast.error(errorMessage)
      },
    })
  }

  const displayDate = (dateToDisplay: Date | null, definitive: boolean) => {
    if (!dateToDisplay) return "-"
    return (
      <DateFormat date={new Date(dateToDisplay)} isDefinitive={definitive} />
    )
  }

  return (
    <>
      <div className="flex items-center justify-between">
        {displayDate(date?.date ?? null, date?.isDefinitive ?? false)}

        {!date?.isDefinitive && (
          <ButtonIcon className="ml-2" type="button" onClick={toggle}>
            <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
          </ButtonIcon>
        )}
      </div>

      {isShowing && (
        <EditDateModal
          isModalShowed={isShowing}
          toggleModal={toggle}
          initialDate={date?.date}
          isDefinitive={date?.isDefinitive ?? false}
          onSubmit={handleSubmit}
          isPending={mutationJalon.isPending}
          title={title}
          labelDate={labelDate}
        />
      )}
    </>
  )
}

export default EditableDateCell
