import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import {
  DndProvider,
  getBackendOptions,
  MultiBackend,
} from "@minoru/react-dnd-treeview"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { isAxiosError } from "axios"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import HiddenRoute from "core/auth/HiddenRoute"
import SpoAxiosInterceptor from "core/interceptors/SpoInterceptor"
import { GroupProtectedRoute } from "core/permissions/GroupProtectedRoute"
import ManagerGuard from "core/permissions/ManagerGuard"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { RoleProtectedRoute } from "core/permissions/RoleProtectedRoute"
import NextThreeMonthsOperationList from "features/home/NextThreeMonthsOperationList"
import OperationContributors from "features/operations/contributors/OperationContributors"
import OperationPlots from "features/operations/plots/OperationPlots"
import ProgramsSearchProvider from "features/programs/ProgramsSearchProvider"
import ProjectActions from "features/project/ProjectActions"
import { CostOfWorkProvider } from "features/studies/costOfWork/CostOfWorkProvider"
import CreateCostOfWork from "features/studies/costOfWork/CreateCostOfWork"
import { EditDocumentsCostOfWork } from "features/studies/costOfWork/EditDocumentsCostOfWork"
import { StepDetailsCoW } from "features/studies/costOfWork/StepDetailsCoW"
import { StepDocumentsCoW } from "features/studies/costOfWork/StepDocumentsCoW"
import { CostOfWorkDetails } from "features/studies/costOfWork/view/CostOfWorkDetails"
import CostOfWorkDocuments from "features/studies/costOfWork/view/CostOfWorkDocuments"
import CreateFeasibility from "features/studies/feasibilities/CreateFeasibility"
import { EditDocumentsFeasibility } from "features/studies/feasibilities/EditDocumentsFeasibility"
import { FeasibilityProvider } from "features/studies/feasibilities/FeasibilityProvider"
import { StepDetailsFeasibility } from "features/studies/feasibilities/StepDetailsFeasibility"
import { StepDocumentsFeasibility } from "features/studies/feasibilities/StepDocumentsFeasibility"
import FeasibilityDetails from "features/studies/feasibilities/view/FeasibilityDetails"
import FeasibilityDocumentsPage from "features/studies/feasibilities/view/FeasibilityDocumentsPage"
import CreateInfography from "features/studies/infographies/CreateInfography"
import { EditDocumentsInfography } from "features/studies/infographies/EditDocumentsInfography"
import { InfographyProvider } from "features/studies/infographies/InfographyProvider"
import { StepDetailsInfography } from "features/studies/infographies/StepDetailsInfography"
import { StepDocumentsInfography } from "features/studies/infographies/StepDocumentsInfography"
import { InfographyDetails } from "features/studies/infographies/view/InfographyDetails"
import InfographyDocuments from "features/studies/infographies/view/InfographyDocuments"
import MainLayout from "pages/MainLayout"
import NoMatch from "pages/NoMatch"
import DashboardExcel from "pages/dashboards/excel/DashboardExcel"
import PdpPage from "pages/dashboards/pdp/PdpPage"
import CostOfWorksDashboardPage from "pages/dashboards/study-requests/CostOfWorksDashboardPage"
import FeasibilityDashboardPage from "pages/dashboards/study-requests/FeasibilityDashboardPage"
import InfographyDashboardPage from "pages/dashboards/study-requests/InfographyDashboardPage"
import OverviewStudyRequestsPage from "pages/dashboards/study-requests/OverviewStudyRequestsPage"
import ProgramEnvironment from "pages/environment/ProgramEnvironment"
import Home from "pages/home/Home"
import TeamExpectedTodos from "pages/home/TeamExpectedTodos"
import TeamStudyRequestsTodos from "pages/home/TeamStudyRequestsTodos"
import UserExpectedTodos from "pages/home/UserExpectedTodos"
import UserStudyRequestsTodos from "pages/home/UserStudyRequestsTodos"
import UserSuppliersLogs from "pages/home/UserSuppliersLogs"
import MaintenancePage from "pages/maintenance/MaintenanceInterceptor"
import OperationAgreements from "pages/operations/OperationAgreements"
import { OperationCommercialization } from "pages/operations/OperationCommercialization"
import OperationCommittees from "pages/operations/OperationCommittees"
import OperationDocuments from "pages/operations/OperationDocuments"
import { OperationFinancial } from "pages/operations/OperationFinancial"
import OperationLegal from "pages/operations/OperationLegal"
import OperationPage from "pages/operations/OperationPage"
import OperationProgram from "pages/operations/OperationProgram"
import { OperationSteering } from "pages/operations/OperationSteering"
import OperationStudies from "pages/operations/OperationStudies"
import { OperationTechnical } from "pages/operations/OperationTechnical"
import ProgramsSearchPage from "pages/programs/ProgramsSearchPage"
import CreateProjectPage from "pages/projects/CreateProjectPage"
import ReleaseNotes from "pages/release-notes/ReleaseNotes"
import ListSimplifiedPrograms from "pages/simplifiedProgramms/ListSimplifiedPrograms"
import { CostOfWorkPage } from "pages/studies/CostOfWorkPage"
import { FeasibilityPage } from "pages/studies/FeasibilityPage"
import { InfographyPage } from "pages/studies/InfographyPage"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { RoutesProvider } from "routes/RoutesProvider"
import { GROUP } from "shared/resources/groups.resources"
import Login from "./core/auth/Login"
import { AgreementCreate } from "./features/agreements/AgreementCreate"
import { AgreementUpdate } from "./features/agreements/AgreementUpdate"
import { AgreementShow } from "./features/agreements/show/AgreementShow"
import { PlotCreate } from "./features/plots/PlotCreate"
import PlotShow from "./features/plots/PlotShow"
import { PlotUpdate } from "./features/plots/PlotUpdate"
import ProjectAgreements from "./features/project/ProjectAgreements"
import ProjectCommittees from "./features/project/ProjectCommittees"
import ProjectContributors from "./features/project/ProjectContributors"
import ProjectHome from "./features/project/ProjectHome"
import ProjectPlots from "./features/project/ProjectPlots"
import ProjectStudies from "./features/project/ProjectStudies"
import "./index.scss"
import ErrorPage from "./pages/projects/ErrorPage"
import ProjectPage from "./pages/projects/ProjectPage"

const MAX_RETRIES = 2
const HTTP_STATUS_TO_NOT_RETRY = [403, 404]

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false
        }

        if (
          isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          return false
        }

        return true
      },
    },
  },
})

const root = createRoot(document.getElementById("root") as HTMLElement)

const baseRouter: RouteObject[] = [
  {
    path: "/login",
    element: (
      <HiddenRoute>
        <Login />
      </HiddenRoute>
    ),
  },
  { path: "*", element: <NoMatch /> },
  {
    path: "/maintenance",
    element: <MaintenancePage />,
  },
  {
    path: "/",
    element: (
      <RoutesProvider>
        <MainLayout />
      </RoutesProvider>
    ),
    children: [
      {
        index: true,

        element: (
          <SpoAxiosInterceptor>
            <Home />
          </SpoAxiosInterceptor>
        ),
      },
      {
        path: "/expected-tasks",
        element: <UserExpectedTodos />,
      },
      {
        path: "/expected-suppliers",
        element: <UserSuppliersLogs />,
      },
      {
        path: "/expected-study-requests",
        element: <UserStudyRequestsTodos />,
      },
      {
        path: "/expected-tasks/team",
        element: (
          <ManagerGuard>
            <TeamExpectedTodos />
          </ManagerGuard>
        ),
      },
      {
        path: "/expected-study-requests/team",
        element: (
          <ManagerGuard>
            <TeamStudyRequestsTodos />
          </ManagerGuard>
        ),
      },
      {
        path: "/simplified-programs",
        element: <ListSimplifiedPrograms />,
      },
      {
        path: "/release-notes",
        element: <ReleaseNotes />,
      },
      {
        path: "/project/create",
        element: (
          <RoleProtectedRoute requiredRoles={["create:project"]}>
            <CreateProjectPage />
          </RoleProtectedRoute>
        ),
      },
      {
        path: "/programs/search",
        element: (
          <ProgramsSearchProvider>
            <QueryBoundaries loadingComponent={<Spinner />}>
              <ProgramsSearchPage />
            </QueryBoundaries>
          </ProgramsSearchProvider>
        ),
      },
      {
        path: "/dashboards/study-requests/costs-of-work",
        element: (
          <PermissionsGuard requiredRoles={["read:cost-of-work.dashboard"]}>
            <QueryBoundaries loadingComponent={<Spinner />}>
              <CostOfWorksDashboardPage />
            </QueryBoundaries>
          </PermissionsGuard>
        ),
      },
      {
        path: "/dashboards/study-requests/feasibility",
        element: (
          <PermissionsGuard requiredRoles={["read:feasibility.dashboard"]}>
            <QueryBoundaries loadingComponent={<Spinner />}>
              <FeasibilityDashboardPage />
            </QueryBoundaries>
          </PermissionsGuard>
        ),
      },
      {
        path: "/dashboards/study-requests/infography",
        element: (
          <PermissionsGuard requiredRoles={["read:infography.dashboard"]}>
            <QueryBoundaries loadingComponent={<Spinner />}>
              <InfographyDashboardPage />
            </QueryBoundaries>
          </PermissionsGuard>
        ),
      },
      {
        path: "/dashboards/study-requests/overview",
        element: (
          <PermissionsGuard requiredRoles={["read:study-request.dashboard"]}>
            <QueryBoundaries loadingComponent={<Spinner />}>
              <OverviewStudyRequestsPage />
            </QueryBoundaries>
          </PermissionsGuard>
        ),
      },
      {
        path: "/dashboards/pdp",
        element: <PdpPage />,
      },
      {
        path: "/dashboards/excel/:code",
        element: <DashboardExcel />,
      },
      {
        path: "/next-three-months/:blockName",
        element: (
          <SpoAxiosInterceptor>
            <NextThreeMonthsOperationList />
          </SpoAxiosInterceptor>
        ),
        children: [
          {
            path: ":bu",
            element: (
              <SpoAxiosInterceptor>
                <NextThreeMonthsOperationList />
              </SpoAxiosInterceptor>
            ),
          },
        ],
      },
      {
        path: "/project/:id",
        element: <ProjectPage />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <ProjectHome />,
            errorElement: <ErrorPage />,
          },
          {
            path: "contributors",
            element: <ProjectContributors />,
          },
          {
            path: "plots/",
            element: <ProjectPlots />,
          },
          {
            path: "actions/",
            element: <ProjectActions />,
          },
          {
            path: "plots/add",
            element: <PlotCreate projectContext />,
          },
          {
            path: "plots/:plotId",
            element: <PlotShow projectContext />,
          },
          {
            path: "plots/:plotId/edit",
            element: <PlotUpdate />,
          },
          {
            path: "agreements/",
            element: (
              <QueryBoundaries loadingComponent={<Spinner />}>
                <RoleProtectedRoute requiredRoles={["read:agreement"]}>
                  <ProjectAgreements />
                </RoleProtectedRoute>
              </QueryBoundaries>
            ),
          },
          {
            path: "agreements/add",
            element: <AgreementCreate projectContext />,
          },
          {
            path: "agreements/:agreementId/edit",
            element: <AgreementUpdate projectContext />,
          },
          {
            path: "agreements/:agreementId",
            element: <AgreementShow projectContext />,
          },
          {
            path: "committees",
            element: <ProjectCommittees />,
          },
          {
            path: "studies",
            element: <ProjectStudies />,
          },
          {
            path: "studies/feasibility/:studyRequestId",
            element: <FeasibilityPage routeName="project" />,
            children: [
              { path: "", element: <FeasibilityDetails /> },
              { path: "documents", element: <FeasibilityDocumentsPage /> },
            ],
          },
          {
            path: "studies/feasibility/new",
            element: (
              <FeasibilityProvider>
                <CreateFeasibility routeName="project" />
              </FeasibilityProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsFeasibility routeName="project" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsFeasibility routeName="project" />,
              },
            ],
          },
          {
            path: "studies/feasibility/edit/:studyRequestId/documents",
            element: <EditDocumentsFeasibility routeName="project" />,
          },
          {
            path: "studies/costOfWork/:studyRequestId",
            element: <CostOfWorkPage routeName="project" />,
            children: [
              { path: "", element: <CostOfWorkDetails routeName="project" /> },
              { path: "documents", element: <CostOfWorkDocuments /> },
            ],
          },
          {
            path: "studies/costOfWork/new",
            element: (
              <CostOfWorkProvider>
                <CreateCostOfWork routeName="project" />
              </CostOfWorkProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsCoW routeName="project" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsCoW routeName="project" />,
              },
            ],
          },
          {
            path: "studies/costOfWork/edit/:studyRequestId/documents",
            element: <EditDocumentsCostOfWork routeName="project" />,
          },
          {
            path: "studies/infography/:studyRequestId",
            element: <InfographyPage routeName="project" />,
            children: [
              { path: "", element: <InfographyDetails routeName="project" /> },
              { path: "documents", element: <InfographyDocuments /> },
            ],
          },
          {
            path: "studies/infography/new",
            element: (
              <InfographyProvider>
                <CreateInfography routeName="project" />
              </InfographyProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsInfography routeName="project" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsInfography routeName="project" />,
              },
            ],
          },
          {
            path: "studies/infography/edit/:studyRequestId/documents",
            element: <EditDocumentsInfography routeName="project" />,
          },
          {
            path: "environment",
            element: <ProgramEnvironment routeName="project" />,
          },
          {
            path: "studies/feasibility/edit/:studyRequestId",
            element: (
              <FeasibilityProvider>
                <CreateFeasibility routeName="project" />
              </FeasibilityProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsFeasibility routeName="project" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsFeasibility routeName="project" />,
              },
            ],
          },
          {
            path: "studies/costOfWork/edit/:studyRequestId",
            element: (
              <CostOfWorkProvider>
                <CreateCostOfWork routeName="project" />
              </CostOfWorkProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsCoW routeName="project" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsCoW routeName="project" />,
              },
            ],
          },
          {
            path: "studies/infography/edit/:studyRequestId",
            element: (
              <InfographyProvider>
                <CreateInfography routeName="project" />
              </InfographyProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsInfography routeName="project" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsInfography routeName="project" />,
              },
            ],
          },
        ],
      },
      {
        path: "/operation/:id",
        element: <OperationPage />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: (
              <SpoAxiosInterceptor>
                <OperationSteering />
              </SpoAxiosInterceptor>
            ),
            errorElement: <ErrorPage />,
          },
          {
            path: "documents/",
            element: <OperationDocuments />,
          },
          {
            path: "committees/",
            element: <OperationCommittees />,
          },
          {
            path: "study/",
            element: <OperationStudies />,
          },
          {
            path: "agreements/",
            element: (
              <QueryBoundaries loadingComponent={<Spinner />}>
                <RoleProtectedRoute requiredRoles={["read:agreement"]}>
                  <OperationAgreements />
                </RoleProtectedRoute>
              </QueryBoundaries>
            ),
          },
          {
            path: "agreements/:agreementId/plots/:plotId",
            element: (
              <GroupProtectedRoute
                requiredGroups={[
                  GROUP.JURIDIQUE_ASSISTANT,
                  GROUP.JURIDIQUE_JURISTE,
                ]}
              >
                <PlotShow projectContext={false} />
              </GroupProtectedRoute>
            ),
          },
          {
            path: "agreements/:agreementId/plots/",
            element: (
              <GroupProtectedRoute
                requiredGroups={[
                  GROUP.JURIDIQUE_ASSISTANT,
                  GROUP.JURIDIQUE_JURISTE,
                ]}
              >
                <OperationPlots />
              </GroupProtectedRoute>
            ),
          },
          {
            path: "agreements/:agreementId/plots/add",
            element: (
              <GroupProtectedRoute
                requiredGroups={[
                  GROUP.JURIDIQUE_ASSISTANT,
                  GROUP.JURIDIQUE_JURISTE,
                ]}
              >
                <PlotCreate projectContext={false} />
              </GroupProtectedRoute>
            ),
          },
          {
            path: "agreements/add",
            element: <AgreementCreate projectContext={false} />,
          },
          {
            path: "agreements/:agreementId/edit",
            element: <AgreementUpdate projectContext={false} />,
          },
          {
            path: "agreements/:agreementId",
            element: <AgreementShow projectContext={false} />,
          },
          {
            path: "commercialization/",
            element: (
              <SpoAxiosInterceptor>
                <OperationCommercialization />
              </SpoAxiosInterceptor>
            ),
          },
          {
            path: "program",
            element: <OperationProgram />,
          },
          {
            path: "technical",
            element: (
              <SpoAxiosInterceptor>
                <OperationTechnical />
              </SpoAxiosInterceptor>
            ),
          },
          {
            path: "technical/:registrationNumber",
            element: (
              <SpoAxiosInterceptor>
                <OperationTechnical />
              </SpoAxiosInterceptor>
            ),
          },
          {
            path: "financial",
            element: (
              <SpoAxiosInterceptor>
                <OperationFinancial />
              </SpoAxiosInterceptor>
            ),
          },
          {
            path: "legal",
            element: (
              <QueryBoundaries loadingComponent={<Spinner />}>
                <RoleProtectedRoute requiredRoles={["read:operation.company"]}>
                  <OperationLegal />
                </RoleProtectedRoute>
              </QueryBoundaries>
            ),
          },
          {
            path: "contributors",
            element: <OperationContributors />,
          },
          {
            path: "studies",
            element: <OperationStudies />,
          },
          {
            path: "studies/feasibility/:studyRequestId",
            element: <FeasibilityPage routeName="operation" />,
            children: [
              { path: "", element: <FeasibilityDetails /> },
              { path: "documents", element: <FeasibilityDocumentsPage /> },
            ],
          },
          {
            path: "studies/feasibility/new",
            element: (
              <FeasibilityProvider>
                <CreateFeasibility routeName="operation" />
              </FeasibilityProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsFeasibility routeName="operation" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsFeasibility routeName="operation" />,
              },
            ],
          },
          {
            path: "studies/feasibility/edit/:studyRequestId/documents",
            element: <EditDocumentsFeasibility routeName="operation" />,
          },
          {
            path: "studies/costOfWork/:studyRequestId",
            element: <CostOfWorkPage routeName="operation" />,
            children: [
              {
                path: "",
                element: <CostOfWorkDetails routeName="operation" />,
              },
              { path: "documents", element: <CostOfWorkDocuments /> },
            ],
          },
          {
            path: "studies/costOfWork/new",
            element: (
              <CostOfWorkProvider>
                <CreateCostOfWork routeName="operation" />
              </CostOfWorkProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsCoW routeName="operation" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsCoW routeName="operation" />,
              },
            ],
          },
          {
            path: "studies/costOfWork/edit/:studyRequestId/documents",
            element: <EditDocumentsCostOfWork routeName="operation" />,
          },
          {
            path: "studies/feasibility/edit/:studyRequestId",
            element: (
              <FeasibilityProvider>
                <CreateFeasibility routeName="operation" />
              </FeasibilityProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsFeasibility routeName="operation" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsFeasibility routeName="operation" />,
              },
            ],
          },
          {
            path: "studies/costOfWork/edit/:studyRequestId",
            element: (
              <CostOfWorkProvider>
                <CreateCostOfWork routeName="operation" />
              </CostOfWorkProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsCoW routeName="operation" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsCoW routeName="operation" />,
              },
            ],
          },
          {
            path: "studies/infography/edit/:studyRequestId",
            element: (
              <InfographyProvider>
                <CreateInfography routeName="operation" />
              </InfographyProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsInfography routeName="operation" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsInfography routeName="operation" />,
              },
            ],
          },
          {
            path: "studies/infography/:studyRequestId",
            element: <InfographyPage routeName="operation" />,
            children: [
              {
                path: "",
                element: <InfographyDetails routeName="operation" />,
              },
              { path: "documents", element: <InfographyDocuments /> },
            ],
          },
          {
            path: "studies/infography/new",
            element: (
              <InfographyProvider>
                <CreateInfography routeName="operation" />
              </InfographyProvider>
            ),
            children: [
              {
                path: "",
                element: <StepDetailsInfography routeName="operation" />,
              },
              {
                path: ":studyRequestId/documents",
                element: <StepDocumentsInfography routeName="operation" />,
              },
            ],
          },
          {
            path: "studies/infography/edit/:studyRequestId/documents",
            element: <EditDocumentsInfography routeName="operation" />,
          },
          {
            path: "environment",
            element: <ProgramEnvironment routeName="operation" />,
          },
        ],
      },
    ],
  },
]

export default { baseRouter }

const router = createBrowserRouter(baseRouter)

root.render(
  <StrictMode>
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </DndProvider>
  </StrictMode>,
)
