import { API } from "core/api/axios"
import {
  TEnvironment,
  TReThresold,
  TUpdateEnvironment,
} from "shared/types/environment.type"
import { TIdLabel } from "shared/types/global.type"

async function getEnvironmentById(projectId: number): Promise<TEnvironment> {
  return typeof projectId === "undefined"
    ? Promise.reject(new Error("Invalid project id"))
    : API.get(`/environments/${projectId}`).then((response) => response.data)
}

async function updateEnvironment(
  projectId: number,
  environment: TUpdateEnvironment,
): Promise<TEnvironment> {
  return typeof projectId === "undefined"
    ? Promise.reject(new Error("Invalid project id"))
    : API.patch(`/environment/${projectId}`, environment).then(
        (response) => response.data,
      )
}

async function listReThresold(): Promise<TReThresold[]> {
  return API.get(`/environment/re-thresold`).then((response) => response.data)
}

async function listConstructiveMode(): Promise<TIdLabel[]> {
  return API.get(`/environment/constructive-mode`).then(
    (response) => response.data,
  )
}

async function listHeatingMode(): Promise<TIdLabel[]> {
  return API.get(`/environment/heating-mode`).then((response) => response.data)
}

async function listBiosourcedLabel(): Promise<TIdLabel[]> {
  return API.get(`/environment/biosourced-label`).then(
    (response) => response.data,
  )
}

async function listOtherEnvironmentalLabel(): Promise<TIdLabel[]> {
  return API.get(`/environment/other-environmental-label`).then(
    (response) => response.data,
  )
}

export {
  getEnvironmentById,
  listBiosourcedLabel,
  listConstructiveMode,
  listHeatingMode,
  listOtherEnvironmentalLabel,
  listReThresold,
  updateEnvironment,
}
