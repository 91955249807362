import { ProgressList } from "features/progress/ProgressList"
import { TChildOperation } from "shared/types/operation.type"
import { DceAoDates } from "./DceAoDates"
import { ProductionTracking } from "./production/technical/ProductionTracking"

interface TOperationChildItemTechnicalProps {
  operationChild: TChildOperation
  operationId: number
}

export function OperationChildItemTechnical(
  props: TOperationChildItemTechnicalProps,
) {
  const { operationChild, operationId } = props

  return (
    <>
      <div className="mb-4 mt-4">
        <ProgressList childOperationId={operationChild.id} />
      </div>

      <DceAoDates
        operationId={operationId}
        childOperations={[operationChild]}
        technical
      />

      <ProductionTracking childOperation={operationChild} />
    </>
  )
}

export default OperationChildItemTechnical
