import classNames from "classnames"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import TooltipWarning from "components/Tooltip/TooltipWaring"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import { useGetAppelDefondList } from "core/spo/query-hooks/useAppelDeFond"
import { t } from "i18next"
import { RiErrorWarningFill } from "react-icons/ri"
import { TChildOperation } from "shared/types/operation.type"
import { TLessor } from "shared/types/spo/tranche.type"
import { ProductionTrackingRow } from "./ProductionTrackingRow"
import { SupportingDocumentsExplanation } from "./SupportingDocumentsExplanation"

export interface IProductionTrackingTableProps {
  trancheTravaux: string
  lessor: TLessor | null
  childOperation: TChildOperation
  trancheTravauxLabel: string
}

export function ProductionTrackingTable(props: IProductionTrackingTableProps) {
  const { trancheTravaux, lessor, childOperation, trancheTravauxLabel } = props
  const { toggle, isShowing } = useModal()
  const {
    data: appelsDeFond,
    isLoading,
    error,
  } = useGetAppelDefondList(trancheTravaux, lessor?.code ?? "")

  const hasSupportingDocumentWarning = appelsDeFond?.some(
    (appelDeFond) => appelDeFond.isSupportingDocument === true,
  )

  const headers = [
    `${t("production-task-name")}`,
    `${t("production-end-date")}`,
    `${t("production-certificates")}`,
    `${t("production-supporting-documents")}`,
    `${t("production-call-for-funds")}`,
  ]

  if (isLoading) {
    return (
      <div className="w-full flex justify-center py-4">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-48">
        <p>{t("an-error-has-occurred")}</p>
      </div>
    )
  }

  if (!appelsDeFond || appelsDeFond.length === 0) {
    return (
      <div className="flex items-center justify-center h-48">
        <p>{t("no-data")}</p>
      </div>
    )
  }

  return (
    <div className="Light_Table__Wrapper">
      <table className="table-auto Table">
        <thead>
          <tr className="text-left">
            {headers.map((header, index) => (
              <th
                className={classNames(
                  "Table__Head Table__Top_Border w-36 text-left",
                  index === 1 && "w-10 text-left",
                )}
                key={header}
              >
                <div className="flex items-center">
                  {header}
                  {index === 3 && hasSupportingDocumentWarning && (
                    <TooltipWarning
                      message={`${t("suporting-document-ask")}`}
                      position="center"
                    >
                      <RiErrorWarningFill
                        className="ml-2"
                        color="red"
                        size={20}
                      />
                    </TooltipWarning>
                  )}
                  {index === 3 && (
                    <SupportingDocumentsExplanation
                      toggle={toggle}
                      registrationNumber={childOperation.registrationNumber}
                      isShowing={isShowing}
                      trancheTravaux={trancheTravaux}
                      lessor={lessor}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {appelsDeFond.map((appelDeFond) => (
            <GedAxiosInterceptor>
              <ProductionTrackingRow
                fundRaisingAppeal={appelDeFond}
                trancheTravauxId={trancheTravaux}
                lessor={lessor}
                registrationNumber={childOperation.registrationNumber}
                trancheTravauxLabel={trancheTravauxLabel}
              />
            </GedAxiosInterceptor>
          ))}
        </tbody>
      </table>
    </div>
  )
}
