import { useMutation } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TMoveFile,
  TRenameItem,
  TSharepointFile,
} from "shared/types/sharepoint"
import { deleteItem, moveFile, renameItem, uploadFile } from "../api/file"

function useDeleteItem() {
  return useMutation<void, CustomAxiosError, string>({
    mutationFn: (itemId: string) => deleteItem(itemId),
  })
}

function useUploadFile() {
  return useMutation<void, CustomAxiosError, TSharepointFile>({
    mutationFn: (data: TSharepointFile) => uploadFile(data),
  })
}

function useMoveFile() {
  return useMutation<void, CustomAxiosError, TMoveFile>({
    mutationFn: (data: TMoveFile) => moveFile(data),
  })
}

function useRenameItem() {
  return useMutation<void, CustomAxiosError, TRenameItem>({
    mutationFn: (data: TRenameItem) => renameItem(data),
  })
}

export { useDeleteItem, useMoveFile, useRenameItem, useUploadFile }
