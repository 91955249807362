import classNames from "classnames"
import ItemsCounter from "components/Counter/ItemsCounter"
import ArrowLeft from "components/Icons/ArrowLeft"
import { LinkCustom } from "components/Link/Link"
import LightTable from "components/Table/LightTable"
import { t } from "i18next"
import { Fragment } from "react"
import { BiTask } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import useGetSuppliersLogs from "core/spo/query-hooks/useSuppliers"
import { DateFormatter } from "pages/dashboards/components/DateFormatter"

const headers = [
  t("status"),
  t("insertion-date-hour"),
  t("supplier-name"),
  t("error-message"),
]
const sortableFields: { key: string; value: string[] }[] = [
  { key: `${t("status")}`, value: ["status"] },
  { key: `${t("insertion-date-hour")}`, value: ["dateHour"] },
  { key: `${t("supplier-name")}`, value: ["supplierName"] },
  { key: `${t("error-message")}`, value: ["message"] },
]
export default function UserSuppliersLogs() {
  const navigate = useNavigate()
  const oneMonthDate = new Date()
  oneMonthDate.setMonth(oneMonthDate.getMonth() + 1)

  const { error, isLoading, data: suppliersLogs } = useGetSuppliersLogs()

  const successes = suppliersLogs?.success || []
  const errors = suppliersLogs?.errors || []
  const totalSuppliers = [...successes, ...errors]

  const sortedSuppliers = totalSuppliers?.sort(
    (a, b) => new Date(a.dateHour).getTime() - new Date(b.dateHour).getTime(),
  )

  return (
    <div className="lg:grid lg:grid-cols-6 lg:gap-4 px-8 py-6">
      <div className="w-full">
        <LinkCustom
          icon={ArrowLeft}
          iconSize={16}
          iconPosition="left"
          onClick={() => navigate(`/`)}
        >
          {t("back-to-home")}
        </LinkCustom>
      </div>
      <div className="col-start-6 col-span-1">
        <ItemsCounter
          totalLateItems={errors ? errors.length : 0}
          totalItems={successes ? successes.length : 0}
          title={t("suppliers")}
          isLoading={isLoading}
          error={error}
          errorMessage={t("error-loading-todos")}
          Icon={<BiTask fontSize={18} className="mr-1" />}
          numberColor="text-blue-600"
        />
      </div>
      <div className="col-span-6 flex justify-between">
        <h2 className="font-semibold">{t("my-suppliers")}</h2>
      </div>
      <div className="col-span-6 Light_Table__Wrapper Table__Wrapper_NoTop">
        {totalSuppliers?.length === 0 && !isLoading ? (
          <div className="rounded-lg bg-white p-4 text-center text-primary">
            {t("no-expected-suppliers")}
          </div>
        ) : (
          <LightTable
            showTotal={false}
            className="table-auto"
            items={sortedSuppliers || []}
            headers={headers}
            sortableFields={sortableFields}
            renderRow={(item, index) => (
              <Fragment key={item.id}>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: totalSuppliers
                      ? totalSuppliers.length - 1 === index
                      : 0,
                  })}
                >
                  <div className="flex items-center">
                    <span
                      className={classNames(
                        "rounded-full py-1 px-2",
                        item.status === "Success"
                          ? "bg-green-300"
                          : "text-green-900",
                        item.status === "Error" ? "bg-red-400" : "text-red-900",
                      )}
                    >
                      {item.status === "Success" ? t("success") : t("error")}
                    </span>
                  </div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: totalSuppliers
                      ? totalSuppliers.length - 1 === index
                      : 0,
                  })}
                  style={{ minWidth: "300px", textAlign: "center" }}
                >
                  <div className="flex items-center">
                    <DateFormatter date={new Date(item.dateHour)} withHours />
                  </div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: totalSuppliers
                      ? totalSuppliers.length - 1 === index
                      : 0,
                  })}
                >
                  <div className="flex items-center">{item.supplierName}</div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: totalSuppliers
                      ? totalSuppliers.length - 1 === index
                      : 0,
                  })}
                >
                  <div className="flex items-center">{item.message}</div>
                </td>
              </Fragment>
            )}
          />
        )}
      </div>
    </div>
  )
}
