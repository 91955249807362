import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getEnvironmentById,
  listBiosourcedLabel,
  listConstructiveMode,
  listHeatingMode,
  listOtherEnvironmentalLabel,
  listReThresold,
  updateEnvironment,
} from "core/api/environments"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TEnvironment,
  TReThresold,
  TUpdateEnvironment,
} from "shared/types/environment.type"
import { TIdLabel } from "shared/types/global.type"

function useGetEnvironmentById(projectId: number) {
  return useQuery<TEnvironment, CustomAxiosError>({
    queryKey: ["getEnvironment", projectId],
    queryFn: () => getEnvironmentById(projectId),
  })
}

function useUpdateEnvironment(id: number) {
  return useMutation<TEnvironment, CustomAxiosError, TUpdateEnvironment>({
    mutationFn: (environment: TUpdateEnvironment) =>
      updateEnvironment(id, environment),
  })
}

function useListReThresold() {
  return useQuery<TReThresold[], CustomAxiosError>({
    queryKey: ["listReThresold"],
    queryFn: () => listReThresold(),
  })
}

function useListConstructiveMode() {
  return useQuery<TIdLabel[], CustomAxiosError>({
    queryKey: ["listConstructiveMode"],
    queryFn: () => listConstructiveMode(),
  })
}

function useListHeatingMode() {
  return useQuery<TIdLabel[], CustomAxiosError>({
    queryKey: ["listHeatingMode"],
    queryFn: () => listHeatingMode(),
  })
}

function useListBiosourcedLabels() {
  return useQuery<TIdLabel[], CustomAxiosError>({
    queryKey: ["listBiosourcedLabel"],
    queryFn: () => listBiosourcedLabel(),
  })
}

function useListOtherEnvironmentalLabel() {
  return useQuery<TIdLabel[], CustomAxiosError>({
    queryKey: ["listOtherEnvironmentalLabel"],
    queryFn: () => listOtherEnvironmentalLabel(),
  })
}

export {
  useGetEnvironmentById,
  useListBiosourcedLabels,
  useListConstructiveMode,
  useListHeatingMode,
  useListOtherEnvironmentalLabel,
  useListReThresold,
  useUpdateEnvironment,
}
