import { useQueryClient } from "@tanstack/react-query"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useAddBuildingPermitDate,
  useUpdateBuildingPermitDate,
} from "core/query-hooks/useBuildingPermits"
import { t } from "i18next"
import { MdEdit } from "react-icons/md"
import { toast } from "react-toastify"
import PERMIT_STEP from "shared/resources/permit-step"
import {
  TBuildingPermit,
  TBuildingPermitLabel,
  TBuildingPermitStep,
  TUpdateBuildingPermitDate,
} from "shared/types/building-permit.type"
import { EditBuildingPermitDateModal } from "./EditBuildingPermitDateModal"

export interface IEditBuildingPermitDate {
  buildingPermit: TBuildingPermit | undefined
  buildingPermitLabel: TBuildingPermitLabel
  buildingPermitStep: TBuildingPermitStep
  childOperationId: number
}

export function EditBuildingPermitDate(props: IEditBuildingPermitDate) {
  const {
    buildingPermit,
    buildingPermitLabel,
    buildingPermitStep,
    childOperationId,
  } = props
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const queryClient = useQueryClient()
  const addMutation = useAddBuildingPermitDate()
  const updateMutation = useUpdateBuildingPermitDate(buildingPermit?.id!)

  async function handleSubmitPermitDate(
    date: Date | undefined | null,
    isDateDefinitive: boolean,
    delay: number,
  ) {
    if (!buildingPermit) {
      addMutation.mutateAsync(
        {
          childOperation: childOperationId,
          buildingPermitLabelId: buildingPermitLabel.id,
          buildingPermitStepId: buildingPermitStep.id,
          isDateDefinitive,
          date: date!,
          delayDepot: delay,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ["listBuildingPermits", childOperationId],
            })
            toast.success(`${t("toast-permit-update-success")}`)
            toggleModal()
          },
          onError: (err) => {
            toast.error(
              `${t("toast-permit-update-error")} ${
                err.response?.data.error.message
              }`,
            )
          },
        },
      )
    } else {
      const updateData: TUpdateBuildingPermitDate = {
        date,
        isDateDefinitive,
        delayDepot: delay,
      }

      updateMutation.mutateAsync(updateData, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["listBuildingPermits", childOperationId],
          })
          toast.success(`${t("toast-permit-update-success")}`)
          toggleModal()
        },
        onError: (err) => {
          toast.error(
            `${t("toast-permit-update-error")} ${
              err.response?.data.error.message
            }`,
          )
        },
      })
    }
  }

  return (
    <div className="flex text-left mx-2 items-center">
      {buildingPermit && buildingPermit.date ? (
        <div className="mr-2">
          <DateFormat
            key={buildingPermit.id}
            isDefinitive={buildingPermit.isDateDefinitive}
            date={buildingPermit.date}
          />
        </div>
      ) : (
        "-"
      )}
      <PermissionsGuard requiredRoles={["create:permit"]}>
        {buildingPermitLabel && buildingPermitStep && (
          <ButtonIcon
            className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            type="button"
            onClick={toggleModal}
          >
            <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
          </ButtonIcon>
        )}
      </PermissionsGuard>
      {isModalShowed && (
        <EditBuildingPermitDateModal
          isModalShowed={isModalShowed}
          toggleModal={toggleModal}
          initialDate={buildingPermit?.date}
          isDefinitive={buildingPermit?.isDateDefinitive || false}
          onSubmit={(newPermitDate, isDefinitive, delay) =>
            handleSubmitPermitDate(newPermitDate, isDefinitive, delay)
          }
          title={`${t("edit-permit-title")}`}
          labelDate={`${t("date")}`}
          delayOption={buildingPermitStep.id === PERMIT_STEP.DEPOT.id}
          delayDepot={buildingPermit?.delayDepot}
          requiredDate={
            !buildingPermit?.date ||
            ![
              PERMIT_STEP.DEPOT_PIECES_COMPLEMENTAIRES.id,
              PERMIT_STEP.REFUS.id,
              PERMIT_STEP.RECOURS_GRACIEUX.id,
              PERMIT_STEP.RECOURS_CONTENTIEUX.id,
              PERMIT_STEP.LEVEE.id,
              PERMIT_STEP.REJET_RECOURS_GRACIEUX.id,
            ].includes(buildingPermitStep.id)
          }
        />
      )}
    </div>
  )
}
