import { useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import { TSupplierLog } from "shared/types/spo/supplier.type"
import getSupplierFromSpo from "../api/supplier"

export default function useGetSuppliersLogs() {
  return useQuery<TSupplierLog, CustomAxiosError>({
    queryKey: ["getSuppliersFromSpo"],
    queryFn: () => getSupplierFromSpo(),
  })
}
