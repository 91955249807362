import classNames from "classnames"
import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import LightTable from "components/Table/LightTable"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useGetTranchesCommerciales } from "core/spo/query-hooks/useTranches"
import { t } from "i18next"
import { MdDateRange } from "react-icons/md"
import { PiSnowflakeBold } from "react-icons/pi"
import { Link } from "react-router-dom"
import EditableDateCell from "./EditableDateCell"

export interface ITranchesCommercialesOverview {
  registrationId: string
}

export function TranchesCommercialesOverview(
  props: ITranchesCommercialesOverview,
) {
  const { registrationId } = props
  const { data, isLoading, error } = useGetTranchesCommerciales(registrationId)

  if (isLoading) return <Spinner />

  const tranchesCommerciales = data?.tranchesCommerciales.map((tc) => {
    return {
      ...tc,
      stockFree: tc.stock.free ?? 0,
      stockTotal: tc.stock.total ?? 0,
    }
  })

  if (error)
    return (
      <p className="text-sm text-red-500">
        <h5>{t("no-data-found-message")}</h5>
        <span> {error.response?.data.message} </span>
      </p>
    )

  const headers = [
    t("label"),
    t("put-on-market"),
    t("stock"),
    t("reserved-CA"),
    t("acted-CA"),
    t("first-VEFA-act"),
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("label")}`, value: ["label"] },
    { key: `${t("put-on-market")}`, value: ["commercialization", "date"] },
    { key: `${t("stock")}`, value: ["stockFree", "stockTotal"] },
    {
      key: `${t("reserved-CA")}`,
      value: ["reservationRevenue", "percentage"],
    },
    { key: `${t("acted-CA")}`, value: ["deedRevenue", "percentage"] },
    { key: `${t("first-VEFA-act")}`, value: ["VEFA", "date"] },
  ]

  return (
    <Box classNames="p-0 border-b-0">
      <div className="flex items-center px-3.5 py-2 justify-between">
        <div className="flex items-center text-cyan-900 mr-1">
          <MdDateRange size={22} />
          <h3 className="text-xs uppercase font-medium pl-1">
            {t("tranche-commerciale-overview")}
          </h3>
        </div>
        <div className="text-primary-dark">
          <TooltipWithHover position="left" message={t("spo-informations")}>
            <Link
              className="text-cyan-900 underline uppercase text-xs"
              to={`${process.env.REACT_APP_SPO_URL}#/planning/gantt?operation=${registrationId}`}
              target="_blank"
            >
              SPO
            </Link>
          </TooltipWithHover>
        </div>
      </div>

      {tranchesCommerciales?.length ? (
        <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
          <LightTable
            showTotal={false}
            className="table-auto"
            items={tranchesCommerciales}
            headers={headers}
            sortableFields={sortableFields}
            renderRow={(item, index) => {
              let isFrozen = false
              if (item.freezeDate && !item.thawDate) isFrozen = true
              const displayEditDate =
                index !== tranchesCommerciales.length - 1 && !isFrozen

              return (
                <>
                  <td
                    className={classNames({
                      "bg-blue-200": isFrozen,
                      Table__Bottom_Border:
                        tranchesCommerciales.length === index,
                    })}
                  >
                    <div className="flex items-center">
                      {item.label}
                      {isFrozen ? (
                        <div className="pl-1">
                          <TooltipWithHover
                            position="right"
                            message={`${t("this-tranche-is-frozen")}`}
                          >
                            <PiSnowflakeBold size={20} />
                          </TooltipWithHover>
                        </div>
                      ) : null}
                    </div>
                  </td>
                  <td
                    className={classNames({
                      "bg-blue-200": isFrozen,
                      Table__Bottom_Border:
                        tranchesCommerciales.length === index,
                    })}
                  >
                    <div className="flex items-center justify-between">
                      {item.commercialization && displayEditDate && (
                        <EditableDateCell
                          date={item.commercialization || null}
                          title={t("edit-commercialization-date")}
                          labelDate={t("label-commercialization-date")}
                          registrationId={registrationId}
                        />
                      )}
                    </div>
                  </td>

                  <td
                    className={classNames({
                      "bg-blue-200": isFrozen,
                      Table__Bottom_Border:
                        tranchesCommerciales.length === index,
                    })}
                  >
                    <div className="flex items-center">
                      {item.stock.free === null
                        ? item.stock.total
                        : `${item.stock.free} / ${item.stock.total}`}
                    </div>
                  </td>

                  <td
                    className={classNames({
                      "bg-blue-200": isFrozen,
                      Table__Bottom_Border:
                        tranchesCommerciales.length === index,
                    })}
                  >
                    <div className="flex items-center">
                      {item.reservationRevenue &&
                      item.reservationRevenue.percentage
                        ? `${+(
                            item.reservationRevenue.percentage * 100
                          ).toFixed(2)} %`
                        : "-"}
                    </div>
                  </td>

                  <td
                    className={classNames({
                      "bg-blue-200": isFrozen,
                      Table__Bottom_Border:
                        tranchesCommerciales.length === index,
                    })}
                  >
                    <div className="flex items-center">
                      {item.deedRevenue && item.deedRevenue.percentage
                        ? `${+(item.deedRevenue.percentage * 100).toFixed(2)} %`
                        : "-"}
                    </div>
                  </td>

                  <td
                    className={classNames({
                      "bg-blue-200": isFrozen,
                      Table__Bottom_Border:
                        tranchesCommerciales.length === index,
                    })}
                  >
                    <div className="flex items-center justify-between">
                      {item.VEFA && displayEditDate && (
                        <EditableDateCell
                          date={item.VEFA || null}
                          title={t("edit-vefa-date")}
                          labelDate={t("label-vefa-date")}
                          registrationId={registrationId}
                        />
                      )}
                    </div>
                  </td>
                </>
              )
            }}
          />
        </div>
      ) : (
        <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
          <p className="text-sm px-2 py-2 Color__Primary">
            {t("reservations-no-data")}
          </p>
        </div>
      )}
    </Box>
  )
}
