import classNames from "classnames"
import Avatar from "components/Avatar/Avatar"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { useGetUserByAzureId } from "core/query-hooks/useUsers"
import { format } from "date-fns"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { sortByDate } from "pages/dashboards/study-requests/dashboard-util"
import { ReactNode } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { GROUP } from "shared/resources/groups.resources"
import {
  ASSIGNMENT_COMMENT,
  RESTITUTION_COMMENT,
  STAND_BY_PRIORIZATION_COMMENT,
  STAND_BY_RESTITUTION_COMMENT,
  VALIDATION_COMMENT,
} from "shared/resources/study-request.resources"
import {
  TStudyRequest,
  TStudyRequestHistory,
  TStudyRights,
} from "shared/types/study.type"
import { DisplayStandbyComment } from "./DisplayStandbyComment"
import { EditAttributionModal } from "./EditAttributionModal"
import { EditVersionModal } from "./EditVersionModal"
import FollowUpCancelled from "./FollowUpCancelled"
import { CostOfWorkFilesRestitution } from "./costOfWork/restitution/CostOfWorkFilesRestitution"
import { FeasibilityFilesRestitution } from "./feasibilities/restitution/FeasibilityFilesRestitution"
import "./follow-up-stepper.scss"
import { InfographyFilesRestitution } from "./infographies/restitution/InfographyFilesRestitution"
import {
  STATUS_CANCELLED,
  STATUS_CREATED,
  STATUS_DRAFT,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_REJECTED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

export interface FollowUpStepperProps {
  studyRequest: TStudyRequest | undefined
  studyRequestHistory: TStudyRequestHistory[] | undefined
  studyRequestType: number
  currentStatusId: number
  categoryId: number
  rights: TStudyRights | undefined
  renderForm: (statusId: number) => ReactNode
}

function RenderUser({ azureId }: { azureId: string }) {
  const { data: user } = useGetUserByAzureId(azureId)

  if (!user) return null

  return (
    <div className="inline-flex items-center ml-2" key={nanoid()}>
      <Avatar size="small" user={user} />{" "}
      <div className="ml-1">
        {user?.firstName} {user?.lastName}
      </div>
    </div>
  )
}

export default function FollowUpStepper(props: FollowUpStepperProps) {
  const {
    renderForm,
    currentStatusId,
    categoryId,
    studyRequest,
    studyRequestHistory,
    studyRequestType,
    rights,
  } = props

  const hasRights = (statusToCheck: number): boolean => {
    return (
      rights?.statuses.find((right) => right.id === statusToCheck)?.edit ||
      false
    )
  }

  const creation =
    studyRequestHistory?.find(
      (element) => element.statusId === STATUS_CREATED,
    ) ||
    studyRequestHistory?.find(
      (element) => element.statusId === STATUS_VALIDATED,
    )
  const validation = studyRequestHistory
    ?.slice()
    .reverse()
    .find(
      (element: TStudyRequestHistory) => element.statusId === STATUS_VALIDATED,
    )
  const rejection = studyRequestHistory?.find(
    (element) => element.statusId === STATUS_REJECTED,
  )
  const priorisationAndAttribution = studyRequestHistory?.find(
    (element) => element.statusId === STATUS_PRIORIZED_AND_ASSIGNED,
  )
  const restitution = studyRequestHistory?.find(
    (element) => element.statusId === STATUS_RETURNED,
  )
  const standbyPriorization = studyRequestHistory?.find(
    (element: TStudyRequestHistory) =>
      element.statusId === STATUS_PRIORIZATION_STANDBY,
  )

  const standbyRestitution = studyRequestHistory?.find(
    (element: TStudyRequestHistory) =>
      element.statusId === STATUS_RESTITUTION_STANDBY,
  )

  const validationComment = studyRequest?.comments?.find(
    (c) => c.type.id === VALIDATION_COMMENT.id,
  )

  const assignmentComment = studyRequest?.comments?.find(
    (c) => c.type.id === ASSIGNMENT_COMMENT.id,
  )

  const restitutionComment = studyRequest?.comments?.find(
    (c) => c.type.id === RESTITUTION_COMMENT.id,
  )

  const standbyPriorizationComment = studyRequest?.comments
    ?.filter((c) => c.type.id === STAND_BY_PRIORIZATION_COMMENT.id)
    .sort((a, b) => sortByDate(a.creationDate, b.creationDate))[0]

  const standbyRestitutionComment = studyRequest?.comments
    ?.filter((c) => c.type.id === STAND_BY_RESTITUTION_COMMENT.id)
    .sort((a, b) => sortByDate(a.creationDate, b.creationDate))[0]

  const getEndingStandbyRqstHistry = (): TStudyRequestHistory | undefined => {
    let hasStandby = false
    let returnedSrh: TStudyRequestHistory | undefined
    studyRequestHistory
      ?.slice()
      .reverse()
      .forEach((srh) => {
        if (srh.statusId === STATUS_PRIORIZATION_STANDBY) {
          hasStandby = true
        }
        if (srh.statusId === STATUS_VALIDATED && hasStandby) {
          returnedSrh = srh
        }
      })
    return returnedSrh
  }
  const removeStandby = getEndingStandbyRqstHistry()

  if (currentStatusId === STATUS_CANCELLED) {
    return (
      <FollowUpCancelled
        studyRequest={studyRequest}
        studyRequestHistory={studyRequestHistory}
      />
    )
  }

  return (
    <ul className="follow-up-stepper">
      {/* Création */}
      <li
        className={classNames("pb-4 follow-up-stepper-li", {
          "step-active": currentStatusId < STATUS_CREATED,
          "step-done": currentStatusId > STATUS_DRAFT,
          "step-rejected": currentStatusId === STATUS_CANCELLED,
        })}
      >
        {currentStatusId > STATUS_DRAFT && (
          <RiCheckboxCircleFill fontSize={23} className="step-done-icon z-10" />
        )}
        <div>
          <h5 className="Color__Primary -mb-1 text-sm">{`${t(
            "studyRequest-projectCreation",
          )}`}</h5>
        </div>
        <span className="text-xs step-more-info">
          {creation ? (
            <>
              {`${t("by")} ${creation.operator.firstName} ${
                creation.operator.lastName
              }, ${t("the")} ${format(new Date(creation.date), "dd/MM/yyyy")}`}
            </>
          ) : null}
        </span>
      </li>

      {/* Demande de validation */}
      <li
        className={classNames("pb-4 follow-up-stepper-li", {
          "step-active": currentStatusId === STATUS_CREATED,
          "step-done":
            currentStatusId > STATUS_CREATED &&
            currentStatusId !== STATUS_CANCELLED &&
            currentStatusId !== STATUS_REJECTED,
          "step-rejected":
            currentStatusId === STATUS_CANCELLED ||
            currentStatusId === STATUS_REJECTED,
        })}
      >
        {currentStatusId > STATUS_CREATED &&
          currentStatusId !== STATUS_REJECTED && (
            <RiCheckboxCircleFill
              fontSize={23}
              className="step-done-icon z-10"
            />
          )}
        <div>
          <h5 className="Color__Primary -mb-1 text-sm">
            {`${
              hasRights(STATUS_VALIDATED)
                ? t(
                    currentStatusId === STATUS_REJECTED
                      ? "studyRequest-rejectionRequest"
                      : "studyRequest-validationRequest",
                  )
                : t("studyRequest-no-rights-validation-request")
            }`}
          </h5>
        </div>
        {validation ? (
          <span className="text-xs step-more-info">
            {`${t("by")} ${validation.operator.firstName} ${
              validation.operator.lastName
            }, ${t("the")} ${format(new Date(validation.date), "dd/MM/yyyy")}`}
          </span>
        ) : null}
        {rejection ? (
          <span className="text-xs step-more-info">
            {`${t("by")} ${rejection.operator.firstName} ${
              rejection.operator.lastName
            }, ${t("the")} ${format(new Date(rejection.date), "dd/MM/yyyy")}`}
          </span>
        ) : null}

        <div>
          {validation && validationComment ? (
            <span
              className={classNames(
                "Color__Primary text-xs font-semibold inline-block py-1 px-2 rounded-lg last:mr-0 mr-1 mt-2",
              )}
            >
              {validationComment.comment}
            </span>
          ) : null}
          {rejection && studyRequest?.validationComment && (
            <span
              className={classNames(
                "Color__Primary text-xs font-semibold inline-block py-1 px-2 rounded-lg last:mr-0 mr-1 mt-2 bg-red-200",
              )}
            >
              {studyRequest.validationComment}
            </span>
          )}
        </div>

        {currentStatusId === STATUS_CREATED &&
          hasRights(STATUS_VALIDATED) &&
          renderForm(currentStatusId)}
      </li>

      {/* Priorisation et attribution */}
      <li
        className={classNames("pb-4 follow-up-stepper-li", {
          "step-active": currentStatusId === STATUS_VALIDATED,
          "step-standby": currentStatusId === STATUS_PRIORIZATION_STANDBY,
          "step-done":
            currentStatusId >= STATUS_PRIORIZED_AND_ASSIGNED &&
            currentStatusId !== STATUS_PRIORIZATION_STANDBY &&
            currentStatusId !== STATUS_CANCELLED &&
            currentStatusId !== STATUS_REJECTED,
          "step-rejected": currentStatusId === STATUS_CANCELLED,
        })}
      >
        {currentStatusId >= STATUS_PRIORIZED_AND_ASSIGNED &&
          currentStatusId !== STATUS_PRIORIZATION_STANDBY && (
            <RiCheckboxCircleFill
              fontSize={23}
              className="step-done-icon z-10"
            />
          )}
        <div>
          <h5 className="Color__Primary -mb-1 text-sm">
            {`${
              hasRights(STATUS_PRIORIZED_AND_ASSIGNED)
                ? t("study-step-priorise-next")
                : t("studyRequest-no-rights-priorisation-and-attribution")
            }`}
          </h5>
        </div>
        {currentStatusId === STATUS_PRIORIZATION_STANDBY && (
          <div className="flex items-center justify-between">
            <div className="flex flex-col mt-1">
              <span className="text-xs step-more-info">
                {standbyPriorization ? (
                  <>
                    {`${t("put-in-standby")} ${t("by")} ${
                      standbyPriorization.operator.firstName
                    } ${standbyPriorization.operator.lastName}, ${t(
                      "the",
                    )} ${format(
                      new Date(standbyPriorization.date),
                      "dd/MM/yyyy",
                    )}`}
                  </>
                ) : null}
              </span>
            </div>
            {standbyPriorizationComment ? (
              <DisplayStandbyComment comment={standbyPriorizationComment} />
            ) : null}
          </div>
        )}
        {(currentStatusId === STATUS_VALIDATED ||
          currentStatusId === STATUS_PRIORIZATION_STANDBY) &&
          hasRights(STATUS_PRIORIZED_AND_ASSIGNED) &&
          renderForm(currentStatusId)}
        {currentStatusId >= STATUS_PRIORIZED_AND_ASSIGNED &&
          (currentStatusId < STATUS_PRIORIZATION_STANDBY ||
            currentStatusId === STATUS_RESTITUTION_STANDBY) && (
            <div className="flex flex-col mt-1">
              <span className="text-xs step-more-info">
                {priorisationAndAttribution ? (
                  <>
                    {`${t("by")} ${
                      priorisationAndAttribution.operator.firstName
                    } ${priorisationAndAttribution.operator.lastName}, ${t(
                      "the",
                    )} ${format(
                      new Date(priorisationAndAttribution.date),
                      "dd/MM/yyyy",
                    )}`}
                  </>
                ) : null}
              </span>
              <div className="inline-flex items-center">
                <span className="text-xs step-more-info">
                  {studyRequest?.version ? (
                    <div className="inline-flex items-center">
                      {`${t("version-number")}`} : {studyRequest.version}
                      {studyRequest?.statusId !== STATUS_RETURNED ? (
                        <GroupsGuard
                          requiredGroups={[
                            GROUP.CONCEPTION_INFOGRAPHISTE_RESPONSABLE,
                            GROUP.CONCEPTION_CONCEPTEUR_RESPONSABLE,
                            GROUP.CONCEPTION_ECONOMISTE_RESPONSABLE,
                          ]}
                        >
                          <EditVersionModal studyRequest={studyRequest} />
                        </GroupsGuard>
                      ) : null}
                    </div>
                  ) : null}
                </span>
              </div>
              <span className="text-xs step-more-info">
                {assignmentComment ? (
                  <>
                    {`${t("comment")}`} : {assignmentComment.comment}
                  </>
                ) : null}
              </span>
              <span className="text-xs step-more-info">
                {studyRequest?.priorisation ? (
                  <>
                    {`${t("priorisation")}`} : {studyRequest.priorisation}{" "}
                  </>
                ) : null}
              </span>
              <span className="text-xs step-more-info">
                {studyRequest?.estimatedRestitutionDate ? (
                  <>
                    {`${t("estimated-restitution-date")} : ${format(
                      new Date(studyRequest.estimatedRestitutionDate),
                      "dd/MM/yyyy",
                    )}`}
                  </>
                ) : null}
              </span>
              <div className="inline-flex items-center">
                <span className="text-xs step-more-info">
                  {studyRequest?.assignedUsers &&
                  studyRequest.assignedUsers.length > 0
                    ? `${t("studyRequest-attribution")} :`
                    : null}
                  {studyRequest?.assignedUsers ? (
                    <div className="inline-flex items-center">
                      {studyRequest.assignedUsers.map((azureId: string) => (
                        <RenderUser azureId={azureId} />
                      ))}
                      {studyRequest?.statusId !== STATUS_RETURNED ? (
                        <GroupsGuard
                          requiredGroups={[
                            GROUP.CONCEPTION_INFOGRAPHISTE_RESPONSABLE,
                            GROUP.CONCEPTION_CONCEPTEUR_RESPONSABLE,
                            GROUP.CONCEPTION_ECONOMISTE_RESPONSABLE,
                          ]}
                        >
                          <EditAttributionModal studyRequest={studyRequest} />
                        </GroupsGuard>
                      ) : null}
                    </div>
                  ) : null}
                </span>
              </div>
            </div>
          )}
        {currentStatusId >= STATUS_PRIORIZED_AND_ASSIGNED &&
          currentStatusId !== STATUS_PRIORIZATION_STANDBY && (
            <div className="flex flex-col">
              <span className="text-xs step-more-info">
                {standbyPriorization ? (
                  <p>
                    {`${t("studyRequest-startingDate")}: ${format(
                      new Date(standbyPriorization.date),
                      "dd/MM/yyyy",
                    )}`}
                  </p>
                ) : null}
              </span>
              <span className="text-xs step-more-info">
                {removeStandby ? (
                  <p>
                    {`${t("studyRequest-endingDate")}: ${format(
                      new Date(removeStandby.date),
                      "dd/MM/yyyy",
                    )}`}
                  </p>
                ) : null}
              </span>
            </div>
          )}
      </li>

      {/* Restitution */}
      <li
        className={classNames("pb-4 follow-up-stepper-li", {
          "step-active":
            currentStatusId === STATUS_PRIORIZED_AND_ASSIGNED ||
            currentStatusId === STATUS_RESTITUTION_STANDBY,
          "step-done":
            currentStatusId >= STATUS_RETURNED &&
            currentStatusId !== STATUS_PRIORIZATION_STANDBY,
          "step-rejected": currentStatusId === STATUS_CANCELLED,
          "step-standby": currentStatusId === STATUS_RESTITUTION_STANDBY,
        })}
      >
        {currentStatusId >= STATUS_RETURNED &&
          currentStatusId < STATUS_PRIORIZATION_STANDBY && (
            <RiCheckboxCircleFill
              fontSize={23}
              className="step-done-icon z-10"
            />
          )}
        <div>
          <h5 className="Color__Primary mb-1 text-sm">
            {`${
              hasRights(STATUS_RETURNED)
                ? t("study-step-restitution-next")
                : t("studyRequest-no-rights-restitution")
            }`}
          </h5>
        </div>
        {currentStatusId === STATUS_RESTITUTION_STANDBY && (
          <div className="flex items-center justify-between">
            <div className="flex flex-col mt-1">
              <span className="text-xs step-more-info">
                {standbyRestitution ? (
                  <>
                    {`${t("put-in-standby")} ${t("by")} ${
                      standbyRestitution.operator.firstName
                    } ${standbyRestitution.operator.lastName}, ${t(
                      "the",
                    )} ${format(
                      new Date(standbyRestitution.date),
                      "dd/MM/yyyy",
                    )}`}
                  </>
                ) : null}
              </span>
            </div>
            {standbyRestitutionComment ? (
              <DisplayStandbyComment comment={standbyRestitutionComment} />
            ) : null}
          </div>
        )}
        {currentStatusId >= STATUS_RETURNED &&
          currentStatusId < STATUS_RESTITUTION_STANDBY && (
            <div className="flex flex-col mt-1">
              <span className="text-xs step-more-info">
                {restitution ? (
                  <>
                    {`${t("by")} ${restitution.operator.firstName} ${
                      restitution.operator.lastName
                    }, ${format(new Date(restitution.date), "dd/MM/yyyy")}`}
                    {studyRequestType === TYPE_FEASIBILITY && (
                      <>
                        <p>
                          {`${t(
                            "number-of-lots",
                          )} : ${studyRequest?.numberOfLotsReturned}`}
                        </p>
                        <span className="text-xs step-more-info">
                          {restitutionComment && (
                            <>
                              {`${t("comment")}`} : {restitutionComment.comment}
                            </>
                          )}
                        </span>
                      </>
                    )}
                    {studyRequestType === TYPE_INFOGRAPHY && (
                      <>
                        <p>
                          {`${t(
                            "followup-stepper-number-of-pieces",
                          )} : ${studyRequest?.numberOfPieces}`}
                        </p>
                        <span className="text-xs step-more-info">
                          {restitutionComment ? (
                            <>
                              {`${t("comment")}`} : {restitutionComment.comment}
                            </>
                          ) : null}
                        </span>
                      </>
                    )}
                    {studyRequestType === TYPE_COST_OF_WORK && (
                      <>
                        {studyRequest?.creationNumberOfHours && (
                          <p>
                            {`${t(
                              "cost-of-work-restitution-followup-form-number-of-hours-label",
                            )} : ${studyRequest?.creationNumberOfHours}`}
                          </p>
                        )}
                        <p>
                          {`${t(
                            "followup-stepper-technical-manager-number-of-hours",
                          )} : ${studyRequest?.technicalManagerNumberOfHours}`}
                        </p>
                        <p>
                          {`${t(
                            "followup-stepper-technical-mail-creation-number-of-hours",
                          )} : ${studyRequest?.mailCreationNumberOfHours}`}
                        </p>
                        {studyRequest?.technicalManager ? (
                          <span className="text-xs step-more-info">
                            {`${t("followup-stepper-technical-manager")}`} :
                            <RenderUser
                              azureId={studyRequest.technicalManager.azureId}
                            />
                          </span>
                        ) : null}
                        <div className="text-xs step-more-info">
                          {restitutionComment && (
                            <>
                              {`${t("comment")}`} : {restitutionComment.comment}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {studyRequestType !== TYPE_COST_OF_WORK && (
                      <p>
                        {`${t(
                          "followup-stepper-number-of-hours",
                        )} : ${studyRequest?.creationNumberOfHours}`}
                      </p>
                    )}
                    {studyRequestType === TYPE_FEASIBILITY && (
                      <FeasibilityFilesRestitution categoryId={categoryId} />
                    )}
                    {studyRequestType === TYPE_INFOGRAPHY && (
                      <InfographyFilesRestitution categoryId={categoryId} />
                    )}
                    {studyRequestType === TYPE_COST_OF_WORK && (
                      <CostOfWorkFilesRestitution categoryId={categoryId} />
                    )}
                  </>
                ) : null}
              </span>
            </div>
          )}
        {(currentStatusId === STATUS_PRIORIZED_AND_ASSIGNED ||
          currentStatusId === STATUS_RESTITUTION_STANDBY) &&
          hasRights(STATUS_RETURNED) &&
          renderForm(currentStatusId)}
      </li>
    </ul>
  )
}
