import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { useCreateEnvironmentBuilding } from "core/query-hooks/useEnvironmentBuildings"
import { t } from "i18next"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { OPERATION } from "shared/resources/environment.resources"
import * as yup from "yup"
import { TEnvironmentBuilding } from "shared/types/environmentBuilding.type"
import { Button } from "components"
import { useParams } from "react-router-dom"

type FormValues = {
  projectId: number
  bbioMaxModule: number
  bbioProject: number
  buildingLabel: string
}

export interface IAddEnvironmentBuildingProps {
  routeName: string
  environmentBuildings?: TEnvironmentBuilding[]
  toggleModal: () => void
}

export default function AddEnvironementBuildingForm(
  props: IAddEnvironmentBuildingProps,
) {
  const { routeName, environmentBuildings, toggleModal } = props
  const { id } = useParams()

  const queryClient = useQueryClient()
  const createEnvironmentBuilding = useCreateEnvironmentBuilding()

  const validationSchema = yup.object().shape({
    bbioProject: yup.number().required(() => `${t("required-bbio-project")}`),
    bbioMaxModule: yup
      .number()
      .required(() => `${t("required-bbio-max-module")}`),
    buildingLabel: yup
      .string()
      .required(() => `${t("required-building-label")}`),
    projectId: yup.number().required(),
  })

  function getNextBuildingLabel(existingLabels: string[]): string {
    const alphabet = "ABCDE".split("")
    return alphabet.find((letter) => !existingLabels.includes(letter)) || ""
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      projectId: +id!,
      buildingLabel: getNextBuildingLabel(
        environmentBuildings?.map((building) => building.buildingLabel) || [],
      ),
    },
  })

  const submitForm = (data: FormValues) => {
    createEnvironmentBuilding.mutate(
      { ...data, projectId: +id! },
      {
        onError: (err) => {
          toast.error(
            `${t("environment-building-create-error")} : ${err.response?.data.message}`,
          )
        },
        onSuccess() {
          toast.success(`${t("environment-building-create-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["listEnvironmentBuildings"],
          })
          toggleModal()
        },
      },
    )
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitForm)()
  }

  return (
    <form onSubmit={onSubmit}>
      {routeName === OPERATION && (
        <>
          <div className="Text__Field_Container">
            <div className="Text__Field_Container">
              <label htmlFor="building-label">
                {`${t("building")}`}
                <input
                  id="building-label"
                  className="Text__Field"
                  type="text"
                  step="0.1"
                  {...register("buildingLabel")}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.buildingLabel && (
                <span className="text-red-500 text-xs">
                  {errors.buildingLabel.message}
                </span>
              )}
            </div>
          </div>
          <div className="Text__Field_Container">
            <div className="Text__Field_Container">
              <label htmlFor="bbio-max-module">
                {`${t("bbio-max-module")}`}
                <Controller
                  name="bbioMaxModule"
                  control={control}
                  render={({ field }) => (
                    <input
                      id="bbio-max-module"
                      className="Text__Field"
                      {...field}
                      type="number"
                      step="0.1"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  )}
                />
              </label>
              {errors.bbioMaxModule && (
                <span className="text-red-500 text-xs">
                  {errors.bbioMaxModule.message}
                </span>
              )}
            </div>
          </div>
          <div className="Text__Field_Container">
            <div className="Text__Field_Container">
              <label htmlFor="bbio-project">
                {`${t("bbio-project")}`}
                <Controller
                  name="bbioProject"
                  control={control}
                  render={({ field }) => (
                    <input
                      id="bbio-project"
                      className="Text__Field"
                      {...field}
                      type="number"
                      step="0.1"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  )}
                />
              </label>
              {errors.bbioProject && (
                <span className="text-red-500 text-xs">
                  {errors.bbioProject.message}
                </span>
              )}
            </div>
          </div>
          <div className="Text__Field_Container flex justify-between my-4">
            <Button size="small" mode="danger" onClick={toggleModal}>
              {t("cancel")}
            </Button>
            <Button
              size="small"
              mode="primary"
              type="submit"
              isLoading={createEnvironmentBuilding.isPending}
            >
              {t("add")}
            </Button>
          </div>
        </>
      )}
    </form>
  )
}
