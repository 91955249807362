export type TStudyRequestCommentType = {
  id: number
  label: string
}

export const DEVELOPER_COMMENT: TStudyRequestCommentType = {
  id: 1,
  label: "developer",
}
export const VALIDATION_COMMENT: TStudyRequestCommentType = {
  id: 2,
  label: "validation",
}
export const ASSIGNMENT_COMMENT: TStudyRequestCommentType = {
  id: 3,
  label: "assignment",
}
export const STAND_BY_PRIORIZATION_COMMENT: TStudyRequestCommentType = {
  id: 4,
  label: "standby-priorization",
}
export const STAND_BY_RESTITUTION_COMMENT: TStudyRequestCommentType = {
  id: 5,
  label: "standby-restitution",
}

export const RESTITUTION_COMMENT = {
  id: 6,
  label: "restitutionComment",
}
