import classNames from "classnames"
import Box from "components/Box/Box"
import DateFormat from "components/Date/DateFormat"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useListCprs } from "core/query-hooks/useCprs"
import { t } from "i18next"
import { Fragment } from "react"
import TooltipWarning from "components/Tooltip/TooltipWaring"
import { FaFileContract } from "react-icons/fa"
import AddCprModal from "./AddCprModal"
import EditCprModal from "./EditCprModal"

export interface IPRCProps {
  childOperationId: number
}

export function PreReservationContract(props: IPRCProps) {
  const { childOperationId } = props
  const { data: cprs } = useListCprs(childOperationId)

  return (
    <Box classNames="p-0 mb-3">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between">
        <div className="flex items-center mr-1">
          <div className="flex items-center text-cyan-900">
            <FaFileContract size={22} />
            <h3 className="text-xs uppercase font-medium pl-1">{t("prc")}</h3>
          </div>
          <TooltipWarning message={`${t("cpr-warning")}`} position="center" />
        </div>
        {cprs && cprs.length <= 4 && (
          <AddCprModal childOperationId={childOperationId} />
        )}
      </div>

      {cprs && cprs.length > 0 ? (
        cprs.map((cpr, index) => (
          <Fragment key={cpr.id}>
            <ul
              className={classNames("pt-2 px-4 py-5", {
                "pb-10": index === cprs.length - 1,
              })}
            >
              <li className="flex items-center justify-between pb-2 text-primary-dark">
                <span>{cpr.clientName}</span>
                <PermissionsGuard requiredRoles={["update:cpr"]}>
                  <EditCprModal cpr={cpr} />
                </PermissionsGuard>
              </li>
              <li className="flex items-center justify-between pb-2">
                <p className="text-body text-cyan-950 mr-2">
                  {t("prc-signature")}
                </p>
                <span>
                  {cpr?.cprSignature ? (
                    <DateFormat
                      isDefinitive={cpr.isCprSignatureDefinitive}
                      date={new Date(cpr?.cprSignature)}
                    />
                  ) : (
                    <div className="pr-4">-</div>
                  )}
                </span>
              </li>
              <li className="flex items-center justify-between pb-2 ">
                <p className="text-body text-cyan-950 mr-2">{t("due-date")}</p>
                <span>
                  {cpr?.cprDueDate ? (
                    <DateFormat
                      isDefinitive={cpr.isCprDueDateDefinitive}
                      date={new Date(cpr?.cprDueDate)}
                    />
                  ) : (
                    <div className="pr-4">-</div>
                  )}
                </span>
              </li>
            </ul>
          </Fragment>
        ))
      ) : (
        <p className="p-2 mt-1.5 mb-28 text-sm Color__Primary">
          {t("no-cr-found")}
        </p>
      )}
    </Box>
  )
}
