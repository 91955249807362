import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createChildOperation,
  getChildOperation,
  getOperationById,
  getOperationStepHistory,
  listChildOperations,
  listChildRecords,
  listCommunicationTypes,
  listOperations,
  listOperationsCommitteesNextThreeMonths,
  listOperationsDataNextThreeMonths,
  listOperationsDpcNextThreeMonths,
  listOperationsIds,
  listSuspensiveConditionsByOperationId,
  registerProject,
  sendCertificateDepositMail,
  sendSupportingDocumentMail,
  updateChildOperation,
  updateOperation,
  updateOperationStep,
} from "core/api/operations"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TChildOperation,
  TCommunicationType,
  TCreateChildOperation,
  TOperation,
  TOperationChildRecords,
  TOperationDataMutate,
  TOperationIds,
  TOperationStep,
  TOperationStepHistory,
  TOperationsList,
  TRegisterProject,
  TSendCertificateDepositMailParams,
  TSendSupportingDocumentMailParams,
  TUpdateChildOperation,
} from "shared/types/operation.type"
import { TOperationsNextThreeMonthsList } from "shared/types/spo/next-three-months-infos"
import { TSuspensiveCondition } from "shared/types/suspensiveCondition.type"

function useRegisterProject(projectId: number) {
  return useMutation<TChildOperation, CustomAxiosError, TRegisterProject>({
    mutationFn: (data: TRegisterProject) => registerProject(projectId, data),
  })
}

function useListOperations() {
  return useQuery<TOperationsList, CustomAxiosError>({
    queryKey: ["listOperations"],
    queryFn: () => listOperations(),
  })
}

function useListOperationsWithParams(params?: URLSearchParams) {
  return useQuery<TOperationsList, CustomAxiosError>({
    queryKey: ["listOperationsWithParams"],
    queryFn: () => listOperations(params),
    ...{
      retry: false,
    },
  })
}

function useListOperationsIds(params?: URLSearchParams) {
  return useQuery<TOperationIds, CustomAxiosError>({
    queryKey: ["listOperationsIds"],
    queryFn: () => listOperationsIds(params),
    ...{
      retry: false,
    },
  })
}

function useListChildRecords(operationId?: string) {
  return useQuery<TOperationChildRecords, CustomAxiosError>({
    queryKey: ["listChildRecords", operationId],
    queryFn: () => listChildRecords(operationId),
    ...{ enabled: Boolean(operationId), suspense: true },
  })
}

function useGetOperationById(id: number) {
  return useQuery<TOperation, CustomAxiosError>({
    queryKey: ["operation", id],
    queryFn: () => getOperationById(id),
  })
}

function useCreateChildOperation() {
  return useMutation<TOperation, CustomAxiosError, TCreateChildOperation>({
    mutationFn: (childOperation) => createChildOperation(childOperation),
  })
}

function useUpdateChildOperation(id: number) {
  return useMutation<
    TChildOperation,
    CustomAxiosError,
    Partial<TUpdateChildOperation>
  >({
    mutationFn: (childOperation) => updateChildOperation(id, childOperation),
  })
}

function useListChildOperations(operationId: number) {
  return useQuery<TChildOperation[], CustomAxiosError>({
    queryKey: ["listChildOperations", operationId],
    queryFn: () => listChildOperations(operationId),
    ...{ enabled: Boolean(operationId) },
  })
}

function useListSuspensiveConditionsByOperationId(operationId: string) {
  return useQuery<TSuspensiveCondition[], CustomAxiosError>({
    queryKey: ["listSuspensiveConditionsByOperationId", operationId],
    queryFn: () => listSuspensiveConditionsByOperationId(operationId),
    ...{ suspense: true },
  })
}

function useUpdateOperation(operationId: number) {
  return useMutation<
    TOperation,
    CustomAxiosError,
    Partial<TOperationDataMutate>
  >({
    mutationFn: (data) => updateOperation(data, operationId),
  })
}

function useUpdateOperationStep(operationId: number) {
  return useMutation<TOperation, CustomAxiosError, TOperationStep>({
    mutationFn: (data) => updateOperationStep(data, operationId),
  })
}

function useListCommunicationTypes() {
  return useQuery<TCommunicationType[], CustomAxiosError>({
    queryKey: ["listCommunicationTypes"],
    queryFn: () => listCommunicationTypes(),
  })
}

function useGetOperationStepHistory(operationId: number) {
  return useQuery<TOperationStepHistory[], CustomAxiosError>({
    queryKey: ["getOperationStepHistory", operationId],
    queryFn: () => getOperationStepHistory(operationId),
  })
}

function useListOperationsDataNextThreeMonths(businessUnitId?: number) {
  return useQuery<TOperationsNextThreeMonthsList, CustomAxiosError>({
    queryKey: ["listOperationsDataNextThreeMonths", businessUnitId],
    queryFn: () => listOperationsDataNextThreeMonths(businessUnitId),
  })
}

function useListOperationsCommitteesNextThreeMonths(businessUnitId?: number) {
  return useQuery<TOperationsNextThreeMonthsList, CustomAxiosError>({
    queryKey: ["listOperationsCommitteesNextThreeMonths", businessUnitId],
    queryFn: () => listOperationsCommitteesNextThreeMonths(businessUnitId),
  })
}

function useListOperationsDcNextThreeMonths(
  businessUnitId?: number,
  ltDate?: Date,
) {
  return useQuery<TOperationsNextThreeMonthsList, CustomAxiosError>({
    queryKey: ["listOperationsDpcNextThreeMonths", businessUnitId],
    queryFn: () => listOperationsDpcNextThreeMonths(businessUnitId, ltDate),
  })
}

function useGetChildOperation(childOperationId: number) {
  return useQuery<TChildOperation, CustomAxiosError>({
    queryKey: ["childOperation", childOperationId],
    queryFn: () => getChildOperation(childOperationId),
  })
}

function useSendCertificateDepositMail() {
  return useMutation<void, CustomAxiosError, TSendCertificateDepositMailParams>(
    {
      mutationFn: ({
        operationId,
        termLabel,
        lessorLabel,
        isSupportingDocument,
      }: TSendCertificateDepositMailParams) =>
        sendCertificateDepositMail(
          operationId,
          termLabel,
          lessorLabel,
          isSupportingDocument,
        ),
    },
  )
}

function useSendSupportingDocumentMail() {
  return useMutation<void, CustomAxiosError, TSendSupportingDocumentMailParams>(
    {
      mutationFn: ({
        operationId,
        termLabel,
        trancheTravauxLabel,
      }: TSendSupportingDocumentMailParams) =>
        sendSupportingDocumentMail(operationId, termLabel, trancheTravauxLabel),
    },
  )
}

export {
  useCreateChildOperation,
  useGetChildOperation,
  useGetOperationById,
  useGetOperationStepHistory,
  useListChildOperations,
  useListChildRecords,
  useListCommunicationTypes,
  useListOperations,
  useListOperationsCommitteesNextThreeMonths,
  useListOperationsDataNextThreeMonths,
  useListOperationsDcNextThreeMonths,
  useListOperationsIds,
  useListOperationsWithParams,
  useListSuspensiveConditionsByOperationId,
  useRegisterProject,
  useSendCertificateDepositMail,
  useUpdateChildOperation,
  useUpdateOperation,
  useUpdateOperationStep,
  useSendSupportingDocumentMail,
}
