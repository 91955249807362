import { NodeModel, Tree } from "@minoru/react-dnd-treeview"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import classNames from "classnames"
import { Button } from "components"
import DisplayLoading from "components/Display/DisplayLoading"
import useModal from "components/Modal/useModal"
import { SearchInput } from "components/SearchInput/SearchInput"
import { AuthContext } from "core/auth/AuthProvider"
import getSubfolders from "core/ged/api/metadata"
import { usePostDocumentToGed } from "core/ged/query-hooks/useGed"
import { useGetUserByAzureId } from "core/query-hooks/useUsers"
import { useSendFolderToGed } from "core/sharepoint/query-hooks/useFolders"
import { t } from "i18next"
import { useCallback, useContext, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { ImFolder, ImFolderOpen } from "react-icons/im"
import { toast } from "react-toastify"
import {
  SUBFOLDER1,
  SUBFOLDER2,
  SUBFOLDER3,
} from "shared/resources/subfolder.resources"
import { TDataFormatedTree, TFormatedTree } from "shared/types/ged.type"
import { useDebouncedCallback } from "use-debounce"
import AddDocumentModal from "./AddDocumentsModal"
import { MAX_SIZED_BYTES, UNAUTHORIZED_EXTENSIONS } from "./ged.resources"
import GedLink from "./GedLink"
import useTree from "./useTree"

interface GedExplorerProps {
  registrationNumbers: string[]
  view: number
  isMother: boolean
  numStatus?: string
}

export default function GedExplorer(props: GedExplorerProps) {
  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()
  const { data: user } = useGetUserByAzureId(userInfo.azureId)
  const { registrationNumbers, view, isMother, numStatus } = props
  const { tree, handleDrop, setSearchText, isLoading, isError, setTree } =
    useTree(undefined, registrationNumbers, view)
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [expand, setExpand] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const uploadDocumentToGed = usePostDocumentToGed()
  const sendSharepointFolderToGed = useSendFolderToGed()

  const buildLeaf = async (node: NodeModel): Promise<TFormatedTree[]> => {
    const newTree = [...tree]
    const data = node.data as TDataFormatedTree
    let subfolderLabel = SUBFOLDER1
    if (data.subfolder1) subfolderLabel = SUBFOLDER2
    if (data.subfolder2) subfolderLabel = SUBFOLDER3

    // On a que 3 sous-niveau possibles
    if (!data.subfolder3) {
      let subfolders: string[] = []
      if (registrationNumbers.length > 1) {
        await Promise.all(
          registrationNumbers.map(async (regNumber) => {
            const { subfolders: sf } = await getSubfolders(
              subfolderLabel,
              data.label!,
              [regNumber],
            )
            subfolders.push(...sf)
          }),
        )
      } else {
        const { subfolders: sf } = await getSubfolders(
          subfolderLabel,
          data.label!,
          registrationNumbers,
        )
        subfolders = [...sf]
      }

      if (subfolders && subfolders.length > 0) {
        const index = newTree.findIndex((leaf) => leaf.id === node.id)
        for (let i = 0; i < subfolders.length; i += 1) {
          const nodeId = data.subfolder1
            ? `${node.id}-${subfolders[i]}`
            : subfolders[i]

          const leafId = !data.latestNode ? `${data.label}.` : ""
          const count = newTree.filter(
            (item) =>
              item.data?.isFile === true &&
              item.parent.toString().startsWith(`${leafId}${nodeId}`),
          )
          if (
            newTree.findIndex((leaf) => leaf.id === `${leafId}${nodeId}`) === -1
          ) {
            newTree.splice(index + i, 0, {
              id: `${leafId}${nodeId}`,
              parent: node.id,
              text: `${subfolders[i]} (${count.length})`,
              droppable: true,
              data: {
                id: data.id,
                subfolder1: data.subfolder1 ?? subfolders[i],
                subfolder2:
                  data.subfolder2 ??
                  (data.subfolder1 ? subfolders[i] : undefined),
                subfolder3:
                  data.subfolder3 ??
                  (data.subfolder2 ? subfolders[i] : undefined),

                latestNode: data.subfolder1 ? data.latestNode : data.label,
                isDocumentType: true,
                label: subfolders[i],
                type: data.type,
                lastModifiedDate: data.lastModifiedDate,
              },
            })
          }
        }
      }
    }

    return newTree
  }

  useEffect(() => {
    isModalShowed && (document.body.style.overflow = "hidden")
    !isModalShowed && (document.body.style.overflow = "unset")
  }, [isModalShowed])

  const handleSharepointFolderDrop = async (
    itemId: string,
    itemType: string,
    data: TDataFormatedTree,
  ) => {
    try {
      if (data.label) {
        const promise = sendSharepointFolderToGed.mutateAsync({
          // si pas de latestNode on est dans un type de document sinon on est dans un sous dossier
          documentType: data.latestNode ? data.latestNode : data.label,
          folderId: itemType === "folder" ? itemId : undefined,
          fileId: itemType === "file" ? itemId : undefined,
          registrationNumber: registrationNumbers[0],
          subfolder1: data.subfolder1 ?? undefined,
          subfolder2: data.subfolder2 ?? undefined,
          subfolder3: data.subfolder3 ?? undefined,
        })

        toast.promise(promise, {
          pending: `${t(`${itemType}-transfer-loading`)}`,
          success: {
            render: () => {
              queryClient.invalidateQueries({
                queryKey: ["getTreeByRegistrationNumbers", registrationNumbers],
              })
              queryClient.invalidateQueries({
                queryKey: ["countFilesInFolder"],
              })
              queryClient.invalidateQueries({
                queryKey: ["listFoldersFromParent"],
              })
              return `${t(`${itemType}-transfer-success`)}`
            },
          },
        })

        await promise
      } else {
        toast.error(`${t("error-sharepoint-folder-transfer-no-document-type")}`)
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        toast.error(`${t(e.response.data.message)}`)
      } else {
        toast.error(`${t("error-unknown")}`)
      }
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2 gap-x-3">
        <div className="inline-flex gap-x-4">
          <div>
            <SearchInput
              searchLabel="Rechercher dans la GED"
              className="mb-3"
              handleChange={useDebouncedCallback(
                useCallback((value: string) => {
                  setExpand(false)
                  if (value) {
                    setExpand(true)
                  }
                  return setSearchText(value)
                }, []),
                500,
              )}
            />
          </div>
          <div>
            {tree.length > 0 && (
              <Button
                classNames="ml-4 w-max"
                onClick={() => setExpand(!expand)}
                size="medium"
                mode="secondary"
              >
                <span>
                  {expand ? `${t("Tout replier")}` : `${t("Tout déplier")}`}
                </span>
              </Button>
            )}
          </div>
          <div>
            {tree && tree.length > 0 && (
              <Button
                classNames="flex-shrink-0"
                onClick={toggleModal}
                size="medium"
                mode="primary"
              >
                {`${t("add")}`}
              </Button>
            )}
          </div>
        </div>

        {isModalShowed && (
          <AddDocumentModal
            view={view}
            isModalShowed={isModalShowed}
            toggleModal={toggleModal}
            registrationNumbers={registrationNumbers}
            numStatus={numStatus}
          />
        )}
      </div>

      <div className="mt-3">
        {isLoading && !isError && <DisplayLoading isLoading={isLoading} />}

        {isError && !isLoading && (
          <p className="text-red-600 text-sm">{`${t(
            "ged-errorLoadMessage",
          )}`}</p>
        )}

        {!isError && !isLoading && (
          <>
            {tree.length > 0 ? (
              <Tree
                tree={tree}
                rootId={0}
                initialOpen={expand}
                canDrag={() => false}
                onDrop={handleDrop}
                render={(node, { depth, isOpen, onToggle }) => {
                  const { getRootProps, getInputProps, isDragActive } =
                    useDropzone({
                      onDrop: async (acceptedFile) => {
                        if (isMother) {
                          toast.warning(t("toast-drop-only-on-children"))
                        } else {
                          let invalidationNeeded = false
                          await Promise.all(
                            acceptedFile.map(async (fileToUpload) => {
                              if (
                                UNAUTHORIZED_EXTENSIONS.includes(
                                  fileToUpload.name.split(".").pop() ?? "",
                                )
                              ) {
                                return toast.error(
                                  `${t("unauthorized-file-extension")} : ${fileToUpload.name}`,
                                )
                              }
                              if (fileToUpload.size < MAX_SIZED_BYTES) {
                                if (node.data && node.data.label) {
                                  const formValues = {
                                    file: fileToUpload,
                                    documentType:
                                      node.data?.latestNode ?? node.data.label,
                                    documentName: fileToUpload.name.replace(
                                      /\.[^/.]+$/,
                                      "",
                                    ),
                                    registrationNumber: registrationNumbers[0],
                                    subfolder1: node.data?.subfolder1,
                                    subfolder2: node.data?.subfolder2,
                                    subfolder3: node.data?.subfolder3,
                                    numStatus: "2",
                                    storedBy: user?.docuwareName,
                                  }

                                  const isUploadSuccess =
                                    await uploadDocumentToGed.mutateAsync(
                                      formValues,
                                    )
                                  if (isUploadSuccess) {
                                    invalidationNeeded = true
                                    toast.success(
                                      `${t("the-document")} ${
                                        fileToUpload.name
                                      } ${t("has-been-added-to-ged")}`,
                                    )
                                  }
                                }
                                return fileToUpload
                              }
                              return toast.error(
                                `${t("file-too-big-error")} : ${fileToUpload.name}`,
                              )
                            }),
                          )

                          if (invalidationNeeded) {
                            queryClient.invalidateQueries({
                              queryKey: [
                                "getTreeByRegistrationNumbers",
                                registrationNumbers,
                              ],
                            })
                          }
                        }
                      },
                    })

                  const hasDragActive = isDragActive && !isMother
                  if (node.droppable || node.data?.isDocumentType) {
                    return (
                      <div
                        className={classNames(
                          "flex items-start Color__Primary cursor-pointer text-sm py-2",
                        )}
                        style={{
                          paddingLeft: depth * 24,
                          transition: "background-color 0.5s ease-in-out",
                          backgroundColor:
                            hasDragActive && node.data?.isDocumentType
                              ? "#B0C02A"
                              : `rgba(173, 216, 230, ${1 - depth * 0.3})`,
                        }}
                        {...getRootProps({
                          onClick: (event) => {
                            event.stopPropagation()
                            node.droppable ? onToggle() : null
                            if (node.data?.isDocumentType) {
                              buildLeaf(node).then((newTree) =>
                                setTree(newTree),
                              )
                            }
                          },
                        })}
                      >
                        <input {...getInputProps()} />
                        <div
                          className="flex items-center"
                          onDragOver={(e) => e.preventDefault()} // Autorise le drop pour les items Sharepoint
                          onDrop={(e) => {
                            e.preventDefault()
                            const data = e.dataTransfer.getData("text/plain")
                            if (data) {
                              const draggedItem = JSON.parse(data)
                              const { id: itemId, type: itemType } = draggedItem

                              if (itemId && node.data) {
                                handleSharepointFolderDrop(
                                  itemId,
                                  itemType,
                                  node.data,
                                )
                              }
                            }
                          }}
                        >
                          <span className="pt-0.5 mr-1.5 pl-2">
                            {isOpen ? <ImFolderOpen /> : <ImFolder />}
                          </span>
                          <p>{node.text}</p>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div
                      className={classNames(
                        "flex items-start Color__Primary cursor-pointer text-sm mb-1",
                      )}
                      style={{ paddingLeft: depth * 24 }}
                    >
                      {node.data?.isFile && node.data?.id ? (
                        <GedLink
                          title={node.text}
                          id={node.data.id!}
                          type={node.data.type}
                          lastModifiedDate={node.data.lastModifiedDate}
                        />
                      ) : (
                        <p>{node.text}</p>
                      )}
                    </div>
                  )
                }}
              />
            ) : (
              <p className="text-sm Color__Primary text-center">
                {`${t("ged-noDocumentsFound")}`}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}
