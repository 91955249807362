import { useQueryClient } from "@tanstack/react-query"
import { useGetMultiplesDocuments } from "core/ged/query-hooks/useGed"
import {
  useGetSupportingDocumentExplanation,
  useUpdateAppelDefondDocuments,
} from "core/spo/query-hooks/useAppelDeFond"
import AddProductionDocumentsModal from "features/ged/AddProductionDocumentsModal"
import { t } from "i18next"
import { uniq } from "lodash"
import { useEffect, useState } from "react"
import { IoDocumentText } from "react-icons/io5"
import { toast } from "react-toastify"
import {
  MAX_GLOBAL_SUPPORTING_DOCUMENTS,
  SUPPORTING_DOCUMENTS_DOCUMENT_TYPE,
} from "shared/resources/fund-raising-appeal.resources"
import { TGedUploadResponse } from "shared/types/ged.type"
import { TLessor } from "shared/types/spo/tranche.type"

export interface ISupportingDocumentsExplanation {
  toggle: () => void
  isShowing: boolean
  registrationNumber: string
  trancheTravaux: string
  lessor: TLessor | null
}

export function SupportingDocumentsExplanation(
  props: ISupportingDocumentsExplanation,
) {
  const { toggle, isShowing, registrationNumber, trancheTravaux, lessor } =
    props
  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])
  const [supportingDocumentExplanation, setSupportingDocumentExplanation] =
    useState<number[]>([])
  const queryClient = useQueryClient()
  const mutation = useUpdateAppelDefondDocuments(
    `${trancheTravaux}${lessor ? `-${lessor.code}` : ""}`,
  )
  const { data } = useGetSupportingDocumentExplanation(
    trancheTravaux,
    lessor?.code,
  )

  useEffect(() => {
    setSupportingDocumentExplanation(data?.shift()?.supportingDocuments ?? [])
  }, [data])

  const { data: currentDocuments, isError } = useGetMultiplesDocuments(
    supportingDocumentExplanation,
  )

  const updateDocuments = async (
    docsIds?: number[],
    isDelete?: boolean,
  ): Promise<void> => {
    mutation.mutateAsync(
      {
        supportingDocuments: docsIds,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [
              "getSupportingDocumentExplanation",
              trancheTravaux,
              lessor?.code,
            ],
          })
          if (isDelete) toast.success(`${t("documents-updated")}`)
        },
      },
    )
  }

  const handleDeleteDocument = async (documentId: number) => {
    const updatedDocs =
      uniq(
        supportingDocumentExplanation.filter((docId) => docId !== documentId),
      ) ?? []

    setFormFiles(formFiles.filter((doc) => doc.id !== documentId))

    await updateDocuments(updatedDocs, true)
  }

  useEffect(() => {
    if (formFiles.length > 0) {
      const supportingDocumentsExplanation = uniq([
        ...supportingDocumentExplanation,
        ...formFiles.map((doc) => doc.id),
      ])
      const updateDocs = async () => {
        await updateDocuments(supportingDocumentsExplanation)
      }

      updateDocs()
    }
  }, [formFiles])

  if (isError) {
    return (
      <div className="flex items-center justify-center h-48">
        <p>{t("an-error-has-occurred")}</p>
      </div>
    )
  }

  return (
    <>
      <IoDocumentText
        size={25}
        color="#0B6CFF"
        role="button"
        onClick={() => toggle()}
      />

      <AddProductionDocumentsModal
        titleModal={t("production-supporting-documents")}
        documentTypes={[SUPPORTING_DOCUMENTS_DOCUMENT_TYPE.label]}
        filesToDisplay={currentDocuments}
        handleDeleteDocument={handleDeleteDocument}
        isModalShowed={isShowing}
        toggleModal={toggle}
        setFiles={setFormFiles}
        files={formFiles}
        registrationNumber={registrationNumber}
        maxFiles={MAX_GLOBAL_SUPPORTING_DOCUMENTS}
      />
    </>
  )
}
