import { API } from "core/api/axios"
import {
  TCreateEnvironmentBuilding,
  TEnvironmentBuilding,
  TUpdateEnvironmentBuilding,
} from "shared/types/environmentBuilding.type"

async function listEnvironmentBuildings(
  projectId: number,
): Promise<TEnvironmentBuilding[]> {
  return typeof projectId === "undefined"
    ? Promise.reject(new Error("Invalid project id"))
    : API.get(`/environment-buildings?projectId=${projectId}`).then(
        (response) => response.data,
      )
}

async function createEnvironmentBuilding(
  environmentBuilding: TCreateEnvironmentBuilding,
): Promise<TEnvironmentBuilding> {
  return API.post(`/environment-building`, environmentBuilding).then(
    (response) => response.data,
  )
}

async function updateEnvironmentBuilding(
  id: number,
  environment: TUpdateEnvironmentBuilding,
): Promise<TEnvironmentBuilding> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid environment building id"))
    : API.patch(`/environment-building/${id}`, environment).then(
        (response) => response.data,
      )
}

async function deleteEnvironmentBuilding(
  id: number,
): Promise<TEnvironmentBuilding> {
  const res = await API.delete(`/environment-building/${id}`)
  return res.data
}

export {
  createEnvironmentBuilding,
  deleteEnvironmentBuilding,
  listEnvironmentBuildings,
  updateEnvironmentBuilding,
}
