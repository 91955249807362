import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Avatar from "components/Avatar/Avatar"
import GedFile from "components/Ged/GedFile"
import { Modal } from "components/Modal/Modal"
import StandByHistory from "components/StandByHistory/StandByHistory"
import { useAddCostOfWorkDocuments } from "core/query-hooks/useCostOfWork"
import useDeleteDocument from "core/query-hooks/useDocuments"
import {
  useAddCommentToStudyRequest,
  useUpdateStatus,
  useUpdateStudyRequest,
} from "core/query-hooks/useStudyRequests"
import { useGetUsersByOccupationId } from "core/query-hooks/useUsers"
import { AddFile } from "features/studies/AddFile"
import {
  ECONOMY_RESTITUTION_GED_CAT,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  TECHNICAL_MANAGER_OCCUPATION_ID,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { sortByDate } from "pages/dashboards/study-requests/dashboard-util"
import { useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { RiDeleteBin2Fill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import Select, {
  OptionProps,
  SingleValue,
  SingleValueProps,
  components,
} from "react-select"
import { toast } from "react-toastify"
import {
  RESTITUTION_COMMENT,
  STAND_BY_RESTITUTION_COMMENT,
} from "shared/resources/study-request.resources"
import { TGedUploadResponse } from "shared/types/ged.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"
import { TUser } from "shared/types/user.type"
import * as yup from "yup"

export type TUserSelect = TUser & {
  label: string
  value: string
}

function Option(props: OptionProps<TUserSelect>) {
  const {
    data,
    children,
    className = "px-2 Border__Bottom_LightGrey py-2 AddContributor__Option cursor-pointer",
    cx,
    innerRef,
    innerProps,
  } = props

  return (
    <div
      ref={innerRef}
      className={cx(
        {
          option: true,
        },
        className,
      )}
      {...innerProps}
    >
      <div className="flex items-center">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data} />
        </div>
        {children}
      </div>
    </div>
  )
}

function SingleValueLabel(props: SingleValueProps<TUserSelect>) {
  const { children, data } = props
  return (
    <components.SingleValue {...props}>
      <div className="flex items-center">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data} />
        </div>
        {children}
      </div>
    </components.SingleValue>
  )
}

const CostOfWorkRestitutionSchema = yup.object({
  technicalManagerId: yup.string(),
  creationNumberOfHours: yup
    .number()
    .positive(() => `${t("restitution-hours-positive-validation")}`)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  technicalManagerNumberOfHours: yup
    .number()
    .positive(() => `${t("restitution-hours-positive-validation")}`)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  mailCreationNumberOfHours: yup
    .number()
    .required(
      () =>
        `${t("cost-of-work-restitution-technical-manager-mail-creation-number-of-hours-validation")}`,
    )
    .positive(() => `${t("restitution-hours-positive-validation")}`)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  restitutionComment: yup.string().nullable(),
  files: yup
    .array()
    .of(yup.number())
    .min(1, () => `${t("error-at-least-one-file")}`)
    .required(() => `${t("error-at-least-one-file")}`),
})

type CoWFormValues = {
  technicalManagerNumberOfHours: number
  mailCreationNumberOfHours: number
  creationNumberOfHours: number
  restitutionComment: string
  files: number[]
  technicalManagerId: string
}

export function CostOfWorkRestitutionForm(props: {
  studyRequestType: number
  requestId: number
  studyRequest: TStudyRequest
  studyRequestHistory: TStudyRequestHistory[]
  categoryId: number
}) {
  const { requestId, studyRequest, studyRequestHistory, categoryId } = props
  const { studyRequestId, id } = useParams()
  const updateStudyRequest = useUpdateStudyRequest(requestId)
  const updateStudyRequestStatus = useUpdateStatus(requestId)
  const addDocuments = useAddCostOfWorkDocuments(+studyRequestId!)
  const deleteDocument = useDeleteDocument()
  const addCommentToStudyRequest = useAddCommentToStudyRequest(requestId!)
  const queryClient = useQueryClient()
  const methods = useForm<CoWFormValues>({
    resolver: yupResolver(
      CostOfWorkRestitutionSchema as yup.ObjectSchema<CoWFormValues>,
    ),
  })
  const standbyRestitutionComment = studyRequest?.comments
    ?.filter((c) => c.type.id === STAND_BY_RESTITUTION_COMMENT.id)
    .sort((a, b) => sortByDate(a.creationDate, b.creationDate))[0]?.comment
  const [showModal, setShowModal] = useState<boolean>(false)
  const [standbyComment, setStandbyComment] = useState<string | undefined>(
    standbyRestitutionComment ?? undefined,
  )
  const [onStandby, setOnStandby] = useState<boolean>(
    studyRequest.statusId === STATUS_RESTITUTION_STANDBY,
  )

  const invalidateQueriesData = () => {
    queryClient.invalidateQueries({ queryKey: ["getFeasibility", requestId] })
    queryClient.invalidateQueries({ queryKey: ["getCostOfWork", requestId] })
    queryClient.invalidateQueries({ queryKey: ["getInfography", requestId] })
    queryClient.invalidateQueries({ queryKey: ["listStudyRequests", id] })
    queryClient.invalidateQueries({
      queryKey: ["getDocumentsByCategoryAndProjectId", id, categoryId],
    })
  }

  const invalidatesQueriesHistory = () => {
    queryClient.invalidateQueries({
      queryKey: ["getFeasibilityHistory", requestId],
    })
    queryClient.invalidateQueries({
      queryKey: ["getCostOfWorkHistory", requestId],
    })
    queryClient.invalidateQueries({
      queryKey: ["getInfographyHistory", requestId],
    })
  }

  const startingStandbyHistory = studyRequestHistory?.find(
    (srh) => srh.statusId === STATUS_RESTITUTION_STANDBY,
  )

  const endingStandbyHistory = studyRequestHistory?.filter(
    (srh) => srh.statusId === STATUS_PRIORIZED_AND_ASSIGNED,
  )

  const { data: attributableUsers } = useGetUsersByOccupationId(
    TECHNICAL_MANAGER_OCCUPATION_ID,
  )

  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])

  useEffect(() => {
    return methods.setValue(
      "files",
      formFiles?.map((file) => file.id),
    )
  }, [formFiles])

  const formattedAttributableUsers = attributableUsers?.map((user) => ({
    ...user,
    label: `${user.firstName} ${user.lastName}`,
    value: user.azureId,
  }))

  const onSubmit = (data: CoWFormValues) => {
    updateStudyRequest.mutate(
      {
        mailCreationNumberOfHours: data.mailCreationNumberOfHours,
        technicalManagerNumberOfHours: data.technicalManagerNumberOfHours,
        creationNumberOfHours: data.creationNumberOfHours,
        technicalManagerId: data.technicalManagerId,
      },
      {
        onSuccess: () => {
          addDocuments.mutate(
            {
              categories: [
                {
                  categoryId: ECONOMY_RESTITUTION_GED_CAT.id,
                  gedDocuments: data.files,
                },
              ],
            },
            {
              onSuccess() {
                if (data.restitutionComment) {
                  addCommentToStudyRequest.mutate(
                    {
                      typeId: RESTITUTION_COMMENT.id,
                      comment: data.restitutionComment,
                    },
                    {
                      onSuccess() {
                        updateStudyRequestStatus.mutate(
                          {
                            statusId: STATUS_RETURNED,
                          },
                          {
                            onSuccess: () => {
                              toast.success(
                                `${t("studyRequest-toast-restitutionSuccess")}`,
                              )
                              invalidateQueriesData()
                              invalidatesQueriesHistory()
                            },
                            onError: (err) =>
                              toast.error(
                                `${t("studyRequest-toast-restitutionError")} ${
                                  err.response?.data.message
                                }`,
                              ),
                          },
                        )
                      },
                      onError(error) {
                        toast.error(error.response?.data.error.message)
                      },
                    },
                  )
                } else {
                  updateStudyRequestStatus.mutate(
                    {
                      statusId: STATUS_RETURNED,
                    },
                    {
                      onSuccess: () => {
                        toast.success(
                          `${t("studyRequest-toast-restitutionSuccess")}`,
                        )
                        invalidateQueriesData()
                        invalidatesQueriesHistory()
                      },
                      onError: (err) =>
                        toast.error(
                          `${t("studyRequest-toast-restitutionError")} ${
                            err.response?.data.message
                          }`,
                        ),
                    },
                  )
                }
              },
              onError: (err) =>
                toast.error(
                  `${t("studyRequest-toast-restitutionError")} ${
                    err.response?.data.message
                  }`,
                ),
            },
          )
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest-toast-restitutionError")} ${
              err.response?.data.message
            }`,
          ),
      },
    )
  }

  function onChange(newValue: SingleValue<TUserSelect>) {
    return methods.setValue("technicalManagerId", newValue?.azureId!)
  }

  function handleDelete(docId: number) {
    deleteDocument.mutateAsync(docId, {
      onSuccess() {
        setFormFiles((prevFiles) => {
          return prevFiles.filter((file) => file.id !== docId)
        })
        toast.success(t("file-deleted"))

        return methods.setValue(
          "files",
          formFiles.filter((file) => file.id !== docId).map((file) => file.id),
        )
      },
      onError() {
        toast.error(t("file-deleted-error"))
      },
    })
  }

  const uploadedFiles = formFiles.map((file) => (
    <li className="flex items-center justify-start" key={file.title}>
      <GedFile stringLength={17} id={file.id} title={file.title} />
      <button
        className="opacity-50 hover:opacity-75 transition-opacity ease-in-out delay-75"
        type="button"
      >
        <RiDeleteBin2Fill
          className="ml-1"
          onClick={() => handleDelete(file.id)}
        />
      </button>
    </li>
  ))

  const updateStatus = (
    statusId: number,
    successMessage: string,
    errorMessage: string,
    standby: boolean,
  ) => {
    updateStudyRequestStatus.mutate(
      {
        statusId,
      },
      {
        onSuccess: () => {
          if (standby && standbyComment) {
            addCommentToStudyRequest.mutate(
              {
                typeId: STAND_BY_RESTITUTION_COMMENT.id,
                comment: standbyComment,
              },
              {
                onSuccess() {
                  invalidateQueriesData()
                },
                onError(error) {
                  toast.error(error.response?.data.error.message)
                },
              },
            )
          }
          setShowModal(false)
          toast.success(successMessage)
          invalidateQueriesData()
          invalidatesQueriesHistory()
          setOnStandby(standby)
        },
        onError: (err) => {
          const errMessage = err.response?.data.message
          if (errMessage?.startsWith("Documents missing")) {
            const missingDocs = errMessage?.split(": ")?.pop()?.split(", ")
            toast.error(
              <>
                <p>{`${t("label-mandatory-documents-list")}`}</p>
                <ul>
                  {missingDocs?.map((doc) => (
                    <li key={nanoid()}>
                      <p>- {doc}</p>
                    </li>
                  ))}
                </ul>
              </>,
            )
          } else {
            toast.error(`${errorMessage} ${err.response?.data.message}`)
          }
        },
      },
    )
  }

  const onChangeStandby = (changedStudyRequest: TStudyRequest) => {
    if (changedStudyRequest.statusId === STATUS_PRIORIZED_AND_ASSIGNED) {
      updateStatus(
        STATUS_RESTITUTION_STANDBY,
        `${t("studyRequest-toast-addStandbySuccess")}`,
        `${t("studyRequest-toast-addStandbyError")}`,
        true,
      )
    }

    if (changedStudyRequest.statusId === STATUS_RESTITUTION_STANDBY) {
      updateStatus(
        STATUS_PRIORIZED_AND_ASSIGNED,
        `${t("studyRequest-toast-removeStandbySuccess")}`,
        `${t("studyRequest-toast-removeStandbyError")}`,
        false,
      )
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        className="Text__Field_Container"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex-1 pb-2 pt-4">
          <Button
            type="button"
            size="small"
            mode="validation"
            onClick={
              onStandby
                ? () => onChangeStandby(studyRequest)
                : () => setShowModal(true)
            }
            classNames={onStandby ? "validation" : "appleGreen"}
            isDisabled={updateStudyRequestStatus.isPending}
          >
            {onStandby
              ? `${t("studyRequest-removeStandby")}`
              : `${t("studyRequest-putOnStandby")}`}
          </Button>
        </div>
        <div className="flex-1 mt-1 grid grid-cols-1 mb-2">
          {startingStandbyHistory ? (
            <StandByHistory
              classNames="text-xs step-more-info"
              label={`${t("studyRequest-startingDate")}`}
              date={startingStandbyHistory.date}
            />
          ) : null}
          {endingStandbyHistory &&
          endingStandbyHistory.length > 1 &&
          !onStandby ? (
            <StandByHistory
              classNames="text-xs step-more-info"
              label={`${t("studyRequest-endingDate")}`}
              date={endingStandbyHistory[0].date}
            />
          ) : null}
        </div>
        <div>
          <label htmlFor="creationNumberOfHours">
            {t("cost-of-work-restitution-followup-form-number-of-hours-label")}
            <input
              id="creationNumberOfHours"
              className="Text__Field"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              placeholder={`${t(
                "restitution-followup-form-number-of-hours-placeholder",
              )}`}
              {...methods.register("creationNumberOfHours")}
              disabled={onStandby}
            />
          </label>
          {methods.formState.errors.creationNumberOfHours && (
            <span className="text-xs text-red-500">
              {methods.formState.errors.creationNumberOfHours.message}
            </span>
          )}
        </div>
        <div>
          <label htmlFor="technicalManagerNumberOfHours">
            {`${t("followup-stepper-technical-manager-number-of-hours")} *`}
            <input
              id="technicalManagerNumberOfHours"
              className="Text__Field"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              placeholder={`${t(
                "cost-of-work-restitution-technical-manager-number-of-hours-placeholder",
              )}`}
              {...methods.register("technicalManagerNumberOfHours")}
              disabled={onStandby}
            />
          </label>
          {methods.formState.errors.technicalManagerNumberOfHours && (
            <span className="text-xs text-red-500">
              {methods.formState.errors.technicalManagerNumberOfHours.message}
            </span>
          )}
        </div>
        <label htmlFor="technicalManagerId">
          {`${t("followup-stepper-technical-manager")}`}
          <Controller
            control={methods.control}
            name="technicalManagerId"
            render={() => (
              <div className="AddPlot__Modal pb-2">
                <Select
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "#F9FAFB",
                      border: "1px solid #F0F0F0",
                    }),
                    indicatorSeparator: (styles) => ({
                      ...styles,
                      backgroundColor: "none",
                    }),
                  }}
                  onChange={(value) => onChange(value)}
                  isMulti={false}
                  placeholder="Sélectionner"
                  options={formattedAttributableUsers}
                  components={{
                    // @ts-ignore We're failing to provide a required index prop to SortableElement
                    SingleValueLabel,
                    Option,
                  }}
                  isDisabled={onStandby}
                />
              </div>
            )}
          />
        </label>

        <div>
          <label htmlFor="mailCreationNumberOfHours">
            {`${t(
              "followup-stepper-technical-mail-creation-number-of-hours",
            )} *`}
            <input
              id="mailCreationNumberOfHours"
              className="Text__Field"
              onWheel={(event) => event.currentTarget.blur()}
              type="number"
              placeholder={`${t(
                "restitution-followup-form-number-of-lots-placeholder",
              )}`}
              {...methods.register("mailCreationNumberOfHours")}
              disabled={onStandby}
            />
          </label>
          {methods.formState.errors.mailCreationNumberOfHours && (
            <span className="text-xs text-red-500">
              {methods.formState.errors.mailCreationNumberOfHours.message}
            </span>
          )}
        </div>
        <div className="Text__Field_Container mb-2">
          <label className="font-medium" htmlFor="comment">
            {`${t("comment")}`}
            <textarea
              className="Textarea__Field h-48"
              placeholder={`${t("comment")}`}
              id="restitutionComment"
              {...methods.register("restitutionComment")}
            />
          </label>
        </div>
        {!onStandby ? (
          <div className="mt-2 flex flex-col">
            <div className="flex items-center justify-between">
              <p>{t("followup-restitution-files-label")} *</p>
              <AddFile
                files={formFiles}
                setFiles={setFormFiles}
                documentTypes={[ECONOMY_RESTITUTION_GED_CAT.name]}
                numStatus="1"
                linkedFiles
              />
            </div>
            <div>
              {methods.formState.errors.files && formFiles.length <= 0 ? (
                <span className="text-xs text-red-500">
                  {methods.formState.errors.files.message}
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
        <div>
          {formFiles.length > 0 && <ul className="mt-2">{uploadedFiles}</ul>}
        </div>
        <Button
          classNames="mt-3"
          mode="primary"
          type="submit"
          size="small"
          isDisabled={
            onStandby ||
            updateStudyRequestStatus.isPending ||
            updateStudyRequest.isPending
          }
        >
          {t("restitution-followup-form-submit-button")}
        </Button>
      </form>
      <Modal
        isShowing={showModal}
        closeModal={() => setShowModal(false)}
        title={t("put-in-standby")}
      >
        <div className="Text__Field_Container mb-2">
          <label className="font-medium" htmlFor="comment">
            {`${t("comment")}`}
            <textarea
              className="Textarea__Field h-48"
              placeholder={`${t("comment")}`}
              id="comment"
              value={standbyComment}
              onChange={(e) => setStandbyComment(e.target.value)}
            />
          </label>
        </div>
        <div className="flex justify-end">
          <Button
            size="medium"
            mode="secondary"
            onClick={() => setShowModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            size="medium"
            mode="primary"
            onClick={() => onChangeStandby(studyRequest)}
            marginLeft={24}
            isLoading={updateStudyRequestStatus.isPending}
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
    </FormProvider>
  )
}
