import ItemsCounter from "components/Counter/ItemsCounter"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { t } from "i18next"
import { FaRegHandshake } from "react-icons/fa6"
import { Link } from "react-router-dom"
import useGetSuppliersLogs from "core/spo/query-hooks/useSuppliers"

export default function CountSuppliersSpoContainer() {
  const { data: suppliersLogs, error, isLoading } = useGetSuppliersLogs()
  const successes = suppliersLogs?.success
  const errors = suppliersLogs?.errors

  return (
    <Link to={{ pathname: "/expected-suppliers" }}>
      <TooltipWithHover message={t("alvis-suppliers-number")} position="right">
        <ItemsCounter
          totalLateItems={errors?.length || 0}
          totalItems={successes?.length || 0}
          title={t("suppliers")}
          Icon={<FaRegHandshake fontSize={18} />}
          isLoading={isLoading}
          errorMessage={t("error-loading-suppliers")}
          error={error}
          numberColor="text-blue-600"
        />
      </TooltipWithHover>
    </Link>
  )
}
