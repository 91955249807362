import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { useUpdateOperation } from "core/query-hooks/useOperations"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { registerLocale } from "react-datepicker"
import { toast } from "react-toastify"
import { TOperationData } from "shared/types/operation.type"

registerLocale("fr", fr)

export interface IEditAgreementDatesModalProps {
  isEditAgreementDateModalShowed: boolean
  toggleEditAgreementDate: () => void
  field: string | null
  operationId: string
  operationData?: TOperationData
}

export function EditAgreementDatesModal(props: IEditAgreementDatesModalProps) {
  const queryClient = useQueryClient()
  const {
    isEditAgreementDateModalShowed,
    toggleEditAgreementDate,
    operationId,
    operationData,
    field,
  } = props

  const mutateLandPurchaseDate = useUpdateOperation(+operationId)

  const initializeDate = (): Date | null => {
    if (field === "landPurchaseDate") {
      return operationData?.landPurchaseDate
        ? operationData?.landPurchaseDate
        : null
    }
    if (field === "reliabilityDate") {
      return operationData?.reliabilityDate
        ? operationData?.reliabilityDate
        : null
    }
    return operationData?.cancelationDate
      ? operationData?.cancelationDate
      : null
  }

  const initializeDefinitive = (): boolean => {
    if (field === "landPurchaseDate") {
      return operationData?.isLandPurchaseDateDefinitive
        ? operationData?.isLandPurchaseDateDefinitive
        : false
    }
    if (field === "reliabilityDate") {
      return operationData?.isReliabilityDateDefinitive
        ? operationData?.isReliabilityDateDefinitive
        : false
    }
    return operationData?.isCancelationDateDefinitive
      ? operationData.isCancelationDateDefinitive
      : false
  }

  const [agreementDate, setAgreementDate] = useState<Date | null>(
    initializeDate(),
  )

  const [isDefinitiveDate, setIsDefinitiveDate] = useState<boolean>(
    initializeDefinitive(),
  )

  async function handleSubmitPurchaseLandDateForm(
    e: FormEvent<HTMLFormElement>,
  ) {
    e.stopPropagation()
    e.preventDefault()

    mutateLandPurchaseDate.mutate(
      {
        landPurchaseDate:
          field === "landPurchaseDate"
            ? agreementDate
            : operationData?.landPurchaseDate,
        isLandPurchaseDateDefinitive:
          field === "landPurchaseDate"
            ? isDefinitiveDate
            : operationData?.isLandPurchaseDateDefinitive,
        reliabilityDate:
          field === "reliabilityDate"
            ? agreementDate
            : operationData?.reliabilityDate,
        isReliabilityDateDefinitive:
          field === "reliabilityDate"
            ? isDefinitiveDate
            : operationData?.isReliabilityDateDefinitive,
        cancelationDate:
          field === "cancelationDate"
            ? agreementDate
            : operationData?.cancelationDate,
        isCancelationDateDefinitive:
          field === "cancelationDate"
            ? isDefinitiveDate
            : operationData?.isCancelationDateDefinitive,
      },
      {
        onSuccess() {
          toast.success(t("success-update-agreement-date"))
          queryClient.invalidateQueries({
            queryKey: ["operation", +operationId],
          })
          queryClient.resetQueries({
            queryKey: ["getAppelDeFondList"],
          })
          toggleEditAgreementDate()
        },
        onError() {
          toast.error(t("error-update-agreement-date"))
        },
      },
    )
  }

  function setLabel(): string {
    if (field === "landPurchaseDate") {
      return t("land-purchase-date")
    }
    if (field === "reliabilityDate") {
      return t("reliability-date")
    }
    return t("cancelation-date")
  }

  function setTitle(): string {
    if (field === "landPurchaseDate") {
      return t("edit-land-purchase-date")
    }
    if (field === "reliabilityDate") {
      return t("edit-reliability-date")
    }
    return t("edit-cancelation-date")
  }

  return (
    <Modal
      isShowing={isEditAgreementDateModalShowed}
      closeModal={toggleEditAgreementDate}
      title={setTitle()}
    >
      <form
        onSubmit={(e) => handleSubmitPurchaseLandDateForm(e)}
        className=" Text__Field_Container"
      >
        <div className="mt-4 flex flex-col">
          <label htmlFor="date">{setLabel()}</label>
          <CustomDatePicker
            id="land-purchase-date"
            locale={fr}
            className="Text__Field"
            placeholderText={`${t("select-date")}`}
            selected={agreementDate}
            onChange={(date) => {
              setAgreementDate(date)
            }}
          />
        </div>

        <div className="mt-2 mb-6">
          <label htmlFor="definitive-toggle-btn">
            {`${t("date-is-definitive")}`}
            <div className="flex items-center justify-start">
              <ToggleSwitch
                id="definitive-toggle-btn"
                name="toggle-definitive"
                disabled={false}
                checked={isDefinitiveDate}
                onChange={(value) => setIsDefinitiveDate(value)}
              />
            </div>
          </label>
        </div>

        <div className="Modal__Footer flex justify-between mt-3">
          <Button
            onClick={() => toggleEditAgreementDate()}
            type="button"
            size="medium"
            mode="secondary"
          >
            {t("cancel")}
          </Button>
          <Button marginLeft={24} size="medium" mode="primary" type="submit">
            {t("update")}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
