import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createEnvironmentBuilding,
  deleteEnvironmentBuilding,
  listEnvironmentBuildings,
  updateEnvironmentBuilding,
} from "core/api/environmentBuilding"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TCreateEnvironmentBuilding,
  TEnvironmentBuilding,
  TUpdateEnvironmentBuilding,
} from "shared/types/environmentBuilding.type"

function useListEnvironmentBuildings(projectId: number) {
  return useQuery<TEnvironmentBuilding[], CustomAxiosError>({
    queryKey: ["listEnvironmentBuildings", projectId],
    queryFn: () => listEnvironmentBuildings(projectId),
  })
}

function useCreateEnvironmentBuilding() {
  return useMutation<
    TEnvironmentBuilding,
    CustomAxiosError,
    TCreateEnvironmentBuilding
  >({
    mutationFn: (environmentBuilding: TCreateEnvironmentBuilding) =>
      createEnvironmentBuilding(environmentBuilding),
  })
}

function useUpdateEnvironmentBuilding(id: number) {
  return useMutation<
    TEnvironmentBuilding,
    CustomAxiosError,
    TUpdateEnvironmentBuilding
  >({
    mutationFn: (environment: TUpdateEnvironmentBuilding) =>
      updateEnvironmentBuilding(id, environment),
  })
}

function useDeleteEnvironmentBuilding(id: number) {
  return useMutation<TEnvironmentBuilding, CustomAxiosError, number>({
    mutationFn: () => deleteEnvironmentBuilding(id),
  })
}

export {
  useListEnvironmentBuildings,
  useCreateEnvironmentBuilding,
  useUpdateEnvironmentBuilding,
  useDeleteEnvironmentBuilding,
}
