import { Button } from "components/Button/Button"
import { t } from "i18next"

interface DeleteItemFormProps {
  itemName: string
  itemType: string
  onCancel: () => void
  onConfirm: () => void
}

function DeleteItemForm({
  itemName,
  itemType,
  onCancel,
  onConfirm,
}: DeleteItemFormProps) {
  return (
    <div>
      {t(`delete-${itemType}-confirmation`, { itemName })}
      <div className="flex justify-end gap-2 mt-2">
        <Button
          type="button"
          onClick={() => onCancel()}
          size="medium"
          mode="secondary"
        >
          {t("cancel")}
        </Button>
        <Button type="button" onClick={onConfirm} size="medium" mode="danger">
          {t("confirm")}
        </Button>
      </div>
    </div>
  )
}

export default DeleteItemForm
