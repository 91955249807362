import { useQueryClient } from "@tanstack/react-query"
import { useDeleteEnvironmentBuilding } from "core/query-hooks/useEnvironmentBuildings"
import { t } from "i18next"
import { toast } from "react-toastify"
import { OPERATION } from "shared/resources/environment.resources"
import { TEnvironmentBuilding } from "shared/types/environmentBuilding.type"
import { MdDelete } from "react-icons/md"
import useModal from "components/Modal/useModal"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import UpdateEnvironementBuildingForm from "./UpdateEnvironmentBuildingForm"

export interface IEnvironmentFormProps {
  routeName: string
  environmentBuilding: TEnvironmentBuilding
}

export default function EnvironmentBuildingForm(props: IEnvironmentFormProps) {
  const { routeName, environmentBuilding } = props

  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const queryClient = useQueryClient()
  const deleteEnvironmentBuilding = useDeleteEnvironmentBuilding(
    environmentBuilding.id,
  )

  const onDeleteEnvironmentBuilding = async () => {
    await deleteEnvironmentBuilding.mutateAsync(environmentBuilding.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["listEnvironmentBuildings"],
        })

        toast.success(`${t("delete-action-toast")}`)
      },
      onError: (err) => {
        toast.error(`${err.response?.data.error.message}`)
      },
    })
  }

  return (
    <div className="grid grid-cols-5 gap-y-5 gap-x-10 mb-3">
      {routeName === OPERATION && (
        <>
          <div className="Text__Field_Container md:col-start-1">
            <div className="Text__Field_Container">
              <label htmlFor="building-label">{`${t("building")}`}</label>
              <p className="Color__Primary text-lg">
                {environmentBuilding.buildingLabel
                  ? `${environmentBuilding.buildingLabel}`
                  : "-"}
              </p>
            </div>
          </div>
          <div className="Text__Field_Container md:col-start-2">
            <div className="Text__Field_Container">
              <label htmlFor="bbio-max-module">
                {`${t("bbio-max-module")}`}
              </label>
              <p className="Color__Primary text-lg">
                {environmentBuilding.bbioMaxModule
                  ? `${environmentBuilding.bbioMaxModule} pts`
                  : "-"}
              </p>
            </div>
          </div>
          <div className="Text__Field_Container md:col-start-3">
            <div className="Text__Field_Container">
              <label htmlFor="bbio-project">{`${t("bbio-project")}`}</label>
              <p className="Color__Primary text-lg">
                {environmentBuilding.bbioProject
                  ? `${environmentBuilding.bbioProject} pts`
                  : "-"}
              </p>
            </div>
          </div>
          <div className="Text__Field_Container md:col-start-4">
            <label htmlFor="bbio-calculated">
              {`${t("bbio-calculated")}`}
              <p className="Color__Primary text-lg">{`${(((environmentBuilding.bbioProject - environmentBuilding.bbioMaxModule) / environmentBuilding.bbioMaxModule) * 100).toFixed(0)} %`}</p>
            </label>
          </div>
          <div className="Text__Field_Container flex justify-around items-center">
            <MdDelete
              title={`${t("delete")}`}
              fontSize={25}
              className="transition cursor-pointer opacity-70 hover:opacity-100 duration-150 ease-out hover:ease-in"
              onClick={() => onDeleteEnvironmentBuilding()}
            />
            <Button size="small" mode="primary" onClick={toggleModal}>
              {t("update")}
            </Button>
          </div>
        </>
      )}
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("update-building")}`}
      >
        <UpdateEnvironementBuildingForm
          routeName={routeName}
          environmentBuilding={environmentBuilding}
          toggleModal={toggleModal}
        />
      </Modal>
    </div>
  )
}
