import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Box from "components/Box/Box"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useListEnvironmentBuildings } from "core/query-hooks/useEnvironmentBuildings"
import {
  useListBiosourcedLabels,
  useListConstructiveMode,
  useListHeatingMode,
  useListOtherEnvironmentalLabel,
  useListReThresold,
  useUpdateEnvironment,
} from "core/query-hooks/useEnvironments"
import AddEnvironementBuildingForm from "features/environment/environment-building/AddEnvironmentBuildingForm"
import EnvironmentBuildingForm from "features/environment/environment-building/EnvironmentForm"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FaQuestionCircle } from "react-icons/fa"
import { IoEarthSharp } from "react-icons/io5"
import { PiThermometerSimpleFill, PiTreeEvergreenDuotone } from "react-icons/pi"
import { NumericFormat } from "react-number-format"
import { toast } from "react-toastify"
import { TEnvironment, TUpdateEnvironment } from "shared/types/environment.type"
import * as yup from "yup"

type FormValues = {
  reThresoldId?: number | null
  constructiveModeId?: number | null
  heatingModeId?: number | null
  otherEnvironmentalLabelId?: number | null
  biosourcedLabelId?: number | null
  bbcaLabel?: boolean
  ecologist?: boolean
  greenSpacesArea?: number | null
  greenSpacesBudget?: number | null
  refugeArea?: boolean
  freshnessOasis?: boolean
  preservedTrees?: number | null
  deletedTrees?: number | null
  replantedTrees?: number | null
  parkingsNumber?: number | null
  permeableParkings?: number | null
  roofTerraces?: number | null
  revegetationRoofTerraces?: number | null
  vegetatedFences?: boolean
  cbs?: number | null
  reusedMaterials?: string | null
  bbioMax?: number | null
  bbioMaxModule?: number | null
  bbioProject?: number | null
  cbsBefore?: number | null
  cbsAfter?: number | null
}

export interface IEditEnvironmentProps {
  environment: TEnvironment
  setIsEdit: (value: boolean) => void
  routeName: string
}

export default function EditEnvironement(props: IEditEnvironmentProps) {
  const { environment, setIsEdit, routeName } = props

  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const queryClient = useQueryClient()

  const updateEnvironment = useUpdateEnvironment(environment.projectId)
  const { data: reThresold } = useListReThresold()
  const { data: constructiveMode } = useListConstructiveMode()
  const { data: heatingMode } = useListHeatingMode()
  const { data: biosourcedLabel } = useListBiosourcedLabels()
  const { data: otherEnvironmentalLabel } = useListOtherEnvironmentalLabel()
  const { data: environmentBuildings } = useListEnvironmentBuildings(
    environment.projectId,
  )

  const getReThresoldAllowableBbca = (reThresoldId: number) => {
    const selectedReThresold = reThresold?.find((rt) => rt.id === reThresoldId)
    return selectedReThresold ? selectedReThresold.allowableBbca : true
  }

  const validationSchema = yup.object().shape({
    biosourcedLabelId: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    constructiveModeId: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    heatingModeId: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    otherEnvironmentalLabelId: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    reThresoldId: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    bbcaLabel: yup.boolean().test({
      name: "bbca-not-allowed",
      message: () => t("bbca-label-not-allowed"),
      test: (value, context) => {
        if (value === null || value === undefined || value === false)
          return true
        const { reThresoldId } = context.parent
        return getReThresoldAllowableBbca(reThresoldId)
      },
    }),
    ecologist: yup.boolean(),
    greenSpacesArea: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    greenSpacesBudget: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    refugeArea: yup.boolean(),
    freshnessOasis: yup.boolean(),
    preservedTrees: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    deletedTrees: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    replantedTrees: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    parkingsNumber: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    permeableParkings: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    roofTerraces: yup
      .number()
      .nullable()
      .max(100, t("100-max"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
    revegetationRoofTerraces: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    vegetatedFences: yup.boolean(),
    cbsBefore: yup
      .number()
      .nullable()
      .min(0, t("zero-or-greater"))
      .max(1, t("lower-than-one"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
    cbsAfter: yup
      .number()
      .nullable()
      .min(0, t("zero-or-greater"))
      .max(1, t("lower-than-one"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reThresoldId: environment.reThresold ? environment.reThresold.id : null,
      constructiveModeId: environment.constructiveMode
        ? environment.constructiveMode.id
        : null,
      heatingModeId: environment.heatingMode
        ? environment.heatingMode.id
        : null,
      biosourcedLabelId: environment.biosourcedLabel
        ? environment.biosourcedLabel.id
        : null,
      otherEnvironmentalLabelId: environment.otherEnvironmentalLabel
        ? environment.otherEnvironmentalLabel.id
        : null,
      bbcaLabel: environment.bbcaLabel,
      ecologist: environment.ecologist,
      greenSpacesArea: environment.greenSpacesArea ?? null,
      greenSpacesBudget: environment.greenSpacesBudget ?? null,
      refugeArea: environment.refugeArea,
      freshnessOasis: environment.freshnessOasis,
      preservedTrees: environment.preservedTrees ?? null,
      deletedTrees: environment.deletedTrees ?? null,
      replantedTrees: environment.replantedTrees ?? null,
      parkingsNumber: environment.parkingsNumber ?? null,
      permeableParkings: environment.permeableParkings ?? null,
      roofTerraces: environment.roofTerraces ?? null,
      revegetationRoofTerraces: environment.revegetationRoofTerraces ?? null,
      vegetatedFences: environment.vegetatedFences,
      reusedMaterials: environment.reusedMaterials ?? null,
      bbioMax: environment.bbioMax ?? null,
      cbsBefore: environment.cbsBefore ?? null,
      cbsAfter: environment.cbsAfter ?? null,
    },
  })

  const submitForm = (data: TUpdateEnvironment) => {
    updateEnvironment.mutate(data, {
      onError: (err) => {
        toast.error(
          `${t("environment-update-error")} : ${err.response?.data.message}`,
        )
      },
      onSuccess() {
        toast.success(`${t("environment-update-success")}`)
        queryClient.invalidateQueries({
          queryKey: ["getEnvironment", environment.projectId],
        })
        setIsEdit(false)
      },
    })
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  const [greenSpacesArea, setGreenSpacesArea] = useState<
    number | undefined | null
  >(environment.greenSpacesArea)
  const [greenSpacesBudget, setGreenSpacesBudget] = useState<
    number | undefined | null
  >(environment.greenSpacesBudget)

  useEffect(() => {
    setValue("greenSpacesArea", greenSpacesArea)
  }, [greenSpacesArea])

  useEffect(() => {
    setValue("greenSpacesBudget", greenSpacesBudget)
  }, [greenSpacesBudget])

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="flex items-center justify-end">
          <Button
            onClick={() => setIsEdit(false)}
            size="small"
            mode="secondary"
            classNames="mr-2"
          >
            {t("cancel")}
          </Button>
          <PermissionsGuard requiredRoles={["update:environment"]}>
            <Button size="small" mode="primary" type="submit">
              {t("update")}
            </Button>
          </PermissionsGuard>
        </div>
        <div className="flex items-center justify-start pb-2 text-green-700">
          <IoEarthSharp size={38} color="008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("carbon-climate-indicators")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-[1fr,0.5fr,1fr,1fr,1fr] gap-y-5 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="re-thresold">
                {`${t("re-thresold")}`}
                {reThresold && reThresold.length > 0 ? (
                  <select
                    className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                    {...register("reThresoldId")}
                  >
                    <option key="no-option-bank" value="" disabled>
                      {t("select-thresold")}
                    </option>
                    {reThresold.map((rt) => (
                      <option key={`${rt.id}-${rt.label}`} value={rt.id}>
                        {rt.label}
                      </option>
                    ))}
                  </select>
                ) : null}
              </label>
              {errors.reThresoldId && (
                <span className="text-red-500 text-xs">
                  {errors.reThresoldId.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="bbca-ce-labelisation">
                {`${t("bbca-ce-labelisation")}`}
                <div className="pt-3">
                  <input
                    type="checkbox"
                    className="checkbox-custom"
                    {...register("bbcaLabel")}
                  />
                </div>
              </label>
              {errors.bbcaLabel && (
                <span className="text-red-500 text-xs">
                  {errors.bbcaLabel.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="constructive-mode">
                {`${t("constructive-mode")}`}
                {constructiveMode && constructiveMode.length > 0 ? (
                  <select
                    className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                    {...register("constructiveModeId")}
                  >
                    <option key="no-option-bank" value="" disabled>
                      {t("select-constructive-mode")}
                    </option>
                    {constructiveMode.map((cm) => (
                      <option key={`${cm.id}-${cm.label}`} value={cm.id}>
                        {cm.label}
                      </option>
                    ))}
                  </select>
                ) : null}
              </label>
              {errors.constructiveModeId && (
                <span className="text-red-500 text-xs">
                  {errors.constructiveModeId.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="reusedMaterials">
                {t("reusedMaterials")}
                <Controller
                  name="reusedMaterials"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <input
                      type="text"
                      className="Text__Field"
                      {...register("reusedMaterials")}
                      {...rest}
                      ref={ref}
                      value={rest.value || ""}
                    />
                  )}
                />
              </label>
              {errors.reusedMaterials && (
                <span className="text-red-500 text-xs">
                  {errors.reusedMaterials.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="biosourced-label">
                {t("biosourced-label")}
                {biosourcedLabel && biosourcedLabel.length > 0 ? (
                  <select
                    className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                    {...register("biosourcedLabelId")}
                  >
                    {biosourcedLabel.map((bl) => (
                      <option key={`${bl.id}-${bl.label}`} value={bl.id}>
                        {bl.label}
                      </option>
                    ))}
                  </select>
                ) : null}
              </label>
              {errors.biosourcedLabelId && (
                <span className="text-red-500 text-xs">
                  {errors.biosourcedLabelId.message}
                </span>
              )}
            </div>
          </div>
        </Box>

        <div className="flex items-center justify-start pt-2 pb-1 text-green-700">
          <PiThermometerSimpleFill size={40} color="008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("energy-indicator")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-y-5 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="heating-mode">
                {`${t("heating-mode")}`}
                {heatingMode && heatingMode.length > 0 && (
                  <select
                    className="Select__Field Text__Field cursor-pointer Select__Wrapper select-width"
                    {...register("heatingModeId")}
                  >
                    <option key="no-option-bank" value="" disabled>
                      {t("select-heating-mode")}
                    </option>
                    {heatingMode.map((hm) => (
                      <option key={`${hm.id}-${hm.label}`} value={hm.id}>
                        {hm.label}
                      </option>
                    ))}
                  </select>
                )}
              </label>
              {errors.heatingModeId && (
                <span className="text-red-500 text-xs">
                  {errors.heatingModeId.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container flex justify-between md:col-start-3">
              <TooltipWithHover message={t("tooltip-bbio-max")}>
                <label htmlFor="bbio-max" className="flex items-center">
                  {t("bbio-max")}
                  <FaQuestionCircle className="ml-1" size={16} />
                </label>
                <p className="Color__Primary text-lg opacity-50">
                  <input
                    id="bbio-max"
                    className="Text__Field"
                    value={environment.bbioMax || 65}
                    type="number"
                    disabled
                  />
                </p>
              </TooltipWithHover>
            </div>
            <div className="Text__Field_Container md:col-start-6">
              <Button
                size="medium"
                mode="primary"
                onClick={toggleModal}
                isDisabled={
                  environmentBuildings && environmentBuildings.length >= 5
                }
              >
                {t("add-building")}
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="w-full">
              {environmentBuildings &&
                environmentBuildings.length > 0 &&
                environmentBuildings
                  .sort(
                    (a, b) =>
                      a.buildingLabel.charCodeAt(0) -
                      b.buildingLabel.charCodeAt(0),
                  )
                  .map((environBuilding: any) => (
                    <EnvironmentBuildingForm
                      routeName={routeName}
                      environmentBuilding={environBuilding}
                    />
                  ))}
            </div>
          </div>
        </Box>

        <div className="flex items-center justify-start pt-2 pb-1 text-green-700">
          <PiTreeEvergreenDuotone size={40} color="008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("biodiversity-risk-adaptation-indicators")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="ecologist">
                {`${t("ecologist")}`}
                <div className="pt-2">
                  <input
                    type="checkbox"
                    className="checkbox-custom"
                    {...register("ecologist")}
                  />
                </div>
              </label>
              {errors.ecologist && (
                <span className="text-red-500 text-xs">
                  {errors.ecologist.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="other-environmental-label">
                {`${t("other-environmental-label")}`}
                {otherEnvironmentalLabel &&
                otherEnvironmentalLabel.length > 0 ? (
                  <select
                    className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                    {...register("otherEnvironmentalLabelId")}
                  >
                    <option key="no-option-bank" value="" disabled>
                      {t("select-other-environmental-label")}
                    </option>
                    {otherEnvironmentalLabel.map((oel) => (
                      <option key={`${oel.id}-${oel.label}`} value={oel.id}>
                        {oel.label}
                      </option>
                    ))}
                  </select>
                ) : null}
              </label>
              {errors.otherEnvironmentalLabelId && (
                <span className="text-red-500 text-xs">
                  {errors.otherEnvironmentalLabelId.message}
                </span>
              )}
            </div>
            <div className="md:col-span-2" />

            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-budget">
                {`${t("green-spaces-budget")}`}
                <Controller
                  name="greenSpacesBudget"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <NumericFormat
                      className="Text__Field"
                      thousandSeparator=" "
                      decimalSeparator="."
                      suffix=" €"
                      decimalScale={2}
                      getInputRef={ref}
                      {...rest}
                      onValueChange={(v) => {
                        setGreenSpacesBudget(v.floatValue ? v.floatValue : null)
                      }}
                    />
                  )}
                />
              </label>
              {errors.greenSpacesBudget && (
                <span className="text-red-500 text-xs">
                  {errors.greenSpacesBudget.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-area">
                {`${t("green-spaces-area")}`}
                <Controller
                  name="greenSpacesArea"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <NumericFormat
                      className="Text__Field"
                      thousandSeparator=" "
                      decimalSeparator="."
                      suffix=" m²"
                      decimalScale={2}
                      getInputRef={ref}
                      {...rest}
                      onValueChange={(v) => {
                        setGreenSpacesArea(v.floatValue ? v.floatValue : null)
                      }}
                    />
                  )}
                />
              </label>
              {errors.greenSpacesArea && (
                <span className="text-red-500 text-xs">
                  {errors.greenSpacesArea.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-area-cost-ht-m2">
                {`${t("green-spaces-area-cost-ht-m2")}`}
                <p className="Color__Primary text-md">{t("deducted-value")}</p>
              </label>
            </div>
            <div className="md:col-span-1" />

            <div className="Text__Field_Container">
              <label htmlFor="refuge-area">
                {`${t("refuge-area")}`}
                <div className="pt-2">
                  <input
                    type="checkbox"
                    className="checkbox-custom"
                    {...register("refugeArea")}
                  />
                </div>
              </label>
              {errors.refugeArea && (
                <span className="text-red-500 text-xs">
                  {errors.refugeArea.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="freshness-oasis">
                {`${t("freshness-oasis")}`}
                <div className="pt-2">
                  <input
                    type="checkbox"
                    className="checkbox-custom"
                    {...register("freshnessOasis")}
                  />
                </div>
              </label>
              {errors.freshnessOasis && (
                <span className="text-red-500 text-xs">
                  {errors.freshnessOasis.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="vegetated-fences">
                {`${t("vegetated-fences")}`}
                <div className="pt-2">
                  <input
                    type="checkbox"
                    className="checkbox-custom"
                    {...register("vegetatedFences")}
                  />
                </div>
              </label>
              {errors.vegetatedFences && (
                <span className="text-red-500 text-xs">
                  {errors.vegetatedFences.message}
                </span>
              )}
            </div>
            <div className="md:col-span-1" />
            <div className="Text__Field_Container">
              <label htmlFor="existing-trees">
                {`${t("existing-trees")}`}
                <p className="Color__Primary text-md">{t("deducted-value")}</p>
              </label>
            </div>

            <div className="Text__Field_Container">
              <label htmlFor="deleted-trees">
                {`${t("deleted-trees")}`}
                <input
                  id="deletedTrees"
                  className="Text__Field"
                  {...register("deletedTrees")}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.deletedTrees && (
                <span className="text-red-500 text-xs">
                  {errors.deletedTrees.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="preserved-trees">
                {`${t("preserved-trees")}`}
                <input
                  id="preservedTrees"
                  className="Text__Field"
                  {...register("preservedTrees")}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.preservedTrees && (
                <span className="text-red-500 text-xs">
                  {errors.preservedTrees.message}
                </span>
              )}
            </div>

            <div className="Text__Field_Container">
              <label htmlFor="replanted-trees">
                {`${t("replanted-trees")}`}
                <input
                  id="replantedTrees"
                  className="Text__Field"
                  {...register("replantedTrees")}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.replantedTrees && (
                <span className="text-red-500 text-xs">
                  {errors.replantedTrees.message}
                </span>
              )}
            </div>

            <div className="Text__Field_Container">
              <label htmlFor="parkings-number">
                {`${t("parkings-number")}`}
                <input
                  id="parkingsNumber"
                  className="Text__Field"
                  {...register("parkingsNumber")}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.parkingsNumber && (
                <span className="text-red-500 text-xs">
                  {errors.parkingsNumber.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="permeable-parkings-number">
                {`${t("permeable-parkings-number")}`}
                <input
                  id="permeableParkings"
                  className="Text__Field"
                  {...register("permeableParkings")}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.permeableParkings && (
                <span className="text-red-500 text-xs">
                  {errors.permeableParkings.message}
                </span>
              )}
            </div>
            <div className="md:col-span-2" />

            <div className="Text__Field_Container">
              <label htmlFor="roof-terraces">
                {`${t("roof-terraces")}`}
                <p className="Color__Primary text-md">{t("deducted-value")}</p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="revegetation-roof-terraces">
                {`${t("revegetation-roof-terraces")}`}
                <input
                  id="revegetationRoofTerraces"
                  className="Text__Field"
                  {...register("revegetationRoofTerraces")}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.revegetationRoofTerraces && (
                <span className="text-red-500 text-xs">
                  {errors.revegetationRoofTerraces.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="revegetation-roof-terraces-percent">
                {`${t("revegetation-roof-terraces-percent")}`}
                <input
                  id="roofTerraces"
                  className="Text__Field"
                  {...register("roofTerraces")}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.roofTerraces && (
                <span className="text-red-500 text-xs">
                  {errors.roofTerraces.message}
                </span>
              )}
            </div>
            <div className="md:col-span-1" />

            <div className="Text__Field_Container">
              <label htmlFor="cbs-before">
                {`${t("cbs-before")}`}
                <input
                  id="cbsBefore"
                  className="Text__Field"
                  {...register("cbsBefore")}
                  type="number"
                  step="0.01"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.cbsBefore && (
                <span className="text-red-500 text-xs">
                  {errors.cbsBefore.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="cbs-after">
                {`${t("cbs-after")}`}
                <input
                  id="cbs-after"
                  className="Text__Field"
                  {...register("cbsAfter")}
                  type="number"
                  step="0.01"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </label>
              {errors.cbsAfter && (
                <span className="text-red-500 text-xs">
                  {errors.cbsAfter.message}
                </span>
              )}
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="cbs-evolution">
                {`${t("cbs-evolution")}`}
                <p className="Color__Primary text-md">{t("deducted-value")}</p>
              </label>
            </div>
            <div className="md:col-span-3" />
          </div>
        </Box>
      </form>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("add-building")}`}
      >
        <AddEnvironementBuildingForm
          routeName={routeName}
          environmentBuildings={environmentBuildings}
          toggleModal={toggleModal}
        />
      </Modal>
    </>
  )
}
