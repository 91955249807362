import { useQueryClient } from "@tanstack/react-query"
import CopyLinkButton from "components/CopyLinkButton/CopyLinkButton"
import DeleteItemForm from "components/DeleteItemForm/DeleteItemForm"
import DropdownButton from "components/DropdownButton/DropdownButton"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import { EhancedFile } from "components/UploadField/UploadFieldDrop"
import {
  useDeleteItem,
  useUploadFile,
} from "core/sharepoint/query-hooks/useFiles"
import { useCountFilesInFolder } from "core/sharepoint/query-hooks/useFolders"
import { t } from "i18next"
import { DateFormatter } from "pages/dashboards/components/DateFormatter"
import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FaRegFolder } from "react-icons/fa"
import { toast } from "react-toastify"
import { SharepointItem } from "shared/types/sharepoint"
import getFileIcon from "utils/getFileIcon"
import handleFileUpload from "utils/uploadFiles"
import MoveSharePointFile from "./MoveSharepointFile"
import RenameSharepointItem from "./RenameSharepointItem"

export interface ISharepointItemCardProps {
  item: SharepointItem
  handleChangeCurrentFolderId: (id: string, name: string) => void
  currentFolderId: string
  sharepointFolderId: string
  isDroppable?: boolean
  isDraggable?: boolean
}

export function SharepointItemCard(props: ISharepointItemCardProps) {
  const {
    item,
    handleChangeCurrentFolderId,
    currentFolderId,
    sharepointFolderId,
    isDroppable,
    isDraggable,
  } = props

  const [myFiles, setMyFiles] = useState<EhancedFile[]>([])

  const mutation = useUploadFile()

  const { isShowing, toggle } = useModal()
  const [modalContent, setModalContent] = useState<React.ReactNode>(null)
  const [modalTitle, setModalTitle] = useState<string>("")

  const folderId = item.type === "file" ? currentFolderId : item.id

  const {
    data: fileCount,
    isLoading,
    isError,
  } = useCountFilesInFolder(folderId)

  const deleteItemMutation = useDeleteItem()
  const queryClient = useQueryClient()

  const extension = item.name.split(".").pop()?.toLowerCase() || ""
  const icon =
    item.type === "folder" ? (
      <FaRegFolder color="#2563eb" />
    ) : (
      getFileIcon(extension)
    )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (isDroppable) {
        setMyFiles(
          acceptedFiles.map((file) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          }),
        )
      } else {
        toast.error(t("max-folder-depth"))
      }
    },
    noDragEventsBubbling: true,
  })

  useEffect(() => {
    if (myFiles.length > 0) {
      const onSuccess = () => {
        setMyFiles([])
      }

      handleFileUpload(myFiles, folderId, mutation, queryClient, t, onSuccess)
    }
  }, [myFiles])

  const handleClick = () => {
    if (item.type === "file") {
      window.open(item.webUrl, "_blank")
    } else {
      handleChangeCurrentFolderId(item.id, item.name)
    }
  }

  const handleDeleteItem = async () => {
    deleteItemMutation.mutate(item.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["listFoldersFromParent", item.parentId],
        })
        queryClient.invalidateQueries({
          queryKey: ["countFilesInFolder", item.parentId],
        })
        toggle()
        toast.success(t(`delete-${item.type}-success`))
      },
      onError: () => {
        toast.error(t(`delete-${item.type}-error`))
      },
    })
  }

  const dropdownOptions =
    isDraggable && item.type === "folder"
      ? [
          {
            code: "rename",
            label: t("rename"),
          },
          {
            code: "delete",
            label: t("delete"),
          },
        ]
      : [
          {
            code: "move",
            label: t("move"),
          },
          {
            code: "rename",
            label: t("rename"),
          },
          {
            code: "share",
            label: t("share"),
          },
          {
            code: "delete",
            label: t("delete"),
          },
        ]

  const handleOptionClick = (option: string) => {
    switch (option) {
      case "move":
        setModalTitle("Déplacer l'élément")
        setModalContent(
          <MoveSharePointFile
            sharepointFolderId={sharepointFolderId}
            toggle={toggle}
            itemId={item.id}
          />,
        )
        break
      case "rename":
        setModalTitle(`Renommer l'élément: ${item.name}`)
        setModalContent(<RenameSharepointItem toggle={toggle} item={item} />)
        break
      case "share":
        setModalTitle(t("share-file"))
        setModalContent(<CopyLinkButton url={item.webUrl} />)
        break
      case "delete":
        if (item.type === "folder" && fileCount !== 0) {
          toast.error(t("delete-folder-contain-file-error"))
          return
        }
        setModalTitle(t(`delete-${item.type}`))
        setModalContent(
          <DeleteItemForm
            itemName={item.name}
            itemType={item.type}
            onCancel={toggle}
            onConfirm={handleDeleteItem}
          />,
        )
        break
      default:
        setModalContent(null)
    }

    toggle()
  }

  return (
    <>
      <div
        className="flex items-center cursor-pointer hover:bg-gray-100 rounded-lg p-2 dropzone-container"
        style={{
          transition: "background-color 0.5s ease-in-out",
          backgroundColor:
            isDragActive && item.type === "folder" ? "#B0C02A" : `transparent`,
        }}
        {...getRootProps({
          onClick: (e) => {
            e.stopPropagation()
            handleClick()
          },
        })}
        draggable={isDraggable}
        onDragStart={(e) => {
          e.dataTransfer.setData("text/plain", JSON.stringify(item))
        }}
      >
        <input {...getInputProps()} />
        <div className="flex items-center flex-1 min-w-0">
          <div className="mr-2">{icon}</div>
          <div className="truncate flex">
            <span className="pr-3">{item.name}</span>
            {isError && (
              <span className="text-danger">
                {t("file-count-in-folder-error")}
              </span>
            )}
            {isLoading ? (
              <Spinner />
            ) : (
              <span>{item.type !== "file" ? ` (${fileCount})` : ""}</span>
            )}
          </div>
        </div>
        {item.type === "file" && (
          <div className="flex items-center">
            <span className="text-sm text-gray-400 pr-3">
              <DateFormatter
                date={new Date(item.lastModifiedDateTime)}
                withHours
              />
            </span>
          </div>
        )}
        {isDraggable && (
          <DropdownButton
            onOptionClick={handleOptionClick}
            dropdownOptions={dropdownOptions}
          />
        )}
      </div>
      <Modal
        isShowing={isShowing}
        closeModal={toggle}
        title={modalTitle}
        displayCloseIcon
      >
        {modalContent}
      </Modal>
    </>
  )
}
