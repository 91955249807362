import {
  TMoveFile,
  TRenameItem,
  TSharepointFile,
} from "shared/types/sharepoint"
import SHAREPOINT_API from "../axios"

async function deleteItem(itemId: string): Promise<void> {
  await SHAREPOINT_API.delete(`/item/${itemId}`)
}

async function moveFile(data: TMoveFile): Promise<void> {
  const { fileId, newParentId } = data
  await SHAREPOINT_API.patch(`/file/move/${fileId}`, {
    newParentId,
  })
}

async function renameItem(data: TRenameItem): Promise<void> {
  const { itemId, itemName } = data
  await SHAREPOINT_API.patch(`/item/rename/${itemId}`, {
    itemName,
  })
}

async function uploadFile(data: TSharepointFile): Promise<void> {
  const fd = new FormData()

  fd.append("fileName", data.fileName)
  fd.append("file", data.fileContent)

  const res = await SHAREPOINT_API.post(`document/${data.folderId}`, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return res?.data
}

export { deleteItem, moveFile, renameItem, uploadFile }
