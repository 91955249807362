import classNames from "classnames"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { t } from "i18next"
import { useRef, useState } from "react"
import { MdOutlineLayers, MdSpaceDashboard } from "react-icons/md"
import { Link } from "react-router-dom"
import { GROUP } from "shared/resources/groups.resources"
import { useOnClickOutside } from "usehooks-ts"

type OnCloseMenu = () => void

interface ISidebarItemDashboards {
  closeSidebar: () => void
}

function RenderDashboards({ onCloseMenu }: { onCloseMenu: OnCloseMenu }) {
  return (
    <>
      <h3 className="font-semibold mb-2">{t("dashboards")}</h3>
      <Link
        to="/dashboards/pdp"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">{t("steering")}</span>
      </Link>
      <Link
        to="/dashboards/excel/synthese-financiere"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">
          {t("financial-synthesis")}
        </span>
      </Link>

      <GroupsGuard
        requiredGroups={[
          GROUP.CONCEPTION_INFOGRAPHISTE_RESPONSABLE,
          GROUP.CONCEPTION_CONCEPTEUR_RESPONSABLE,
          GROUP.CONCEPTION_ECONOMISTE_RESPONSABLE,
          GROUP.CONCEPTION_CONCEPTEUR,
          GROUP.CONCEPTION_INFOGRAPHISTE,
          GROUP.CONCEPTION_ECONOMISTE,
          GROUP.CODIR,
          GROUP.DEVELOPPEMENT_DIRECTEUR,
          GROUP.DEVELOPPEMENT_ASSISTANT,
          GROUP.DIRECTEUR_REGION_IDF,
          GROUP.DIRECTEUR_REGION_GO,
          GROUP.DIRECTEUR_REGION_SE,
          GROUP.DIRECTEUR_REGION_RA,
          GROUP.DIRECTEUR_REGION_SO,
          GROUP.DIRECTEUR_REGION_NE,
          GROUP.DIRECTEUR_REGION_VDM,
          GROUP.DIRECTEUR_TERTIAIRE,
          GROUP.DIRECTEUR_TECHNIQUE,
        ]}
      >
        <h3 className="font-semibold my-2">{t("study-request")}</h3>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_REGION_IDF]}>
          <Link
            to="/dashboards/excel/etudes-IDF"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("IDF")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_REGION_GO]}>
          <Link
            to="/dashboards/excel/etudes-GO"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("GO")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_REGION_SE]}>
          <Link
            to="/dashboards/excel/etudes-SE"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("SE")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_REGION_RA]}>
          <Link
            to="/dashboards/excel/etudes-RA"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("RA")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_REGION_SO]}>
          <Link
            to="/dashboards/excel/etudes-SO"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("SO")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_REGION_NE]}>
          <Link
            to="/dashboards/excel/etudes-NE"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("NE")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_REGION_VDM]}>
          <Link
            to="/dashboards/excel/etudes-VDM"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("VDM")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_TERTIAIRE]}>
          <Link
            to="/dashboards/excel/etudes-tertiaires"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("tertiary")}</span>
          </Link>
        </GroupsGuard>
        <GroupsGuard requiredGroups={[GROUP.DIRECTEUR_TECHNIQUE]}>
          <Link
            to="/dashboards/excel/etudes-techniques"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("technical")}</span>
          </Link>
        </GroupsGuard>
        <PermissionsGuard requiredRoles={["read:feasibility.dashboard"]}>
          <Link
            to="/dashboards/study-requests/feasibility"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">
              {t("dashboard-feasibility")}
            </span>
          </Link>
        </PermissionsGuard>
        <PermissionsGuard requiredRoles={["read:cost-of-work.dashboard"]}>
          <Link
            to="/dashboards/study-requests/costs-of-work"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">{t("cost-of-work")}</span>
          </Link>
        </PermissionsGuard>
        <PermissionsGuard requiredRoles={["read:infography.dashboard"]}>
          <Link
            to="/dashboards/study-requests/infography"
            className="Sidebar__Item_Content mb-1"
            onClick={onCloseMenu}
          >
            <MdOutlineLayers size={20} />
            <span className="Sidebar__Item_TextLink">
              {t("dashboard-info")}
            </span>
          </Link>
        </PermissionsGuard>
      </GroupsGuard>
      <PermissionsGuard requiredRoles={["read:study-request.dashboard"]}>
        <Link
          to="/dashboards/study-requests/overview"
          className="Sidebar__Item_Content mb-1"
          onClick={onCloseMenu}
        >
          <MdOutlineLayers size={20} />
          <span className="Sidebar__Item_TextLink">
            {t("study-request-from-my-area")}
          </span>
        </Link>
      </PermissionsGuard>
      <h3 className="font-semibold my-2">{t("land-expense")}</h3>
      <Link
        to="/dashboards/excel/acquisitions-foncieres"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">
          {t("acquisitions-foncieres")}
        </span>
      </Link>
      <h3 className="font-semibold my-2">{t("assembly")}</h3>
      <Link
        to="/dashboards/excel/suivi-pc"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">{t("suivi-pc")}</span>
      </Link>
      <h3 className="font-semibold my-2">{t("production")}</h3>
      <Link
        to="/dashboards/excel/dce-ao"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">{t("dce-ao")}</span>
      </Link>
      <Link
        to="/dashboards/excel/avancement-travaux"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">
          {t("avancement-travaux")}
        </span>
      </Link>
      <h3 className="font-semibold my-2">{t("clients")}</h3>
      <Link
        to="/dashboards/excel/livraisons-diffus"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">{t("livraisons-diffus")}</span>
      </Link>
      <h3 className="font-semibold my-2">{t("suppliers")}</h3>
      <Link
        to="/dashboards/excel/achats"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">{t("achats")}</span>
      </Link>
      <Link
        to="/dashboards/excel/suivi-administratif"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">
          {t("suivi-administratif")}
        </span>
      </Link>
      <h3 className="font-semibold my-2">{t("dematerialisation")}</h3>
      <Link
        to="/dashboards/excel/factures-fournisseurs"
        className="Sidebar__Item_Content mb-1"
        onClick={onCloseMenu}
      >
        <MdOutlineLayers size={20} />
        <span className="Sidebar__Item_TextLink">{t("supplier-invoices")}</span>
      </Link>
    </>
  )
}

export default function SidebarItemDashboards({
  closeSidebar,
}: ISidebarItemDashboards) {
  const [isCliked, setIsClicked] = useState(false)
  const SidebarItemRef = useRef(null)
  useOnClickOutside(SidebarItemRef, () => setIsClicked(false))

  const handleCloseMenu = () => {
    setIsClicked(false)
    closeSidebar()
  }

  return (
    <li ref={SidebarItemRef}>
      <button
        data-title={t("dashboards")}
        onClick={() => setIsClicked(!isCliked)}
        className="sidebar-title flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 group"
        type="button"
      >
        <MdSpaceDashboard
          className={classNames(
            "w-6 h-6 transition duration-75 group-hover:text-sky-800",
            {
              "text-sky-800": isCliked,
              "text-gray-500": !isCliked,
            },
          )}
        />
      </button>
      {isCliked && (
        <div className="sidebar-child-container p-4">
          <RenderDashboards onCloseMenu={handleCloseMenu} />
        </div>
      )}
    </li>
  )
}
