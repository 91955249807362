import classNames from "classnames"
import { Button } from "components"
import Box from "components/Box/Box"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { UsersGuard } from "core/permissions/UsersGuard"
import { t } from "i18next"
import { FaQuestionCircle } from "react-icons/fa"
import { IoEarthSharp } from "react-icons/io5"
import { MdWarning } from "react-icons/md"
import { PiThermometerSimpleFill, PiTreeEvergreenDuotone } from "react-icons/pi"
import {
  OPERATION,
  USERS_WITH_EDIT_PERMISSIONS,
} from "shared/resources/environment.resources"
import Spinner from "components/Spinner/Spinner"
import { useListEnvironmentBuildings } from "core/query-hooks/useEnvironmentBuildings"
import { TEnvironment } from "shared/types/environment.type"
import { EcoRangeSlide } from "./EcoRangeSlide"

export interface IDisplayEnvironmentProps {
  environment: TEnvironment
  setIsEdit: (value: boolean) => void
  routeName: string
}

export default function DisplayEnvironment(props: IDisplayEnvironmentProps) {
  const { environment, setIsEdit, routeName } = props

  const {
    data: environmentBuildings,
    isLoading: isEnvironmentBuildingLoading,
    error: environmentBuildingError,
  } = useListEnvironmentBuildings(environment.projectId)

  const renderButton = () => (
    <div
      className={classNames("flex items-center", {
        "justify-between": routeName !== OPERATION,
        "justify-end": routeName === OPERATION,
      })}
    >
      {routeName !== OPERATION && (
        <div className="flex items-center text-red-600">
          <MdWarning size={22} className="mr-2 flex-2" />
          <span className="flex whitespace-nowrap">
            {t("indicators-are-residential-only")}
          </span>
        </div>
      )}
      <Button onClick={() => setIsEdit(true)} size="small" mode="primary">
        {t("edit")}
      </Button>
    </div>
  )
  return (
    <>
      {routeName === OPERATION ? (
        <UsersGuard requiredUserId={USERS_WITH_EDIT_PERMISSIONS}>
          {renderButton()}
        </UsersGuard>
      ) : (
        <PermissionsGuard requiredRoles={["update:environment"]}>
          {renderButton()}
        </PermissionsGuard>
      )}
      <>
        <div className="flex items-center justify-start pb-2 text-green-700">
          <IoEarthSharp size={38} color="#008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("carbon-climate-indicators")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-6 gap-y-6 md:gap-y-0 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="re-thresold">
                {`${t("re-thresold")}`}
                <p className="Color__Primary text-lg">
                  {environment.reThresold ? environment.reThresold.label : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="bbca-ce-labelisation">
                {`${t("bbca-ce-labelisation")}`}
                <p className="Color__Primary text-lg">
                  {environment.bbcaLabel ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <TooltipWithHover
                message={`Equivalence RE/kg eq CO₂/m² :\nRE2020 = 740\nRE2025 = 650\nRE2028 = 580\nRE2031 = 490`}
              >
                <label htmlFor="ic-construction" className="flex items-center">
                  {`${t("ic-construction")}`}
                  <FaQuestionCircle className="ml-1" size={16} />
                </label>
                <p className="Color__Primary text-lg">
                  {environment.reThresold
                    ? `${environment.reThresold.IcConstruction} kg eq CO₂/m²`
                    : "-"}
                </p>
              </TooltipWithHover>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="constructive-mode">
                {`${t("constructive-mode")}`}
                <p className="Color__Primary text-lg">
                  {environment.constructiveMode
                    ? environment.constructiveMode.label
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="reusedMaterials">
                {`${t("reusedMaterials")}`}
                <p className="Color__Primary text-lg">
                  {environment.reusedMaterials
                    ? environment.reusedMaterials
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="biosourced-label">
                {`${t("biosourced-label")}`}
                <p className="Color__Primary text-lg">
                  {environment.biosourcedLabel
                    ? environment.biosourcedLabel.label
                    : "-"}
                </p>
              </label>
            </div>
            <div className="md:col-span-1" />
          </div>
        </Box>

        <div className="flex items-center justify-start pt-2 pb-1 text-green-700">
          <PiThermometerSimpleFill size={40} color="008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("energy-indicator")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-y-5 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="heating-mode">
                {`${t("heating-mode")}`}
                <p className="Color__Primary text-lg">
                  {environment.heatingMode
                    ? environment.heatingMode.label
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <TooltipWithHover message={`${t("tooltip-bbio-max")}`}>
                <label htmlFor="bbio-max" className="flex items-center">
                  {`${t("bbio-max")}`}
                  <FaQuestionCircle className="ml-1" size={16} />
                </label>
                <p className="Color__Primary text-lg">
                  {environment.bbioMax ? environment.bbioMax : "65"} pts
                </p>
              </TooltipWithHover>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-y-5 gap-x-12">
            {isEnvironmentBuildingLoading && (
              <div className="flex justify-center h-screen items-center">
                <Spinner />
              </div>
            )}
            {environmentBuildingError && (
              <p>
                {`${t("error")}`} : {environmentBuildingError.message}
              </p>
            )}
            {routeName === OPERATION &&
              environmentBuildings &&
              environmentBuildings
                .sort(
                  (a, b) =>
                    a.buildingLabel.charCodeAt(0) -
                    b.buildingLabel.charCodeAt(0),
                )
                .map((building) => (
                  <>
                    <div className="Text__Field_Container md:col-start-2">
                      <label
                        htmlFor="building-label"
                        className="flex items-center"
                      >
                        {`${t("building")}`}
                      </label>
                      <p className="Color__Primary text-lg">
                        {building.buildingLabel
                          ? `${building.buildingLabel}`
                          : "-"}
                      </p>
                    </div>
                    <div className="Text__Field_Container md:col-start-3">
                      <TooltipWithHover
                        message={`${t("tooltip-bbio-max-module")}`}
                      >
                        <label
                          htmlFor="bbio-max-module"
                          className="flex items-center"
                        >
                          {`${t("bbio-max-module")}`}
                          <FaQuestionCircle className="ml-1" size={16} />
                        </label>
                        <p className="Color__Primary text-lg">
                          {building.bbioMaxModule
                            ? `${building.bbioMaxModule} pts`
                            : "-"}
                        </p>
                      </TooltipWithHover>
                    </div>
                    <div className="Text__Field_Container md:col-start-4">
                      <label htmlFor="bbio-project">
                        {`${t("bbio-project")}`}
                        <p className="Color__Primary text-lg">
                          {building.bbioProject
                            ? `${building.bbioProject} pts`
                            : "-"}
                        </p>
                      </label>
                    </div>
                    <div className="Text__Field_Container md:col-start-5">
                      <label htmlFor="bbio-calculated">
                        {`${t("bbio-calculated")}`}
                        <p className="Color__Primary text-lg">
                          {building.bbioProject != null &&
                          building.bbioMaxModule != null &&
                          building.bbioMaxModule !== 0 ? (
                            <div className="flex items-center">
                              {`${(((building.bbioProject - building.bbioMaxModule) / building.bbioMaxModule) * 100).toFixed(0)} %`}
                            </div>
                          ) : (
                            "-"
                          )}
                        </p>
                      </label>
                    </div>
                  </>
                ))}
          </div>
        </Box>

        <div className="flex items-center justify-start pt-2 pb-1 text-green-700">
          <PiTreeEvergreenDuotone size={40} color="008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("biodiversity-risk-adaptation-indicators")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="ecologist">
                {`${t("ecologist")}`}
                <p className="Color__Primary text-lg">
                  {environment.ecologist ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="other-environmental-label">
                {`${t("other-environmental-label")}`}
                <p className="Color__Primary text-lg">
                  {environment.otherEnvironmentalLabel
                    ? environment.otherEnvironmentalLabel.label
                    : "-"}
                </p>
              </label>
            </div>
            <div className="md:col-span-2" />

            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-budget">
                {`${t("green-spaces-budget")}`}
                <p className="Color__Primary text-lg">
                  {environment.greenSpacesBudget
                    ? `${environment.greenSpacesBudget.toLocaleString("fr-FR")} €`
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-area">
                {`${t("green-spaces-area")}`}
                <p className="Color__Primary text-lg">
                  {environment.greenSpacesArea
                    ? `${environment.greenSpacesArea.toLocaleString("fr-FR")} m²`
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-area-cost-ht-m2">
                {`${t("green-spaces-area-cost-ht-m2")}`}
                <p className="Color__Primary text-lg">
                  {environment.greenSpacesBudget && environment.greenSpacesArea
                    ? `${(
                        environment.greenSpacesBudget /
                        environment.greenSpacesArea
                      ).toFixed(2)} €/m²`
                    : "-"}
                </p>
              </label>
            </div>
            <div className="md:col-span-1" />

            <div className="Text__Field_Container">
              <label htmlFor="refuge-area">
                {`${t("refuge-area")}`}
                <p className="Color__Primary text-lg">
                  {environment.refugeArea ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="freshness-oasis">
                {`${t("freshness-oasis")}`}
                <p className="Color__Primary text-lg">
                  {environment.freshnessOasis ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="vegetated-fences">
                {`${t("vegetated-fences")}`}
                <p className="Color__Primary text-lg">
                  {environment.vegetatedFences ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="md:col-span-1" />

            <div className="Text__Field_Container">
              <label htmlFor="existing-trees">
                {`${t("existing-trees")}`}
                <p className="Color__Primary text-lg">
                  {environment.preservedTrees ? (
                    <div className="font-semibold pl-1">
                      {environment.preservedTrees != null &&
                      environment.deletedTrees != null
                        ? `${environment.preservedTrees + environment.deletedTrees}`
                        : environment.preservedTrees}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="deleted-trees">
                {`${t("deleted-trees")}`}
                <p className="Color__Primary text-lg">
                  {environment.deletedTrees ? (
                    <div className="flex items-center">
                      {environment.deletedTrees}{" "}
                      {environment.preservedTrees && (
                        <div className="font-semibold pl-1">
                          {`(${((environment.deletedTrees * 100) / (environment.deletedTrees + environment.preservedTrees)).toFixed(0)} %)`}
                        </div>
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="preserved-trees">
                {`${t("preserved-trees")}`}
                <p className="Color__Primary text-lg">
                  <div className="flex items-center">
                    {environment.preservedTrees
                      ? environment.preservedTrees
                      : "-"}
                    {environment.preservedTrees && environment.deletedTrees && (
                      <div className="font-semibold pl-1">
                        {`(${((environment.preservedTrees * 100) / (environment.deletedTrees + environment.preservedTrees)).toFixed(0)} %)`}
                      </div>
                    )}
                  </div>
                </p>
              </label>
            </div>

            <div className="Text__Field_Container">
              <label htmlFor="replanted-trees">
                {`${t("replanted-trees")}`}
                <p className="Color__Primary text-lg">
                  {environment.replantedTrees
                    ? environment.replantedTrees
                    : "-"}
                </p>
              </label>
            </div>

            <div className="Text__Field_Container">
              <label htmlFor="parkings-number">
                {`${t("parkings-number")}`}
                <p className="Color__Primary text-lg">
                  {environment.parkingsNumber
                    ? environment.parkingsNumber
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="permeable-parkings-number">
                {`${t("permeable-parkings-number")}`}
                <p className="Color__Primary text-lg">
                  {environment.permeableParkings ? (
                    <div className="flex items-center">
                      {environment.permeableParkings}{" "}
                      {environment.parkingsNumber ? (
                        <div className="font-semibold pl-1">
                          {`(${((environment.permeableParkings / environment.parkingsNumber) * 100).toFixed(0)} %)`}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="md:col-span-2" />

            <div className="Text__Field_Container">
              <label htmlFor="roof-terraces">
                {`${t("roof-terraces")}`}
                <p className="Color__Primary text-lg">
                  {environment.revegetationRoofTerraces ? (
                    <div className="flex items-center">
                      {environment.roofTerraces ? (
                        <div className="font-semibold pl-1">
                          {`${((environment.revegetationRoofTerraces / environment.roofTerraces) * 100).toFixed(0)}`}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="revegetation-roof-terraces">
                {`${t("revegetation-roof-terraces")}`}
                <p className="Color__Primary text-lg">
                  {environment.revegetationRoofTerraces ? (
                    <div className="flex items-center">
                      {environment.revegetationRoofTerraces}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="revegetation-roof-terraces-percent">
                {`${t("revegetation-roof-terraces-percent")}`}
                <p className="Color__Primary text-lg">
                  {environment.roofTerraces ? environment.roofTerraces : "-"}
                </p>
              </label>
            </div>
            <div className="md:col-span-4" />

            <div className="Text__Field_Container">
              <label htmlFor="cbs-before">
                {`${t("cbs-before")}`}
                {environment.cbsBefore != null ? (
                  <div className="pt-1">
                    <EcoRangeSlide value={environment.cbsBefore} />
                  </div>
                ) : (
                  <div>-</div>
                )}
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="cbs-after">
                {`${t("cbs-after")}`}
                {environment.cbsAfter != null ? (
                  <div className="pt-1">
                    <EcoRangeSlide value={environment.cbsAfter} />
                  </div>
                ) : (
                  <div>-</div>
                )}
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="cbs-evolution">
                {`${t("cbs-evolution")}`}
                <p className="Color__Primary text-lg">
                  {environment.cbsBefore != null &&
                  environment.cbsBefore !== 0 &&
                  environment.cbsAfter != null &&
                  environment.cbsAfter !== 0 ? (
                    <div className="flex items-center">
                      {`${(((environment.cbsAfter - environment.cbsBefore) / environment.cbsBefore) * 100).toFixed(0)}`}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="md:col-span-3" />
          </div>
        </Box>
      </>
    </>
  )
}
