import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useListLessorMissingCertificate } from "core/spo/query-hooks/useTranches"
import { t } from "i18next"
import { FaBuildingUser } from "react-icons/fa6"
import {
  TLessor,
  TTranchesTravauxWithBailleurs,
} from "shared/types/spo/tranche.type"

export interface ILessorsFilterProps {
  trancheTravaux: TTranchesTravauxWithBailleurs
  selectedLessor: string | null
  setSelectedLessor: (selectedLessor: TLessor | null) => void
  registrationNumber: string
}

export function LessorsFilter(props: ILessorsFilterProps) {
  const {
    trancheTravaux,
    selectedLessor,
    setSelectedLessor,
    registrationNumber,
  } = props
  const { data: listLessorMissingCertificate } =
    useListLessorMissingCertificate(registrationNumber, trancheTravaux.code)

  const hasMissingCertificate = (lessorCode: string): Boolean => {
    return (
      listLessorMissingCertificate?.find((lessor) => lessor.code === lessorCode)
        ?.hasMissingCertificate ?? false
    )
  }

  return (
    <div className="flex space-x-2 py-3">
      <button
        key="pierreval"
        type="button"
        onClick={() => setSelectedLessor(null)}
        className={`px-4 py-2 rounded-full ${
          selectedLessor === null
            ? "bg-primary-light text-white"
            : "bg-gray-200"
        }`}
      >
        {t("pierreval")}
      </button>
      {trancheTravaux.lessors?.map((lessor) => (
        <div className="relative inline-flex">
          <button
            key={lessor.code}
            type="button"
            onClick={() => setSelectedLessor(lessor)}
            className={`px-4 py-2 rounded-full ${
              selectedLessor === lessor.code
                ? "bg-primary-light text-white"
                : "bg-gray-200"
            }`}
          >
            {hasMissingCertificate(lessor.code) && (
              <div className="absolute top-0.5 right-0.5">
                <TooltipWithHover
                  message={t("certificate-missing")}
                  position="center"
                >
                  <span className=" grid min-h-[24px] min-w-[24px] translate-x-2/4 -translate-y-2/4 rounded-full place-items-center bg-red-600 py-1 px-1 text-xs font-semibold text-white">
                    !
                  </span>
                </TooltipWithHover>
              </div>
            )}
            <FaBuildingUser className="inline-block mr-2" />
            {lessor.label}
          </button>
        </div>
      ))}
    </div>
  )
}
