import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TFundRaisingAppeal,
  TMutateFundRaisingAppeal,
} from "shared/types/spo/appelDeFond.type"
import {
  getAppelDefondList,
  getSupportingDocumentExplanation,
  updateAppelDefondDocuments,
} from "../api/appel-de-fond"

function useGetAppelDefondList(trancheTravauxId: string, lessorId: string) {
  return useQuery<TFundRaisingAppeal[], AxiosError>({
    queryKey: ["getAppelDeFondList", trancheTravauxId, lessorId],
    queryFn: () => getAppelDefondList(trancheTravauxId, lessorId),
  })
}

function useGetSupportingDocumentExplanation(
  trancheTravauxId: string,
  lessorId?: string,
) {
  return useQuery<TFundRaisingAppeal[], AxiosError>({
    queryKey: ["getSupportingDocumentExplanation", trancheTravauxId, lessorId],
    queryFn: () => getSupportingDocumentExplanation(trancheTravauxId, lessorId),
  })
}

function useUpdateAppelDefondDocuments(termCode: string) {
  return useMutation<void, CustomAxiosError, Partial<TMutateFundRaisingAppeal>>(
    {
      mutationFn: (data: Partial<TMutateFundRaisingAppeal>) =>
        updateAppelDefondDocuments(termCode, data),
    },
  )
}

export {
  useGetAppelDefondList,
  useGetSupportingDocumentExplanation,
  useUpdateAppelDefondDocuments,
}
