import {
  TFundRaisingAppeal,
  TMutateFundRaisingAppeal,
} from "shared/types/spo/appelDeFond.type"
import SPO_API from "../axios"

async function getAppelDefondList(
  trancheTravauxId: string,
  lessorId: string,
): Promise<TFundRaisingAppeal[]> {
  const res = await SPO_API.get(
    `/operations/appel-de-fond?trancheTravauxCode=${trancheTravauxId}&tiersCode=${lessorId}`,
  )
  return res.data
}

async function getSupportingDocumentExplanation(
  trancheTravauxId: string,
  lessorId?: string,
): Promise<TFundRaisingAppeal[]> {
  const res = await SPO_API.get(
    `/operations/appel-de-fond/supporting-document?trancheTravauxCode=${trancheTravauxId}${lessorId ? `&lessorId=${lessorId}` : ""}`,
  )
  return res.data
}

async function updateAppelDefondDocuments(
  termCode: string,
  updateData: Partial<TMutateFundRaisingAppeal>,
): Promise<void> {
  SPO_API.patch(
    `/operations/appel-de-fond/documents/${termCode}`,
    updateData,
  ).then((response) => response.data)
}

export {
  getAppelDefondList,
  getSupportingDocumentExplanation,
  updateAppelDefondDocuments,
}
