import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components/Button/Button"
import { useRenameItem } from "core/sharepoint/query-hooks/useFiles"
import { t } from "i18next"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { SharepointItem } from "shared/types/sharepoint"
import * as yup from "yup"

export interface TRenamedFileFormProps {
  toggle: () => void
  item: SharepointItem
}

const getValidationSchema = (type: string) => {
  return type === "file"
    ? yup
        .object({
          itemName: yup
            .string()
            .required(() => t("file-name-required"))
            .matches(/^[a-zA-Z0-9À-ÖØ-öø-ÿ_-]+$/, t("invalid-file-name")),
        })
        .required()
    : yup
        .object({
          itemName: yup
            .string()
            .required(() => t("required-folder-name"))
            .matches(/^[^"*:<>?/\\|.]*$/, {
              message: t(t("invalid-characters")),
            }),
        })
        .required()
}

export default function RenameSharepointItem({
  toggle,
  item,
}: TRenamedFileFormProps) {
  type FormValues = {
    itemName: string
  }

  const { id: itemId, name, parentId, type } = item
  const schema = getValidationSchema(type)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
    defaultValues: {
      itemName:
        type === "file" ? name.substring(0, name.lastIndexOf(".")) : name,
    },
  })

  const mutation = useRenameItem()
  const queryClient = useQueryClient()

  const fileExtension = name.split(".").pop()

  const submitForm = async (data: FormValues) => {
    await mutation.mutateAsync(
      {
        itemName:
          type === "file" ? `${data.itemName}.${fileExtension}` : data.itemName,
        itemId,
      },
      {
        onSuccess: () => {
          toggle()
          queryClient.invalidateQueries({
            queryKey: ["listFoldersFromParent", parentId],
          })
          toast.success(t(`rename-${type}-success`))
        },
        onError: () => {
          toast.error(t(`rename-${type}-error`))
        },
      },
    )
  }

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className="Text__Field_Container mt-4"
    >
      <label htmlFor="itemName">
        {`${t("file-name")}`}
        <input
          id="itemName"
          className="Text__Field"
          type="text"
          {...register("itemName")}
          placeholder={`${t("file-name-description")}`}
        />
        {errors.itemName && (
          <p className="text-red-500">
            {errors.itemName.message || `${t("file-name-required")}`}
          </p>
        )}
      </label>

      <div className="Modal__Footer mt-4">
        <div className="flex items-center justify-end">
          <Button
            size="medium"
            mode="secondary"
            isLoading={false}
            onClick={toggle}
          >
            {`${t("cancel")}`}
          </Button>
          <Button
            type="submit"
            marginLeft={24}
            size="medium"
            mode="primary"
            isLoading={mutation.isPending}
          >
            {`${t("rename")}`}
          </Button>
        </div>
      </div>
    </form>
  )
}
