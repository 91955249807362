import classNames from "classnames"
import ButtonIcon from "components/Button/ButtonIcon"
import useModal from "components/Modal/useModal"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import AddProductionDocumentsModal from "features/ged/AddProductionDocumentsModal"
import { Dispatch, SetStateAction } from "react"
import { MdAdd, MdOutlineSync } from "react-icons/md"
import { TGedDocument, TGedUploadResponse } from "shared/types/ged.type"
import { TDateJalon } from "shared/types/spo/datesPlanning.type"

export interface IAddProductionDocuments {
  documentTypes: string[]
  maxUploadFiles?: number
  setFiles?: Dispatch<SetStateAction<TGedUploadResponse[]>>
  files?: TGedUploadResponse[]
  disabled?: boolean
  isCertificate?: boolean
  handleDeleteDocument?: (documentId: number) => Promise<void>
  linkedFiles?: boolean
  registrationNumber?: string
  titleModal: string
  handleGetDate?: (date: TDateJalon) => Promise<void>
  filesToDisplay?: TGedDocument[]
}

export function AddProductionDocuments(props: IAddProductionDocuments) {
  const {
    files,
    maxUploadFiles = 10,
    setFiles,
    documentTypes,
    disabled,
    handleDeleteDocument,
    linkedFiles,
    registrationNumber,
    titleModal,
    isCertificate = false,
    handleGetDate,
    filesToDisplay,
  } = props

  const { toggle, isShowing } = useModal()

  return (
    <div>
      {files && files?.length >= 30 ? null : (
        <>
          <ButtonIcon
            disabled={disabled}
            className={classNames(
              "flex items-center text-blue-500 hover:text-blue-600 transition-colors ease-in-out delay-75",
              {
                "opacity-50": disabled,
              },
            )}
            type="button"
            onClick={toggle}
          >
            {(filesToDisplay?.length ?? 0) > 0 && isCertificate ? (
              <MdOutlineSync
                size={22}
                cursor="pointer"
                className="text-cyan-800"
              />
            ) : (
              <MdAdd size={22} cursor="pointer" className="text-cyan-800" />
            )}
          </ButtonIcon>

          {isShowing && (
            <GedAxiosInterceptor>
              <AddProductionDocumentsModal
                isModalShowed={isShowing}
                toggleModal={() => toggle()}
                maxFiles={maxUploadFiles}
                setFiles={setFiles}
                documentTypes={documentTypes}
                files={files}
                linkedFiles={linkedFiles}
                registrationNumber={registrationNumber}
                titleModal={titleModal}
                handleGetDate={handleGetDate}
                handleDeleteDocument={handleDeleteDocument}
                filesToDisplay={filesToDisplay}
                isCertificate={isCertificate}
                isPatchDocument={
                  (filesToDisplay?.length ?? 0) > 0 && isCertificate
                }
              />
            </GedAxiosInterceptor>
          )}
        </>
      )}
    </div>
  )
}
