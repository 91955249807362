import classNames from "classnames"
import DateFormat from "components/Date/DateFormat"
import ArrowLeft from "components/Icons/ArrowLeft"
import { LinkCustom } from "components/Link/Link"
import LightTable from "components/Table/LightTable"
import listBusinessUnits from "core/api/business-units"
import { listCommitteeByOperationId } from "core/api/committee"
import {
  listOperationsCommitteesNextThreeMonths,
  listOperationsDataNextThreeMonths,
  listOperationsDpcNextThreeMonths,
} from "core/api/operations"
import getNextThreeMonthsInfos from "core/spo/api/data"
import { addMonths } from "date-fns"
import { t } from "i18next"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { RiTaskLine } from "react-icons/ri"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { COMMITTEE_TRANSFERT } from "shared/resources/committees-types-resources"
import PERMIT_LABEL from "shared/resources/permit-label"
import PERMIT_STEP from "shared/resources/permit-step"
import { TBusinessUnit } from "shared/types/business-unit.type"
import {
  TDatePerOp,
  TNextThreeMonthsInfos,
  TOperationNextThreeMonths,
} from "shared/types/spo/next-three-months-infos"
import { NextThreeMonthsBuildingPermits } from "./NextThreeMonthsBuildingPermits"
import { NextThreeMonthsLandPurchaseBlock } from "./NextThreeMonthsLandPurchaseBlock"
import { NextThreeMonthsSpoBlock } from "./NextThreeMonthsSpoBlock"
import { NextThreeMonthsTransfertBlock } from "./NextThreeMonthsTransfertBlock"

export default function NextThreeMonthsOperationList() {
  const navigate = useNavigate()
  const location = useLocation()
  type TNextThreeMonthsInfosExtended = TNextThreeMonthsInfos & {
    businessUnitLabel: string | undefined
  }
  type TGlobalNextThreeMonth = TOperationNextThreeMonths | TNextThreeMonthsInfos
  const operationList: TGlobalNextThreeMonth[] =
    location.state?.operations ?? []
  const [businessUnits, setBusinessUnits] = useState<TBusinessUnit[]>([])
  const [transformedOperationList, setTransformedOperationList] = useState<
    TNextThreeMonthsInfosExtended[]
  >([])

  const [businessUnitId, setBusinessUnitId] = useState<number | undefined>(
    undefined,
  )
  const [countBlock, setCountBlock] = useState<ReactNode | null>(null)
  const { bu, blockName } = useParams()

  const transformTGlobalNextThreeMonth = async (
    opList: TGlobalNextThreeMonth[],
  ) => {
    const globalNextThreeMonthList: TNextThreeMonthsInfosExtended[] = []
    await listBusinessUnits().then(async (buList) => {
      setBusinessUnits(buList)
      await Promise.all(
        opList.map(async (o) => {
          if ("operationBase" in o) {
            const dates: Date[] = []
            let datesPerChildOperation: TDatePerOp[] = []

            if (blockName === "purchases") {
              if (o.operationData.landPurchaseDate)
                dates.push(o.operationData.landPurchaseDate)
            }

            if (blockName === "transferts") {
              const committees = await listCommitteeByOperationId(
                o.operationBase.id,
              )
              const date = committees?.find(
                (c) => c.type.id === COMMITTEE_TRANSFERT.id,
              )?.date
              if (date) dates.push(date)
            }

            if (blockName === "dpc") {
              o.operationData?.permits.forEach((p) => {
                if (
                  p.buildingPermitStepId === PERMIT_STEP.DEPOT.id &&
                  p.buildingPermitLabelId === PERMIT_LABEL.PERMIS.id
                ) {
                  datesPerChildOperation.push({
                    childOperationImmat: p.childOperation,
                    date: p.date,
                    isDateDefinitive: p.isDateDefinitive,
                  })
                }
              })
            }
            datesPerChildOperation = datesPerChildOperation.sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            )
            globalNextThreeMonthList.push({
              projectId: o.operationBase.id,
              businessUnitLabel: o.operationBase.businessUnit.label,
              businessUnitId: o.operationBase.businessUnit.id,
              city: o.operationBase.city,
              postcode: o.operationBase.postcode,
              address: o.operationBase.address,
              dates,
              datesPerChildOperation,
            })
          } else {
            globalNextThreeMonthList.push({
              ...o,
              dates: o.date ? [o.date] : [],
              businessUnitLabel: buList?.find(
                (businnessUnit) => businnessUnit.id === o.businessUnitId,
              )?.label,
            })
          }
        }),
      )
    })

    setTransformedOperationList(
      globalNextThreeMonthList.sort((a, b) => {
        if (a.dates && b.dates) {
          return new Date(a.dates[0]).valueOf() - new Date(b.dates[0]).valueOf()
        }
        return -1
      }),
    )
  }

  const getNextThreeMonthDatas = async (buId?: number) => {
    let listOpe: TGlobalNextThreeMonth[] = []

    if (blockName === "transferts") {
      const { data } = await listOperationsCommitteesNextThreeMonths(buId)
      listOpe = data
      setCountBlock(
        <NextThreeMonthsTransfertBlock
          businessUnitId={businessUnitId ? businessUnitId.toString() : ""}
        />,
      )
    } else if (blockName === "purchases") {
      const { data } = await listOperationsDataNextThreeMonths(buId)
      listOpe = data
      setCountBlock(
        <NextThreeMonthsLandPurchaseBlock
          businessUnitId={businessUnitId ? businessUnitId.toString() : ""}
        />,
      )
    } else if (blockName === "dpc") {
      const { data } = await listOperationsDpcNextThreeMonths(
        buId,
        addMonths(new Date(), 3),
      )
      listOpe = data
      setCountBlock(
        <NextThreeMonthsBuildingPermits
          businessUnitId={businessUnitId ? businessUnitId.toString() : ""}
        />,
      )
    } else if (blockName) {
      listOpe = await getNextThreeMonthsInfos(
        blockName,
        buId ? buId.toString() : "",
      )
      setCountBlock(
        <NextThreeMonthsSpoBlock
          action={blockName}
          businessUnitId={businessUnitId ? businessUnitId.toString() : ""}
          Icon={<RiTaskLine size={18} />}
          title={location.state?.title}
        />,
      )
    }
    transformTGlobalNextThreeMonth(listOpe)
  }

  useMemo(() => {
    setBusinessUnitId(bu ? +bu : undefined)
  }, [])

  useEffect(() => {
    getNextThreeMonthDatas(businessUnitId)
  }, [businessUnitId])

  const headers = [
    () => t("bu"),
    () => t("city"),
    () => t("address"),
    () => t("estimated-date"),
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    {
      key: `${t("bu")}`,
      value: ["businessUnitLabel"],
    },
    { key: `${t("city")}`, value: ["city"] },
    {
      key: `${t("address")}`,
      value: ["address"],
    },
    {
      key: `${t("estimated-date")}`,
      value: ["date"],
    },
  ]

  return (
    <div className="Light_Table__Wrapper Table__Wrapper_NoTop md:gap-4 px-8 py-6 gap-y-2">
      <div className="w-full">
        <LinkCustom
          classNames="pt-4"
          icon={ArrowLeft}
          iconSize={16}
          iconPosition="left"
          onClick={() => navigate(`/`)}
        >
          {t("back-to-home")}
        </LinkCustom>
      </div>
      <div className="my-2">
        <div className="grid md:grid-cols-5 md:grid-rows-1 grid-cols-1 grid-rows-2 gap-4 mb-4">
          <div className="col-span-1 row-span-1">
            <h2 className="font-semibold">{location.state?.title}</h2>
          </div>
          <div className="col-span-3 row-span-1" />
          <div className="col-span-1 row-span-1">{countBlock}</div>
        </div>

        <div className="flex items-center justify-end">
          {businessUnits ? (
            <div className="Text__Field_Container">
              <select
                id="businessUnitId"
                value={businessUnitId}
                className="Select__Field Text__Field cursor-pointer font-semibold Select__Wrapper"
                onChange={(e) => setBusinessUnitId(+e.target.value)}
              >
                <option key="business-unit-all" value="">
                  {t("national")}
                </option>
                {businessUnits.map((businessUnit) => (
                  <option
                    key={`${businessUnit.id}-${businessUnit.label}`}
                    value={businessUnit.id}
                  >
                    {businessUnit.label}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <LightTable
        showTotal={false}
        className="table-auto"
        items={transformedOperationList}
        headers={headers}
        sortableFields={sortableFields}
        renderRow={(item, index) => {
          return (
            <>
              <td
                onClick={() => navigate(`/operation/${item.projectId}`)}
                className={classNames(
                  {
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: operationList.length - 1 === index,
                  },
                  "cursor-pointer",
                )}
              >
                <div className="flex items-center">
                  {item.businessUnitLabel}
                </div>
              </td>
              <td
                onClick={() => navigate(`/operation/${item.projectId}`)}
                className={classNames(
                  {
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: operationList.length - 1 === index,
                  },
                  "cursor-pointer",
                )}
              >
                {`${item.city} - ${item.postcode}`}
              </td>
              <td
                onClick={() => navigate(`/operation/${item.projectId}`)}
                className={classNames(
                  {
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: operationList.length - 1 === index,
                  },
                  "cursor-pointer",
                )}
              >
                {item.address}
              </td>
              <td
                onClick={() => navigate(`/operation/${item.projectId}`)}
                className={classNames(
                  {
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: operationList.length - 1 === index,
                  },
                  "cursor-pointer",
                )}
              >
                <div className="flex items-start">
                  {item.datesPerChildOperation
                    ? item.datesPerChildOperation.map((detail) => {
                        return (
                          !detail.isDateDefinitive && (
                            <div className="mr-4 pb-0.5">
                              <div className="text-left pb-0.5">
                                <strong>
                                  {
                                    detail.childOperationImmat
                                      .searchableRegistrationNumber
                                  }
                                </strong>
                              </div>
                              <div className="text-left">
                                {detail.date && (
                                  <DateFormat
                                    date={detail.date}
                                    isDefinitive={detail.isDateDefinitive}
                                  />
                                )}
                              </div>
                            </div>
                          )
                        )
                      })
                    : null}
                  {item.dates
                    ? item.dates.map((date) => {
                        return (
                          <div className="mr-2">
                            <DateFormat date={date} isDefinitive={false} />
                          </div>
                        )
                      })
                    : null}
                  {!item.datesPerChildOperation && !item.dates && "-"}
                </div>
              </td>
            </>
          )
        }}
      />
    </div>
  )
}
