import { useQueryClient } from "@tanstack/react-query"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import EditDateModal from "components/Modal/EditDateModal"
import useModal from "components/Modal/useModal"
import { useUpdateJalon } from "core/spo/query-hooks/useProductions"
import { t } from "i18next"
import { useState } from "react"
import { MdEdit } from "react-icons/md"
import { toast } from "react-toastify"
import { TFundRaisingAppeal } from "shared/types/spo/appelDeFond.type"

export interface IJalonDate {
  fundRaisingAppeal: TFundRaisingAppeal
  trancheTravaux: string
  lessor?: string | null
}

export function JalonDate(props: IJalonDate) {
  const { fundRaisingAppeal, trancheTravaux, lessor } = props
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  const [fundRaisindAppealDate, setFundRaisindAppealDate] = useState<
    Date | undefined
  >()
  const updateFundRaisingAppeal = useUpdateJalon(fundRaisingAppeal.dateCode)

  const handleShowModal = (date: Date) => {
    setFundRaisindAppealDate(new Date(date))
    toggleModal()
  }

  const handleSubmitFund = (fundRaisingAppealDate: Date | null) => {
    updateFundRaisingAppeal.mutate(
      {
        date: fundRaisingAppealDate,
      },
      {
        onSuccess: () => {
          toast.success(`${t("fund-raising-appeal-updated")}`)
          queryClient.invalidateQueries({
            queryKey: ["getAppelDeFondList", trancheTravaux, lessor ?? ""],
          })
          toggleModal()
        },
        onError: (err) => {
          toast.error(
            `${t("fund-raising-appeal-errored")} : ${
              err.response?.data?.message
            }`,
          )
        },
      },
    )
  }

  return (
    <>
      <div className="flex items-center">
        <div>
          {fundRaisingAppeal.termLabel ? (
            <DateFormat
              date={fundRaisingAppeal.date}
              isDefinitive={fundRaisingAppeal.actualDate}
            />
          ) : (
            "-"
          )}
        </div>
        {!fundRaisingAppeal.actualDate && (
          <ButtonIcon
            className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            type="button"
            onClick={() => handleShowModal(fundRaisingAppeal.date)}
          >
            <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
          </ButtonIcon>
        )}
      </div>
      {isModalShowed && (
        <EditDateModal
          hasToggle={false}
          isModalShowed={isModalShowed}
          toggleModal={toggleModal}
          initialDate={fundRaisindAppealDate}
          isDefinitive={fundRaisingAppeal.actualDate}
          onSubmit={(fundRaisingAppealDate) => {
            handleSubmitFund(fundRaisingAppealDate ?? null)
          }}
          isPending={updateFundRaisingAppeal.isPending}
          title={`${t("edit-date-fund-raising-appeal")}`}
          labelDate={`${t("end-date")}`}
          requiredDate
        />
      )}
    </>
  )
}
