import Spinner from "components/Spinner/Spinner"
import { useGetEnvironmentById } from "core/query-hooks/useEnvironments"
import { useListChildOperations } from "core/query-hooks/useOperations"
import { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { OPERATION } from "shared/resources/environment.resources"
import { RESIDENTIAL_TYPE } from "shared/resources/operation.resources"
import DisplayEnvironment from "./DisplayEnvironment"
import EditEnvironement from "./EditEnvironment"

export interface IProgramEnvironment {
  routeName: string
}

export default function ProgramEnvironment(props: IProgramEnvironment) {
  const { id } = useParams()
  const { routeName } = props
  const projectId = +id!
  const {
    data: environment,
    isLoading,
    error,
  } = useGetEnvironmentById(projectId)

  const operationsChildren =
    routeName === OPERATION ? useListChildOperations(projectId).data : null

  const [isEdit, setIsEdit] = useState<boolean>(false)

  if (
    operationsChildren &&
    operationsChildren.every((child) => child.type?.id !== RESIDENTIAL_TYPE.id)
  ) {
    return <>{t("notFoundPageMessage")}</>
  }

  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="p-4">
        {isLoading ? <Spinner /> : null}
        {environment ? (
          <>
            {isEdit ? (
              <EditEnvironement
                environment={environment}
                setIsEdit={setIsEdit}
                routeName={routeName}
              />
            ) : (
              <DisplayEnvironment
                environment={environment}
                setIsEdit={setIsEdit}
                routeName={routeName}
              />
            )}
          </>
        ) : null}
        {error ? t("environment-data-error") : null}
      </div>
    </>
  )
}
