import { Tree } from "@minoru/react-dnd-treeview"
import classNames from "classnames"
import { Button } from "components"
import DisplayLoading from "components/Display/DisplayLoading"
import useModal from "components/Modal/useModal"
import { SearchInput } from "components/SearchInput/SearchInput"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import { t } from "i18next"
import { useCallback, useEffect } from "react"
import { ImFolder, ImFolderOpen } from "react-icons/im"
import { useDebouncedCallback } from "use-debounce"
import AddDocumentModal from "./AddDocumentsModal"
import GedLink from "./GedLink"
import useTree from "./useTree"

interface GedTreeProps {
  view: number
  projectId?: number
  registrationNumbers?: string[]
}

export default function GedTree(props: GedTreeProps) {
  const { view, projectId, registrationNumbers } = props
  const { tree, handleDrop, setSearchText, isLoading, isError } = useTree(
    projectId,
    registrationNumbers,
    view,
  )
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  useEffect(() => {
    isModalShowed && (document.body.style.overflow = "hidden")
    !isModalShowed && (document.body.style.overflow = "unset")
  }, [isModalShowed])

  return (
    <div>
      <div className="flex justify-between items-center mb-2 gap-x-3">
        <SearchInput
          searchLabel="Rechercher dans la GED"
          className="mb-3"
          handleChange={useDebouncedCallback(
            useCallback((value: string) => {
              return setSearchText(value)
            }, []),
            500,
          )}
        />
        {tree && tree.length > 0 && (
          <Button
            classNames="flex-shrink-0"
            onClick={toggleModal}
            size="medium"
            mode="primary"
          >
            {`${t("add")}`}
          </Button>
        )}
        {isModalShowed && (
          <GedAxiosInterceptor>
            <AddDocumentModal
              view={view}
              isModalShowed={isModalShowed}
              toggleModal={toggleModal}
              registrationNumbers={registrationNumbers}
            />
          </GedAxiosInterceptor>
        )}
      </div>

      <div className="mt-3">
        {isLoading && !isError ? (
          <DisplayLoading isLoading={isLoading} />
        ) : null}

        {isError && !isLoading ? (
          <p className="text-red-600 text-sm">{`${t(
            "ged-errorLoadMessage",
          )}`}</p>
        ) : null}

        {!isError && !isLoading && (
          <>
            {tree.length ? (
              <Tree
                tree={tree}
                rootId={0}
                canDrag={() => false}
                onDrop={handleDrop}
                render={(
                  node: any,
                  {
                    depth,
                    isOpen,
                    onToggle,
                  }: { depth: number; isOpen: boolean; onToggle: () => void },
                ) => (
                  <div
                    onClick={() => (node.droppable ? onToggle() : null)}
                    className={classNames(
                      "flex items-start Color__Primary cursor-pointer text-sm mb-1 opacity-80",
                    )}
                    style={{ marginLeft: depth * 12 }}
                  >
                    {node.droppable || node.data?.isDocumentType ? (
                      <span className="pt-0.5 mr-1.5">
                        {isOpen ? <ImFolderOpen /> : <ImFolder />}
                      </span>
                    ) : null}
                    {node.data?.isFile && node.data?.id ? (
                      <GedLink title={node.text} id={node.data.id!} />
                    ) : (
                      <p>{node.text}</p>
                    )}
                  </div>
                )}
              />
            ) : (
              <p className="text-sm Color__Primary text-center">
                {`${t("ged-noDocumentsFound")}`}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}
