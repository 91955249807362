import { useGetCurrentUser } from "core/query-hooks/useUsers"
import * as React from "react"
import { GROUP } from "shared/resources/groups.resources"

export interface IUsersGuardProps extends React.PropsWithChildren {
  requiredUserId: string[]
  excludeAdmin?: boolean
}

export function UsersGuard(props: IUsersGuardProps) {
  const { data: user } = useGetCurrentUser()
  const { children, requiredUserId, excludeAdmin } = props

  const isUserRequired = user ? requiredUserId.includes(user.azureId) : false
  const isAdmin = user && user.groups.includes(GROUP.ADMIN) && !excludeAdmin

  if (!isUserRequired && !isAdmin) return null

  return <>{children}</>
}
