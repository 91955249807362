import { useQueries } from "@tanstack/react-query"
import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { getCollectifsProductionDates } from "core/spo/api/production"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { Fragment } from "react"
import {
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { TChildOperation } from "shared/types/operation.type"
import { SteeringProductionCollectiveRow } from "./SteeringProductionCollectiveRow"

export interface IOperationProductionProps {
  operationsChildren: TChildOperation[]
}

export function SteeringProductionCollectiveDatesTable(
  props: IOperationProductionProps,
) {
  const { operationsChildren } = props

  const operationsDate = useQueries({
    queries: operationsChildren
      ?.filter(
        (operation) =>
          operation.type.id === TERTIARY_TYPE.id ||
          operation.type.id === RESIDENTIAL_TYPE.id,
      )
      .map((operation) => {
        return {
          queryKey: ["operationChildren", operation.id],
          queryFn: () =>
            getCollectifsProductionDates(operation.registrationNumber),
        }
      }),
  })

  const headers = [
    `${t("child-operation")}`,
    `${t("tranche-travaux")}`,
    `${t("start-work")}`,
    `${t("levelling")}`,
    `${t("fundation")}`,
    `${t("first-level-slab")}`,
    `${t("last-level-slab")}`,
    `${t("set-rain-tight")}`,
    `${t("set-wind-tight")}`,
    `${t("end-partition")}`,
    `${t("completion")}`,
    `${t("provision-collectif")}`,
  ]

  const isLoading = operationsDate.some((operation) => operation.isLoading)

  const isOnError = operationsDate.every((operation) => operation.error)

  if (isLoading) {
    return (
      <div className="flex justify-center mb-2">
        <Spinner />
      </div>
    )
  }

  if (isOnError) {
    return (
      <div className="flex justify-center mb-2">
        <p className="text-sm text-red-500">
          {t("error-loading-dates-planning")}
        </p>
      </div>
    )
  }

  const shouldDisplayData = operationsDate.some(
    (operation) => operation.data && operation.data.length > 0,
  )

  return (
    <div className="overflow-auto">
      {shouldDisplayData ? (
        <div className="Light_Table__Wrapper">
          <table className="table-auto Table">
            <thead>
              <tr className="text-left">
                {headers.map((header, index) => (
                  <th
                    className={classNames(
                      "Table__Head Table__Top_Border",
                      index === 0 ? "w-36" : "w-52",
                    )}
                    key={header}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {operationsDate.map((operation) => (
                <Fragment key={nanoid()}>
                  {operation.data &&
                    operation.data.map((lotsCollectifDate) => (
                      <SteeringProductionCollectiveRow
                        key={lotsCollectifDate.registrationId}
                        lotsCollectifDate={lotsCollectifDate}
                      />
                    ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-2 text-primary-dark text-sm">
          {t("no-tranche-travaux-found")}
        </div>
      )}
    </div>
  )
}
